/*
Comportement tablette/mobile : $breakpoint-medium: 768px;

pour info :
$breakpoint-mobile: 480px;
*/
$bp-mobile-to-bp-medium: 288;

.strate-3-cols-simples {

    .inner_content_strate_3_cols_simples {
        &.bleu {
            background-color: $bleuFonceFondHeader;
        }

        &.blanc {
            margin-bottom: 140px;

            .inner_content_wrapper {
                .titre {
                    color: $DarkBlue_Text;
                }

                .wrapper_colonnes {
                    .col {
                        color: $DarkBlue_Text;

                        &.col1 {
                            border: 0;
                        }
                    }

                }
            }
        }
    }

    .inner_content_wrapper {
        // padding: 120px 0;
        @include responsive(54px, 66, $breakpoint-mobile, $bp-mobile-to-bp-medium, 'padding-top');
        @include responsive(60px, 60, $breakpoint-mobile, $bp-mobile-to-bp-medium, 'padding-bottom');

        .titre {
            @include titre-strate();
            color: white;
            text-align: center;
            // margin-bottom: 70px;
            @include responsive(40px, 30, $breakpoint-mobile, $bp-mobile-to-bp-medium, 'margin-bottom');
        }

        .wrapper_colonnes {
            display: flex;
            justify-content: space-between;
            @include max($breakpoint-medium) {
                display: block;
            }

            .col {
                width: 33.333333%;
                color: white;
                @include max($breakpoint-medium) {
                    width: 100%;
                    margin-bottom: 20px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                &.col0 {
                    padding-right: 30px;
                    @include max($breakpoint-medium) {
                        padding-right: 0;
                    }
                }

                &.col1 {
                    border-left: solid 1px rgba(255, 255, 255, .3);
                    border-right: solid 1px rgba(255, 255, 255, .3);
                    padding: 0 30px;
                    @include max($breakpoint-medium) {
                        padding: 0;
                        border: 0;
                    }
                }

                &.col2 {
                    padding-left: 30px;
                    @include max($breakpoint-medium) {
                        padding-left: 0;
                    }
                }

                h3 {
                    @include font-responsive(18px, 7, $breakpoint-mobile, $desktop_to_mobile);
                    @include responsive(28px, 7, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                    font-weight: 500;
                }

                p {
                    @include texte-standard();
                    color: white;
                    margin-top: 20px;

                    &:first-of-type {
                        margin-top: 0;
                    }
                }

                li {
                    line-height: 1.4em;
                    margin-top: 22px;
                    position: relative;
                    padding-left: 20px;

                    &::before {
                        content: '';
                        display: block;
                        width: 7px;
                        height: 7px;
                        border-radius: 100%;
                        background-color: $Red_Praxedo;
                        position: absolute;
                        left: 0;
                        top: 10px;
                    }
                }

                strong {
                    font-weight: 500;
                }
            }
        }

    }

    a {
        color: $Red_Praxedo;
        text-decoration: underline;
    }
}
