/*
BP : $breakpoint-large: 992px;
*/

.strate-intro-csm {
    // quelques variables
    $width_petites_col: 26.19047619047619%; // 330
    $height_titre_petites_col: 76px;
    $padding_petites_col: 30px;


    .inner_content_strate_intro_csm {
        // margin-top: 108px;
    }

    .inner_content_wrapper {
        // 1260
        display: flex;
        padding-top: 108px;
        padding-bottom: 108px;

        @include max($breakpoint-large){
            flex-direction: column;
            padding-top: 30px;
            padding-bottom: 40px;
        }

        .col_centre, .col_droite {
            // 330
            width: $width_petites_col;
            border-radius: 20px;
            box-shadow: 0px 4px 24px #EEEEF4;
            padding: $padding_petites_col;
            background-color: white;

            .titre {
                font-weight: 500;
                font-size: 25px;
                line-height: 1.56em;
                // margin-bottom: 37px;
                height: $height_titre_petites_col;
                text-align: center;
            }
        }

        .col_gauche {
            width: calc(100% - (2 * #{$width_petites_col}) - 30px);
            padding-right: 40px; // au pif pour faire joli

            @include max($breakpoint-large){
                width: 100%;
                padding-right: 0;
            }

            .titre {
                font-weight: 700;
                // font-size: 50px;
                line-height: 1.2em;
                margin-bottom: 50px;
                @include responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile, 'font-size');
            }

            .wrapper_personne {
                display: flex;
                align-items: center;

                .image {
                    width: 80px;
                    height: 80px;
                    border-radius: 10px;
                    overflow: hidden;
                    margin-right: 15px;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }

                .nom {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 1.62em;
                }

                .fonction {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 1.2em;
                }
            }
        }

        .col_centre {
            margin-right: 30px;

            @include max($breakpoint-large){
                width: 100%;
                margin-right: 0;
                margin-top: 35px;
            }

            .wrapper_competence {
                display: flex;
                padding-bottom: 20px;
                border-bottom: 1px solid #C5D1D9;
                margin-bottom: 20px;

                &:last-of-type {
                    padding-bottom: 0;
                    border-bottom: none;
                    margin-bottom: 0;
                }

                .picto {
                    width: 105px;
                }

                .texte {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 1.28em;
                }
            }
        }

        .col_droite {
            display: flex;
            flex-direction: column;

            @include max($breakpoint-large){
                width: 100%;
                margin-top: 20px;
            }

            .wrapper_items_bottom_col {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: calc(100% - #{$height_titre_petites_col} );

                .wrapper_projet {
                    @include max($breakpoint-large){
                        margin-bottom: 25px;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }

                    .projet_top {
                        display: flex;
                        justify-content: space-between;
                        line-height: 20px;
                        margin-bottom: 7px;

                        .projet_top_texte {
                            font-size: 12px;
                            font-weight: 500;
                            text-transform: uppercase;
                            letter-spacing: 0.07em;
                        }

                        .projet_top_pourcentage {
                            font-size: 10px;
                            font-weight: 400;
                        }
                    }

                    .projet_bottom {
                        height: 15px;
                        padding: 3px;
                        border-radius: 100px;
                        box-shadow: 0px 0px 7px rgba(197, 209, 217, 0.7);

                        .progress_bar {
                          border-radius: 100px;
                          background-color: red;
                          height: 9px;
                          transition: all 1000ms;

                          &.init {
                            width: 5% !important;
                            background-color: rgba(red,0);
                          }
                        }
                    }
                }
            }
        }

    }
}
