.header-titre-cta {

    .inner_content_header_titre_cta {
        // @include responsive(70px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        // @include responsive(25px, 30, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        &.transparent {
            background: transparent;
        }

        &.bleu {
            background: $bleuFonceFondHeader;
            @include responsive(15px, 15, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
        }

        &.gris {
            background: $grisClairFondHeader;
            @include responsive(85px, 15, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
        }
    }

    .inner_content_wrapper {
        @include responsive(40px, 20, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
    }

    .wrapper_breadcrumb {
        text-align: center;
        margin-bottom: 28px;
    }

    .wrapper_logo {
        display: flex;
        justify-content: center;
        
        img {
            @include max($breakpoint-medium) {
                width: 100%;
                max-width: 550px;
            }
        }
        @include responsive(0px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(35px, 5, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
    }

    .titre {
        @include titre-header();
        margin-bottom: 35px;
    }

    .sous_titre {
        text-align: center;
        @include font-responsive(14px, 6, $breakpoint-mobile, $desktop_to_mobile);
        @include responsive(20px, 8, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
        font-weight: 300;
        max-width: 738px;
        margin: 0 auto;
        margin-top: 35px;
        line-height: 1.4;
    }

    .wrapper_form_newsletter {
        margin-top: 55px;
        display: flex;
        justify-content: center;

        // overrules wpcf7 form
        form {
            width: 100%;
            max-width: 505px;

            p {
                display: flex;
                justify-content: center;
            }

            input[type='email'] {
                border: 0;
                width: 100%;
                height: 50px;
                padding: 0 23px;
                font-weight: 300;
                @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                line-height: 19px;
                color: $DarkBlue_Text;
            }

            input[type='submit'] {
                border: 0;
                background: $Red_Praxedo;
                height: 50px;
                padding: 0 25px;
                font-weight: 500;
                @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                line-height: 19px;
                color: white;

                &.button-mobile {
                    display: none;

                    @include max($breakpoint-mobile) {
                        display: block;
                    }
                }

                &.button-desktop {
                    display: block;

                    @include max($breakpoint-mobile) {
                        display: none;
                    }
                }
            }

            .your-email {
                display: flex;
                flex-direction: column;
            }

            .wpcf7-not-valid-tip {
                font-size: 0.7em;
                color: $Red_Praxedo;
            }

            .ajax-loader {
                display: none;
            }

            .wpcf7-response-output {
                margin-top: 1em;
                margin-bottom: 0;
                font-size: 0.7em;
            }
        }
    }

    .wrapper_ctas {
        display: flex;
        justify-content: center;
        z-index: 1;
        position: relative;
        @include responsive(40px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');

        @include max($breakpoint-mobile) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;
        }

        .cta {
            margin: 0 7px;

            @include max($breakpoint-mobile) {
                margin: 7px 0;
            }
        }
    }

    // surcharge couleur des textes si fonds bleu
    .bleu {
        .titre,
        .sous_titre,
        .wrapper_breadcrumb,
        .wpcf7-response-output {
            color: white;
        }

        .wrapper_breadcrumb {
            .separator {
                border-color: white;
            }
        }
    }
}
