.strate-faq {
    @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

    .inner_content_wrapper {
        display: flex;
        justify-content: space-between;
    }

    .wrapper_menu_categories_faqs {
        width: 300px;
        flex-shrink: 0;
        margin-right: 15px;

        @include max($breakpoint-medium) {
            display: none;
        }

        .menu_categories_faqs {
            width: 100%;
            position: sticky;
            top: 100px;

            .categorie_faq {
                border-bottom: 1px solid rgba($mediumGrey_Praxedo, 0.5);
                padding: 23px 0;

                a, button {
                    display: flex;
                    align-items: center;
                    opacity: 0.5;
                    transition: $transition_default;

                    &:hover,
                    &.active {
                        opacity: 1;
                    }

                    .icon {
                        flex-shrink: 0;
                        width: 40px;
                        display: flex;
                        align-items: center;
                        margin-right: 20px;

                        img {
                            max-width: 100%;
                        }
                    }

                    .libelle {
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 25px;
                        letter-spacing: 0.07em;
                    }
                }
            }
        }
    }

    .wrapper_categories_faqs {
        flex-grow: 1;
        max-width: 720px;

        @include max($breakpoint-medium) {
            max-width: 100%;
        }

        .faq {
            &:not(:last-child) {
                @include responsive(40px, 40, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
            }

            & > .title {
                font-weight: 700;
                @include font-responsive(20px, 12, $breakpoint-mobile, $desktop_to_mobile);
                @include responsive(28px, 11, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                @include responsive(25px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
            }

            // accordion
            .question {
                border-bottom: 1px solid transparentize($mediumGrey_Praxedo, 0.5);
                transition: $transition_standard;
                position: relative;
                cursor: pointer;

                .question_titre {
                    display: flex;
                    justify-content: space-between;
                    padding: 30px 0;

                    .libelle {
                        margin-right: 20px;
                        @include font-responsive(18px, 4, $breakpoint-mobile, $desktop_to_mobile);
                        @include responsive(20px, 8, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                    }

                    &::after {
                        content: url($pathToSVG + 'ico-plus.svg');
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        right: 0;
                        top: 30px;
                        display: block;
                    }

                    &.active {
                        &::after {
                            content: url($pathToSVG + 'ico-moins.svg');
                            margin-top: 0;
                            top: 25px;
                        }
                    }

                    &:hover, &.active {
                        color: $Red_Praxedo;
                    }
                }

                .question_content {
                    overflow: hidden;
                    margin: 0;
                    max-height: 0;
                    transition: $transition_standard;
                    @include font-responsive(18px, 0, $breakpoint-mobile, $desktop_to_mobile);

                    p {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}
