.wrapper_breadcrumb {
    font-size: 14px;
    line-height: 1.16;
    margin-bottom: 40px;
    letter-spacing: 0.03em;

    @include max($breakpoint-mobile) {
        display: none;
    }

    /*
    Obligatoire :
    coller "<span class="separator"></span>" dans BO / SEO / Search Appearence / Breadcrumbs / Breadcrumbs settings / Separator
    */
    >span>span, >span {

        a , >span a {
            transition: $transition_standard;

            &:hover {
                color: $Red_Praxedo;
            }
        }

        .separator {
            width: 5px;
            height: 5px;
            border-top: solid 1px $DarkBlue_Text;
            border-right: solid 1px $DarkBlue_Text;
            transform: rotate(45deg);
            margin: 0 8px 0 5px;
            display: inline-block;
        }

        .breadcrumb_last, .breacrumb_item {
            opacity: .4;
        }
    }
}
