.error404 {
    #neo_body {
        main {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: url($pathToPNG + '404_bg.png') no-repeat center center;
            margin-bottom: 50px;
            h2 {
                font-weight: 700;
                font-size: 20px;
                line-height: 25px;
                padding:30px 0;
            }
            img {
                width: 200px;
                height: auto;
            }
            @include min($breakpoint-large) {
                min-height: 650px;
                img {
                    width: auto;
                }
                h2 {
                    
                    font-size: 50px;
                    line-height: 55px;
                    padding:70px 0;
                }
            }
        }
    }
}