/*
comportement tablette/mobile : $breakpoint-medium: 768px;
*/

.wrapper_push_simple_blog {
    // 735
    // quelques variables
    $width_col_right: 360px;
    $gouttiere: 40px;

    background-color: $grisClairFondHeader;
    border-radius: 10px;
    padding: 32px 50px 36px 20px;
    display: flex;
    justify-content: space-between;

    @include max($breakpoint-medium) {
        flex-direction: column;
        padding: 58px 25px 33px 15px;
    }

    &.bleu {
        background-color: $DarkBlue_Text;
        color: white;
    }

    .col_left_psb {
        width: calc(100% - #{$width_col_right} - #{$gouttiere});

        @include max($breakpoint-medium) {
            width: 100%;
            height: 175px;
        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100% !important; // overrule réglages généraux liés à .single
        }
    }

    .col_right_psb {
        width: $width_col_right;

        @include max($breakpoint-medium) {
            width: 100%;
        }

        .titre_psb {
            font-size: 32px;
            font-weight: 500;
            line-height: 1.2em;
            margin-bottom: 14px;

            @include max($breakpoint-medium) {
                text-align: center;
                font-size: 20px;
                line-height: 1.4em;
            }
        }

        .texte_psb {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.27em;

            @include max($breakpoint-medium) {
                text-align: center;
                font-size: 14px;
                line-height: 1.35em;
            }
        }

        .wrapper_cta_psb {
            margin-top: 17px;

            @include max($breakpoint-medium) {
                margin-top: 25px;
                text-align: center;
            }

        }
    }
}