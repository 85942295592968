// FONTS

$font_family_niveau_grotesk: 'niveau-grotesk', sans-serif;
$font_family_niveau_grotesk_small_caps: 'niveau-grotesk-small-caps', sans-serif;


$font_size_base: 20px;
$font_base: $font_family_niveau_grotesk;

/*
$font-path: '../fonts/';
$font-graphik: 'Graphik', sans-serif;
$base-font-size: 16px;
$base-line-height: 1.2;

// FONT WEIGHT
$font-light: 300;
$font-regular: 400;
$font-medium: 600;
$font-bold: 700;
$font-extra-bold: 800;
*/


// COLORS
$white: #fff;
$black: #000;
$Bleu_Praxedo: #33adff;
$Rouge_Praxedo: #ea202c;
$Rouge_Praxedo2: #EE313B;
$Red_Praxedo: $Rouge_Praxedo;
$Gris_Praxedo: #566277;
$LightBleu_Praxedo: #d6efff;
$DarkBlue_Text: #0a2d40;
$grisClairFondHeader: #fafafc;
$bleuFonceFondHeader: #08293c;
$mediumGrey_Praxedo: #c5d1d9;
$greyPush_Praxedo: #f2f4f8;
$textColorFooter: #0a2540;
$LightGrey_Praxedo_Bkg: #fafafc;
$DarkGrey_Praxedo: #566277;
$greyBg_praxedo: #575757e3;
// paths
$pathToJPG: '../img/jpg/';
$pathToPNG: '../img/png/';
$pathToSVG: '../img/svg/';

// LAYOUT
// $layout-margin: 60px;

// GRID BREAKPOINTS
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
// $grid-breakpoints: (
//     xs: 0,
//     sm: 576px,
//     md: 768px,
//     lg: 992px,
//     nav: 1328px
// );
$breakpoint-nav: 1328px;
$breakpoint-header-main: 1440px;
$breakpoint-mobile: 480px;

$breakpoint-medium: 768px;
$breakpoint-large: 992px;

$breakpoint-strate-large: 1260px;
$breakpoint-strate-medium: 1140px;
$breakpoint-strate-small: 1024px;

$breakpoint-height-13pouces: 820px;


// marges
$marge-mini: 20px; // marge mobile par défaut

// quelques valeurs importantes
$hauteur_nav: 84px;
$desktop_to_mobile: 544 ; //calc (1024 - 480);
$medium_to_mobile: 288 ; //calc (768 - 480);
$large_to_mobile: 512 ; //calc (992 - 480);
$desktop_to_medium: 256; // calc (1024 - 768);


// TRANSITIONS
$transition_default: all 300ms;
$transition_standard: all 225ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
$transition_smooth_speed: all 350ms ease-in-out;
$transition_smooth: all 600ms ease-in-out;
$transition_smooth_slowly: all 800ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
$animation_error_fade_bg: errorFadeBg 1s ease-in-out forwards;
$animation_error_fade_text: errorFadeText 1s ease-in-out forwards;


@keyframes errorFadeBg {
  0% {
    background-color: $Rouge_Praxedo;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes errorFadeText {
  0% {
    color: $white;
  }
  100% {
    color: $Rouge_Praxedo;
  }
}
