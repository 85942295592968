.strate-2-cols-textes-seules-titres-avec-picto {

    .inner_content_strate_2_cols_textes_seules_titres_avec_picto {
        @include responsive(70px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(50px, 40, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
    }

    .inner_content_wrapper {
        position: relative;

        &.separation {
            border-bottom: 1px solid $mediumGrey_Praxedo;
            @include responsive(50px, 40, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
        }

        &.filet {

            .block:nth-child(1), .block:nth-child(2) {
                padding-bottom: 40px;
                border-bottom: 1px solid $mediumGrey_Praxedo;
            }
        }

        &.pictos_larges {

            .block .picto {
                width: 68px;
            }
        }
    }

    // largeur strate = 1140

    .titre {
        color: $Red_Praxedo;
        @include font-responsive(16px, 2, $breakpoint-mobile, $desktop_to_mobile);
        line-height: 1.4;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-bottom: 5px;
        text-align: center;
    }

    .wrapper_blocks {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        $gouttiere_2ctstap: 40px;

        .block {
            width: calc(50% - (#{$gouttiere_2ctstap} / 2));
            display: flex;
            margin-top: 45px;

            @include max($breakpoint-medium) {
                width: 100%;
            }

            .picto {
                display: flex;
                width: 45px;
                flex-shrink: 0;

                .puce {
                    display: block;
                    width: 8px;
                    height: 8px;
                    background: $Red_Praxedo;
                    margin: 10px 10px 0 auto;
                    border-radius: 20px;
                }

                svg {
                    g {
                        mask: none;
                    }
                }
            }

            .inner_content_block {

                .titre_block {
                    @include font-responsive(18px, 4, $breakpoint-mobile, $desktop_to_mobile);
                    line-height: 1.27;
                    margin-bottom: 10px;

                    a:hover {
                        color: $Red_Praxedo;
                    }
                }

                .texte_block {
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 1.43;
                }

                .cta_savoir_plus {
                    margin-top: 10px;
                }
            }
        }
    }
}
