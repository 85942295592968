/*
Comportement tablette / mobile => $breakpoint-strate-small: 1024px;
*/


.sup_margin_top {

    .partageable-notes-etoiles {
        @include max($breakpoint-strate-small) {
            margin-top: 10px;
        }
    }
}

.partageable-notes-etoiles {
    display: flex;
    justify-content: center;
    @include responsive(20px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
    @include responsive(20px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

    @include max($breakpoint-strate-small) {
        flex-direction: column;
        align-items: center;
    }

    .col {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        height: 60px;
        box-shadow: 0px 0px 20px rgba(158, 168, 169, 0.2);
        border-radius: 10px;
        padding: 0 25px;
        margin: 0 10px;

        @include max($breakpoint-strate-small) {
            width: 100%;
            max-width: 340px;
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .libelle {
            white-space: nowrap;
            font-size: 13px;
            font-weight: 500;
            margin-right: 15px;
        }

        .etoiles {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            .note {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0.05em;
                color: #FFAB2B;
                margin-right: 6px;
            }
        }

        .logo {
            margin-right: 20px;
        }
    }
}
