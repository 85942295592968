$bp_max_fr_hplb: 660; // calc(1140 - 480);

.header-page-liste-besoins {
    // wrapper_strate_medium : 1140px

    .inner_content_header_page_liste_besoins {
        margin-top: 0;
        margin-bottom: 125px;
        position: relative;
        // background-color: $grisClairFondHeader;

        @include max($breakpoint-strate-medium) {
            overflow: hidden;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 800px;
            // background-color: white;
            background-color: $grisClairFondHeader;
            @include responsive(790px, 10, $breakpoint-mobile, $desktop_to_mobile, 'height');
        }
    }

    .inner_content_wrapper {
        padding-top: 72px;
        position: relative;
        z-index: 2;
        // padding-bottom: 145px;
        // @include responsive(70px, 30, $breakpoint-mobile, $bp_max_fr_hplb, 'padding-top' );
        // @include responsive(70px, 75, $breakpoint-mobile, $bp_max_fr_hplb, 'padding-bottom' );
    }

    .wrapper_top {
        .wrapper_breadcrumb {
            text-align: center;
        }

        .titre {
            @include titre-strate();
            // @include font-responsive(30px, 20, $breakpoint-mobile, $bp_max_fr_hplb); // 50xp - 50px - 480px - 1140px
            font-size: 65px;
            text-align: center;
            margin-bottom: 36px;
            line-height: 1.07;
        }

        .sous_titre {
            font-weight: 300;
            text-align: center;
        }
    }

    .wrapper_bottom {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: calc(94px - 60px); // pour compenser margin-top:60px des besoins;
        @include max($breakpoint-strate-large) {
            justify-content: space-evenly;
        }
    }

    .besoin {
        width: 400px;
        min-height: 480px;
        background-color: $white;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        padding: 37px 37px 54px;
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        &.fake {
            min-height: 0;
            height: 0;
            opacity: 0;
            padding: 0;
            margin: 0;
            overflow: hidden;
        }

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            background-color: $Red_Praxedo;
            position: absolute;
            bottom: 0;
            left: 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }


        .wrapper_svg {
            height: 42px; // pour forcer l'alignement vertical quel que soit la hauteur du svg (max 40)
            margin-bottom: 32px;

            &.bigger {
                height: 65px;
            }

            svg {
                max-height: 100%;
                width: auto;
            }
        }

        .titre_block {
            font-size: 32px;
            line-height: 1.2;
            font-weight: 500;
            margin-bottom: 14px;
            min-height: 75px;

            a:hover {
                color: $Red_Praxedo;
            }
        }

        .texte_block {
            font-weight: 300;
            font-size: 18px;
            line-height: 1.4;
            // overflow: hidden;
            // display: -webkit-box;
            // -webkit-line-clamp: 7;
            // -webkit-box-orient: vertical;
        }

        .wrapper_cta {
            margin-top: 48px;
        }
    }
}
