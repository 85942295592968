.single {
    &.strate-contenu-temoignage {
        position: relative;
    }

    .single_header {
        @include responsive(40px, 30, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
        @include responsive(70px, 30, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

        .inner_content_wrapper {
            display: flex;
            justify-content: space-between;

            @include max($breakpoint-large) {
                flex-direction: column;
            }

            .wrapper_img {
                width: 100%;
                max-width: 540px;
                max-height: 350px;
                margin-right: 60px;
                flex-shrink: 0;
                overflow: hidden;

                @include max($breakpoint-large) {
                    margin-right: 0;
                    max-width: 100%;
                    height: 350px;
                    margin-bottom: 25px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .wrapper_content {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                padding-top: 10px;

                .list_categories {
                    display: flex;
                    margin-bottom: 27px;
                    flex-wrap: wrap;

                    .category {
                        color: $Rouge_Praxedo;
                        border: solid 1px $Rouge_Praxedo;
                        background: $white;
                        border-radius: 17px;
                        padding: 5px 10px;
                        font-size: 8px;
                        font-weight: 500;
                        letter-spacing: 0.07em;
                        text-transform: uppercase;
                        margin-bottom: 8px;

                        &:not(:last-child) {
                            margin-right: 8px;
                        }
                    }
                }

                .titre {
                    flex-grow: 1;
                    @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile);
                    @include responsive(35px, 20, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                    font-weight: 500;
                    color: inherit;
                    @include responsive(25px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                }

                .infos {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    border-top: 1px solid rgba($mediumGrey_Praxedo, 0.5);
                    @include responsive(22px, 7, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
                }

                .date {
                    @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                    line-height: 26px;
                    font-weight: 300;
                }

                .auteur {
                    display: flex;
                    align-items: center;

                    &_image {
                        width: 45px;
                        height: 45px;
                        background-color: white;
                        margin-right: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    &_nom {
                        @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                        line-height: 26px;
                        font-weight: 300;
                    }
                }
            }
        }

        &.single-blog_header {
            @include responsive(30px, 50, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
            background: $bleuFonceFondHeader;

            display: flex;
            align-items: center;
            flex-direction: column;

            .wrapper_breadcrumb {
                text-align: center;
                color: $white;

                .separator {
                    border-color: $white;
                }
            }

            .inner_content_wrapper {
                .wrapper_content {
                    color: $white;
                }
            }
        }

        &.single-actualite_header {
            .wrapper_breadcrumb {
                text-align: center;
            }
        }
    }

    .single_content {
        position: relative;
        margin-bottom: 100px;

        & > .wrapper_content {
            max-width: 855px;
            min-height: 300px;
            box-shadow: 0px 0px 50px rgba(204, 213, 222, 0.35);
            border-radius: 10px;
            padding: 45px 60px;
            margin: 0 auto 20px;

            @include max(900px) {
                margin: 0 15px 20px;
                padding: 20px;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    .single_similar-posts {
        @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

        .single_similar-posts_titre {
            @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile);
            @include responsive(35px, 20, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
            @include responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
            font-weight: 700;
            color: $DarkBlue_Text;
        }

        .similar-posts {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @include max($breakpoint-medium) {
                flex-direction: column;
            }

            .post {
                //width: calc(33.33333333333% - 40px);
                max-width: 340px;
                width: 100%;
                @include responsive(50px, -50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                display: flex;
                flex-direction: column-reverse;
                flex-wrap: wrap;
                align-items: stretch;

                @include max($breakpoint-medium) {
                    width: 100%;
                    max-width: 100%;
                }

                .wrapper_img {
                    width: 100%;
                    min-height: 220px;
                    flex-shrink: 0;
                    margin-bottom: 25px;
                    position: relative;

                    a , button{
                        display: block;
                        height: 100%;

                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: top center;

                    }

                    .player_video {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            width: 70px;
                            height: 70px;
                        }
                    }
                }

                .wrapper_content {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;

                    .list_categories {
                        display: flex;
                        margin-bottom: 10px;
                        flex-wrap: wrap;

                        .category {
                            color: $Rouge_Praxedo;
                            border: solid 1px $Rouge_Praxedo;
                            border-radius: 17px;
                            padding: 5px 10px;
                            font-size: 8px;
                            font-weight: 500;
                            letter-spacing: 0.07em;
                            text-transform: uppercase;
                            margin-bottom: 8px;

                            &:not(:last-child) {
                                margin-right: 8px;
                            }
                        }
                    }

                    .wrapper_content_link {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                    }

                    .titre {
                        font-weight: 500;
                        font-size: 22px;
                        line-height: 28px;
                        color: $DarkBlue_Text;
                        margin-bottom: 20px;
                        flex-grow: 1;
                    }

                    .date {
                        font-size: 14px;
                        line-height: 26px;
                        font-weight: 300;
                        padding-top: 15px;
                        border-top: 1px solid rgba($mediumGrey_Praxedo, 0.5);
                    }

                    .logo_societe {
                        width: 45%;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                &.empty_block {
                    height: 0px;
                    margin: 0;
                }
            }
        }
    }

    &.single-blog {
        
        .single_header .inner_content_wrapper .wrapper_content {
            .infos {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgba($mediumGrey_Praxedo, 0.5);
                border-top: none;
                padding-top: 0;
                @include responsive(22px, 7, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
            }

            .date-readingtime  {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
                @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                line-height: 26px;
                font-weight: 300;
            }

            .readingtime {
                .time {
                    font-weight: 600;
                }
            }
        }
    }
}
