.strate-formulaire-right-content {
    @include responsive(70px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

    .inner_content_strate_formulaire_right_content {
        background: $grisClairFondHeader;
        @include responsive(35px, 30, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
        @include responsive(45px, 40, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');

        &.with_background_image {
            background-repeat: no-repeat;
            background-position: 95% bottom;

            @include max($breakpoint-strate-small) {
                background-position: center bottom;
                background-size: 400px;
                padding-bottom: 350px;
            }
        }
    }

    .inner_content_wrapper {
        display: flex;

        @include max($breakpoint-strate-small) {
            flex-direction: column;
            align-items: center;
        }

        .hubspot-multistep-form {
            flex-shrink: 0;
            width: 720px;
            min-height: 630px;
            box-shadow: 0px 0px 30px rgba(158, 168, 169, 0.2);
            border-radius: 7px;
            background: $white;

            padding: 68px 60px;

            @include max($breakpoint-strate-small) {
                width: 100%;
                max-width: 720px;
                margin-bottom: 40px;
                padding: 25px 20px;
            }

            &.texte_ctas {

                .titre_gauche {
                    @include titre-strate();
                    margin-bottom: 52px;
                }

                .wysiwyg {

                    p {
                        margin-top: 35px;
                    }
                }

                .wrapper_cta {
                    margin-top: 60px;

                    a, button {
                        margin-top: 25px;
                        display: flex;
                        width: 100%;
                    }
                }
            }

            iframe {
                border: 0;
                width: 100% !important;

                @at-root {
                    // body max = 680

                    .hbspt-form { // Intérieur de l'iframe (attention aux classes qui ne commencent pas par "HS")
                        /*
                        La plupart de nos mixin ne fonctionne pas ici à cause des !important qu'il faut coller presque partout
                        */
                        @mixin responsive_important ($min, $diff, $breakmin, $breakdiff, $prop) {
                            #{$prop}: calc(#{$min} + #{$diff} * ((100vw - #{$breakmin}) / #{$breakdiff}));
                            @media(max-width: $breakmin) {
                                #{$prop}: $min !important;
                            }
                            @media(min-width: round($breakmin + $breakdiff)) {
                                #{$prop}: round($min + $diff) !important;
                            }
                        }

                        form {
                            font-family: $font_base;
                            font-size: $font_size_base;
                            line-height: 1.4em;
                            font-weight: 500;

                            h1 {
                                font-family: $font_base !important;
                                font-weight: 700 !important;
                                color: $DarkBlue_Text !important;
                                line-height: 1.1em !important;

                                @include responsive_important(30px, 20, 480px, 200, 'font-size');
                                @include responsive_important(20px, 32, 480px, 200, 'margin-bottom');
                            }

                            label {
                                font-family: $font_base !important;
                                @include responsive_important(14px, 2, 480px, 200, 'font-size');
                                line-height: 1.75em;
                                letter-spacing: 1px;
                                text-transform: uppercase;
                                margin-bottom: 5px;
                            }

                            .field {
                                @include responsive_important(20px, 30, 480px, 200, 'margin-bottom');
                            }

                            .hs-input {
                                font-family: $font_base !important;
                                @include responsive_important(40px, 10, 480px, 200, 'min-height');
                                background-color: white !important;
                            }

                            .hs-button {
                                width: 100% !important;
                                font-family: $font_base !important;
                                @include responsive_important(40px, 10, 480px, 200, 'min-height');
                                font-weight: 500 !important;
                                font-size: 16px !important;
                            }
                        }

                        .hs-main-font-element {
                            font-family: $font_base !important;
                            font-size: $font_size_base !important;
                            line-height: 1.4em !important;
                            font-weight: 400 !important;
                        }
                    }
                }
            }
        }

        .wrapper_content {
            flex-grow: 1;
            margin-left: 50px;
            @include responsive(0px, 40, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
            @include responsive(0px, 30, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');

            @include max($breakpoint-strate-small) {
                margin-left: 0;
            }

            .inner_content {
                @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                @include responsive(20px, 3, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                @include responsive(0px, 80, $breakpoint-strate-small, 200, 'padding-left');
                @include responsive(0px, 80, $breakpoint-strate-small, 200, 'padding-right');

                h1,
                h2,
                h3 {
                    @include font-responsive(18px, 0, $breakpoint-mobile, $desktop_to_mobile);
                    @include responsive(28px, 0, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                    margin-bottom: 20px;
                    font-weight: 500;
                    color: $DarkBlue_Text;
                }

                p {
                    margin-bottom: 15px;
                }

                ul {
                    padding-left: 20px;
                    margin-bottom: 15px;
                    font-weight: 300;

                    li {
                        position: relative;
                        list-style-image: url($pathToSVG + 'avantage_coche.svg');

                        &:not(:last-child) {
                            @include responsive(10px, 2, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                        }
                    }
                }
            }

            .inner_image {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .partageable-notes-etoiles {
            flex-wrap: wrap;

            .col {
                box-shadow: unset;
                flex-direction: column;
                padding: 0 5px;

                @include max($breakpoint-strate-small) {
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    height: auto;
                }

                .libelle {
                    margin: 0;
                    margin-bottom: 10px;
                    text-transform: uppercase;
                    font-size: 10px;
                    letter-spacing: 0.05em;

                    @include max($breakpoint-strate-small) {
                        margin-bottom: 0;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
