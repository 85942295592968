$pathToFont: '../fonts/';

@font-face {
    font-family: 'niveau-grotesk';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Extra-Light.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Extra-Light.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Extra-Light.woff2') format('woff2');
    font-display: auto;
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: 'niveau-grotesk';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Extra-Light.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Extra-Light.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Extra-Light.woff2') format('woff2');
    font-display: auto;
    font-style: italic;
    font-weight: 200;
}

@font-face {
    font-family: 'niveau-grotesk';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Light.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Light.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Light.woff2') format('woff2');
    font-display: auto;
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'niveau-grotesk';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Light.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Light.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Light.woff2') format('woff2');
    font-display: auto;
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: 'niveau-grotesk';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Regular.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Regular.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Regular.woff2') format('woff2');
    font-display: auto;
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: 'niveau-grotesk';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Regular.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Regular.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Regular.woff2') format('woff2');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'niveau-grotesk';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Medium.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Medium.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Medium.woff2') format('woff2');
    font-display: auto;
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'niveau-grotesk';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Medium.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Medium.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Medium.woff2') format('woff2');
    font-display: auto;
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: 'niveau-grotesk';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Bold.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Bold.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Bold.woff2') format('woff2');
    font-display: auto;
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: 'niveau-grotesk';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Bold.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Bold.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Bold.woff2') format('woff2');
    font-display: auto;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'niveau-grotesk';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Bold.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Bold.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Bold.woff2') format('woff2');
    font-display: auto;
    font-style: italic;
    font-weight: 900;
}

@font-face {
    font-family: 'niveau-grotesk';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Bold.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Bold.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Bold.woff2') format('woff2');
    font-display: auto;
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: 'niveau-grotesk-small-caps';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Regular.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Regular.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Regular.woff2') format('woff2');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'niveau-grotesk-small-caps';
    src: url( $pathToFont + 'Niveau-Grotesk-Web-Bold.eot') format('eot'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Bold.woff') format('woff'), 
        url( $pathToFont + 'Niveau-Grotesk-Web-Bold.woff2') format('woff2');
    font-display: auto;
    font-style: normal;
    font-weight: 700;
}
