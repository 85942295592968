/*
largeur strate = wrapper_strate_large = 1260px
gouttiere = 81px
largeur colonne = 366px =  29,047619047619048%;
*/

.strate-3-cols-titre-intro-ctas-picto {


    .inner_content_strate_3_cols_titre_intro_ctas_picto {
        @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(30px, 70, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
        @include responsive(30px, 70, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
        background-color: $grisClairFondHeader;
    }

    .wrapper_top {
        text-align: center;

        .titre {
            max-width: 760px;
            margin: auto;
            @include titre-strate();
            @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile);
        }
    }

    .wrapper_bottom {
        @include responsive(43px, 30, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include max($breakpoint-strate-medium) {
            flex-direction: column;
        }

        .colonne {
            width: 29.047619047619048%;
            max-width: 370px;

            @include max($breakpoint-strate-medium) {
                margin-top: 20px;
                max-width: 100%;
                width: 100%;
            }

            .titre_colonne {
                @include titre-strate();
                @include font-responsive(20px, 12, $breakpoint-mobile, $desktop_to_mobile);
                font-weight: 500;
                @include responsive(35px, 4, $breakpoint-mobile, $desktop_to_mobile, 'line-height');

            }

            .introduction_colonne {
                font-weight: 300;
                font-size: 16px;
                margin-top: 20px;
                @include responsive(20px, 3, $breakpoint-mobile, $desktop_to_mobile, 'line-height');

                @include max($breakpoint-strate-medium) {
                    font-weight: 400;
                }
            }

            .wrapper_liens_colonne {
                margin-top: 40px;

                button.lien_colonne {
                    width: 100%;
                    text-align: left;

                    .picto_lien {
                        display: flex;
                        justify-content: flex-start;
                    }
                }

                .lien_colonne {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px 0;
                    border-bottom: 1px solid $mediumGrey_Praxedo;
                    position: relative;

                    &:last-child {
                        border-bottom: 0px;
                    }

                    .picto_lien {
                        width: 20px;
                        margin-right: 20px;
                        line-height: 0;
                        display: flex;

                        svg {
                            display: flex;
                            width: 20px;

                            g {
                                mask: none;
                            }

                            path {
                                transition: $transition_standard;
                            }
                        }
                    }

                    .texte {
                        width: calc(100% - 70px);
                        font-size: 15px;
                        font-weight: 400;
                        transition: $transition_standard;
                        margin-right: 30px;
                    }

                    .arrow {
                        display: flex;
                        justify-content: flex-end;
                        width: 30px;
                        line-height: 0;
                        transition: $transition_standard;
                        position: absolute;
                        right: 5px;
                    }

                    &:hover {
                        .texte {
                            color: $Red_Praxedo;
                        }

                        .arrow {
                            right: 0;
                        }

                        .picto_lien {
                            svg {
                                path {
                                    fill: $Red_Praxedo;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}
