.strate-header-cpt-temoignage {
    @include responsive(50px, 30, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

    .inner_content_strate_header_cpt_temoignage_top {
        background-color: $LightGrey_Praxedo_Bkg;

        .inner_content_wrapper {
            @include responsive(35px, 50, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
            @include responsive(85px, 80, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
        }

        .wrapper_breadcrumb {
            @include max($breakpoint-medium) {
                display: none;
            }
        }

        .header_top {
            display: flex;
            justify-content: space-between;

            @include max($breakpoint-medium) {
                flex-direction: column;
            }

            & > .wrapper_titre {
                flex-grow: 1;
                max-width: 660px;

                @include max($breakpoint-medium) {
                    max-width: 100%;
                    margin-bottom: 35px;
                }

                .titre {
                    @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile);
                    @include responsive(35px, 20, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                    font-weight: 500;
                    @include responsive(20px, 5, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                }

                .temoignage {
                    @include texte-standard();
                    @include responsive(40px, -10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                }

                .auteur {
                    display: flex;
                    align-items: center;

                    .auteur_image {
                        width: 60px;
                        height: 60px;
                        border-radius: 10px;
                        margin-right: 18px;
                        flex-shrink: 0;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .auteur_content {
                        flex-grow: 1;

                        .nom_auteur {
                            @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                            font-weight: 700;
                            line-height: 26px;
                        }

                        .fontion_auteur {
                            @include font-responsive(12px, 2, $breakpoint-mobile, $desktop_to_mobile);
                            font-weight: 300;
                        }
                    }
                }
            }

            & > .wrapper_img_logo {
                width: 360px;
                height: 330px;
                flex-shrink: 0;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
                border-radius: 10px;
                margin-left: 10px;

                img {
                    max-width: 100%;
                }

                @include max($breakpoint-medium) {
                    margin: 0 auto;
                    width: 100%;
                    max-width: 360px;
                }
            }
        }
    }

    .inner_content_strate_header_cpt_temoignage_bottom {
        @include responsive(-40px, -30, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');

        .inner_content_wrapper {

            & > .wrapper_image {
                position: relative;
                @include responsive(30px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

                img {
                    width: 100%;
                }

                .btn_player-video {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    // width: 100%;
                    // height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 0;
                    background-color: transparent;

                    svg {
                        @include responsive(60px, 30, $breakpoint-mobile, $desktop_to_mobile, 'width');
                    }
                }

                .video_embed {
                    display: none;
                }
            }

            & > .wrapper_video_inline {
                display: flex;
                justify-content: center;
                position: relative;
                @include responsive(30px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

                //@include max($breakpoint-medium) {
                    iframe {
                        border: none;
                        width: 100%;
                        height: 100%;
                        aspect-ratio: 16/9;
                        left: 50%;
                        transform: translateX(-50%);
                        position: relative;
                    }
                //}
            }

            & > .list_chiffres_cles {
                display: flex;
                justify-content: space-between;

                @include max($breakpoint-medium) {
                    flex-direction: column;
                }

                .chiffre_cle {
                    display: flex;
                    flex-direction: column;
                    // justify-content: center;
                    align-items: center;
                    @include responsive(5px, 25, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
                    @include responsive(5px, 25, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
                    position: relative;
                    flex-grow: 1;
                    max-width: 33%;

                    &:not(:last-child) {

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 0;
                            background-color: rgba($mediumGrey_Praxedo, 0.5);
                            width: 1px;
                            height: 100%;
                        }
                    }

                    @include max($breakpoint-medium) {
                        max-width: 100%;
                        border-bottom: 1px solid rgba($mediumGrey_Praxedo, 0.5);
                        padding-bottom: 20px;
                        align-items: center !important;
                        text-align: center;

                        &:not(:last-child) {
                            &::after {
                                display: none;
                            }
                        }
                    }

                    & > .wrapper {
                        padding: 0 10px;
                    }

                    .libelle {
                        @include font-responsive(16px, 2, $breakpoint-mobile, $desktop_to_mobile);
                        letter-spacing: 0.07em;
                        line-height: 25px;
                        text-transform: uppercase;
                        color: $Red_Praxedo;
                        font-weight: 500;
                        @include responsive(0px, 13, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                    }

                    .info {
                        @include font-responsive(20px, 12, $breakpoint-mobile, $desktop_to_mobile);
                        color: $DarkBlue_Text;
                    }
                }
            }
        }

    }
}
