.popin_video {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $greyBg_praxedo;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    // transform: scale(.8);
    opacity: 0;
    visibility: hidden;
    z-index: -9999;
    // transition: $transition_smooth_slowly;

    &.activate {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
        z-index: 9999;
    }

    .close_video {
        position: absolute;
        // right: 30px;
        // top: 30px;
        right: 3vh;
        top: 6vh;
        width: 40px;
        height: 40px;
        cursor: pointer;
        z-index: 9999;

        > .icon {
            width: 100%;
            height: 100%;
            display: flex;
            background-color: $white;
            border-radius: 50%;
            align-items: center;
            justify-content: center;

            > svg {
                position: relative;
                left: 0px;
                width: 15px;
                height: 15px;
                object-fit: contain;

                path {
                    fill: $black;
                }
            }
        }
    }

    .video_embedded {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9000;

        iframe, iframe.active {

            width: 80%;
            height: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 10px;

            @include between($breakpoint-medium, $breakpoint-large) {
                height: 50%;
            }

            @include max($breakpoint-medium) {
                height: 40%;
            }

            .ytp-share-button {
                visibility: hidden;
            }
        }
    }
}
