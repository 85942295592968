.strate-maillage-autres-temoignages {



    // wrapper_strate_medium : 1140px


    .inner_content_strate_maillage_autres_temoignages {
        @include responsive(80px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
    }

    .wrapper_titre {
        margin-bottom: 48px;

        .titre {
            @include titre-strate();
        }
    }




    .list_posts {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include max($breakpoint-medium) {
            flex-direction: column;
        }

        .post {
            width: calc(33.33333333333% - 40px);
            // @include responsive(50px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
            display: flex;
            flex-direction: column-reverse;
            flex-wrap: wrap;
            align-items: stretch;

            @include max($breakpoint-medium) {
                width: 100%;
            }

            .wrapper_img {
                width: 100%;
                flex-shrink: 0;
                margin-bottom: 25px;
                position: relative;
                border-radius: 10px;

                a, button {
                    display: block;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .player_video {
                    @include player_video();

                    &.js_player_video_popin {
                        cursor: pointer;
                    }
                }
    
                .logo-company {
                    max-width: 100px;
                    width: 100px;
                    height: 50px;
                    border-radius: 10px;
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    box-shadow: 0px 0px 20px rgba(78,78,78,0.19);
    
                    img {
                        border-radius: 10px;
                        background: $white;
                        object-fit: contain;
                        padding: 3px;
                    }
                }

                .video_embed {
                    display: none;
                }
    
                img {
                    border-radius: 10px;
                }
            }

            .wrapper_content {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .badge {
                    @include badge();
                }

                .wrapper_content_link {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                }

                .titre {
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 28px;
                    color: $DarkBlue_Text;
                    margin-bottom: 20px;
                    flex-grow: 1;
                }

                .logo-company {
                    max-width: 50%;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .date {
                    font-size: 14px;
                    line-height: 26px;
                    font-weight: 300;
                    padding-top: 15px;
                    border-top: 1px solid rgba($mediumGrey_Praxedo, 0.5);
                }
            }

            &.empty_block {
                height: 0px;
                margin: 0;
            }
        }
    }
}
