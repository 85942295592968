.strate-pricing-3-col {
    @include max($breakpoint-strate-small) {
        overflow: hidden;
        //padding et margin important pour les ombres des blocs
        padding-top: 10px;
        margin-top: -10px;
    }

    .inner_content_strate_pricing_3_col {
        padding: 30px 0;

        &.blue {
            background: $bleuFonceFondHeader;

            .titre {
                color: $white;
            }
        }
    }

    .inner_content_wrapper {
        display: flex;
        justify-content: space-between;
        
        &.nb-2 {
            justify-content: center;
        }

        @include max($breakpoint-strate-small) {
            display: block;
        }
    }

    .titre {
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 25px;
    }

    .formule {
        width: 31.746031746031746%;
        background: $white;
        box-shadow: 0px 0px 20px rgba(78, 78, 78, 0.1);
        border-radius: 10px;
        border: solid 1px #fff;
        padding: 45px 32px 80px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: $transition_default;

        @include max($breakpoint-strate-small) {
            width: 300px;
            max-width: 400px;
            margin: 0 auto;
            max-height: 419px;
        }

        &:hover {
            border: solid 1px $Red_Praxedo;
            background: #FFE1E3;

            .cta_large {
                background-color: $Red_Praxedo;
                border: solid 1px $Red_Praxedo;

                .texte_cta {
                    color: #fff;
                }
            }
        }

        .picto {
            line-height: 0;
            min-height: 54px;
        }

        .ia-active {
            position: absolute;
            top: 10px;
            right: 10px;
        }

        .titre_formule {
            margin: 27px 0 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 1.4;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            text-align: center;
        }

        .prix {
            font-weight: 500;
            font-size: 25px;
            line-height: 1.2;
            margin: 10px 0 25px;
        }

        .cta_large {
            width: 100%;
            justify-content: center;
            height: 50px;
            transition: $transition_default;

            .texte_cta {
                font-size: 16px;
                transition: $transition_default;
            }
        }

        .titre_colonne_avantages {
            margin-top: 43px;
            width: 100%;
            font-weight: 500;
        }

        .wrapper_avantages {
            margin-top: 22px;

            .avantage {
                display: flex;
                margin-bottom: 12px;

                &.texte_avantage_barre {
                    .texte_avantage {
                        text-decoration: line-through;
                    }
                }

                .picto_avantage {
                    width: 27px;
                    flex-shrink: 0;
                }

                .texte_avantage {
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 1.4;
                }
            }
        }

        .cta_savoir_plus {
            position: absolute;
            left: 32px;
            bottom: 34px;
        }
    }

    .owl-stage {
        display: flex;
    }

    .owl-dots {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .owl-dot {
            width: 5px;
            height: 5px;
            border-radius: 50px;
            background-color: rgba(255, 255, 255, 0.2);
            margin: 0 15px;

            &.active {
                background-color: $white;
            }
        }
    }

    .owl-stage-outer {
        overflow: visible;
    }
}

.strate-pricing-3-col-v2 {
    .inner_content_wrapper {
        gap: 10px;
    }
    .formule {
        padding: 30px 20px 20px;
        
        .titre_formule {
            margin: 30px 0 10px;
        }
        .inside_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 96px;
        }
        
        .prix {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin:0;

            span {
                margin-top: 3px;
                font-weight: 300;
                font-size: 16px;
                line-height: 17px;
            }
        }

        .titre_colonne_avantages {
            margin-top: 20px;
            text-align: center;
            font-size: 16px;
            line-height: 23px;
            font-weight: 300;
        }

        .cta_detail_offre {
            margin:16px 0 35px;
        }
    }
}