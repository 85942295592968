.strate-addons-connecteurs {

  .inner_content_strate_addons_connecteurs {
    padding: 30px 0 60px;

    &.blue {
      background: $bleuFonceFondHeader;

      .wrapper .label {
        color: $white;
      }
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;

    @include min($breakpoint-large) {
      display: flex;
      justify-content: space-between;
      gap: 0;
    }

    .row {
      display: flex;
      flex-direction: column;
      gap: 22px;

      @include min($breakpoint-large) {
        flex-direction: row;
        align-items: center;
      }

      .label {
        text-align: center;

        @include min($breakpoint-large) {
          text-align: left;
        }

        .text {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;

          @include min($breakpoint-large) {
            font-size: 16px;
            margin-bottom: 6px;
          }

        }

        a {
          font-size: 14px;
          text-decoration: none;

          @include min($breakpoint-large) {
            font-size: 16px;
          }
        }
      }
      .logos {
        text-align: center;

        img {
          max-height: 40px;
          @include min($breakpoint-large) {
            max-height: 60px;
          }
        }
      }
    }
  }
}