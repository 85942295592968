$padding_lateral_block_desktop: 27px;
$padding_lateral_block_mobile: 20px;

.strate-3-blocks-contact {

    .inner_content_strate_3_blocks_contact {
        // 1260
        @include responsive(60px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(60px, 70, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
    }

    .inner_content_wrapper {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .wrapper_block_contact {
            // 400 x 450
            width: 31.746031746031746%;
            height: 450px;
            @include responsive(40px, 15, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
            padding-bottom: 0;
            @include responsive($padding_lateral_block_mobile, 7, $breakpoint-mobile, $desktop_to_mobile, 'padding-left');
            @include responsive($padding_lateral_block_mobile, 7, $breakpoint-mobile, $desktop_to_mobile, 'padding-right');
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
            border-radius: 10px;
            position: relative;

            @include max($breakpoint-medium) {
                width: 100%;
                flex-shrink: 0;
                height: 360px;
                margin-bottom: 30px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .wrapper_icon {
                margin-bottom: 40px;
            }

            .titre {
                font-weight: 400;
                @include font-responsive(20px, 12, $breakpoint-mobile, $desktop_to_mobile);
                @include responsive(28px, 11, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                text-align: center;
                margin-bottom: 16px;
            }

            .texte {
                font-weight: 400;
                @include font-responsive(14px, 4, $breakpoint-mobile, $desktop_to_mobile);
                @include responsive(20px, 5, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                text-align: center;
            }

            .wrapper_cta {
                position: absolute;
                width: calc(100% - 2 * #{$padding_lateral_block_desktop});

                @include responsive(50px, 10, $breakpoint-mobile, $desktop_to_mobile, 'bottom');
                @include responsive($padding_lateral_block_mobile, 7, $breakpoint-mobile, $desktop_to_mobile, 'left');

                @include max($breakpoint-medium) {
                    width: calc(100% - 2 * #{$padding_lateral_block_mobile});
                }

                a, button {
                    display: flex;

                    .texte_cta {
                        text-align: center;
                    }
                }
            }
        }
    }
}
