/*
Fauille de style utilisé pour les pagees suivantes :
    liste Blog          (cpt blog)
    liste Témoignages   (cpt terstimonial)
    liste Guides        (cpt guide)
    liste cas d'usage   (cpt cas_usage)

régles spécifiques à page liste témoignages et page liste guides à la fin
*/






.strate-archive-blog {
    // wrapper_strate_medium : 1140px
    transition: .2s;

    &.loading {
        pointer-events: none;
        opacity: .5;
    }

    .inner_content_strate_articles_page_blog {
        @include responsive(80px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
    }

    // utilisé dans la version "Maillage 3 actualités seulement (avec titre, texte et cta)"
    .top_actu {
        display: flex;
        justify-content: space-between;
        margin-bottom: 55px;
        @include max($breakpoint-medium) {
            display: block;
        }

        .top_actu_right {
            @include max($breakpoint-medium) {
                margin-top: 20px;
            }
        }

        .titre_strate_top {
            @include titre-strate();
            margin-bottom: 20px;
        }

        .texte_strate_top {
            font-weight: 300;
            line-height: 1.4em;
        }

    }

    .filter_search_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include responsive(35px, 25, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        padding-bottom: 35px;
        border-bottom: 1px solid rgba($mediumGrey_Praxedo, 0.5);
        margin-top: 35px;

        @include max($breakpoint-large) {
            flex-direction: column;
        }

        .filter_wrapper {
            display: flex;
            align-items: center;
            position: relative;

            @include max($breakpoint-large) {
                margin-bottom: 25px;
            }

            select {
                -webkit-appearance: none;
                appearance: none;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.07em;
                text-transform: uppercase;
                border: 0;
                padding-right: 20px;
                background: $white;
            }

            .icon {
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                margin-left: 8px;
                pointer-events: none;

                svg {
                    path {
                        stroke: $Rouge_Praxedo;
                    }
                }
            }
        }

        .search_wrapper {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            width: 100%;
            max-width: 250px;
            height: 50px;
            padding: 0 15px;
            border: 1px solid rgba($mediumGrey_Praxedo, 0.5);
            border-radius: 100px;

            @include max($breakpoint-medium) {
                max-width: 450px;
            }

            input {
                border: 0;
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;
                flex-grow: 1;
            }

            button {
                background: transparent;
                border: 0;
                display: flex;
                align-items: center;
            }
        }
    }

    #ajax_result {
        .no_results {
            display: flex;
            justify-content: center;
        }
    }

    .highlighted_post {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        @include responsive(50px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

        @include max($breakpoint-large) {
            flex-direction: column-reverse;
        }

        .wrapper_img {
            width: 100%;
            max-width: 540px;
            max-height: 350px;
            margin-right: 60px;
            flex-shrink: 0;
            overflow: hidden;
            cursor: pointer;
            position: relative;

            @include max($breakpoint-large) {
                margin-right: 0;
                max-width: 100%;
                height: auto;
            }

            a, button {
                display: block;
                height: 100%;
                position: relative;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            .player_video {
                @include player_video();
                @include min($breakpoint-medium) {
                    // SVG taille réelle ici
                    width: auto;
                    height: auto;
                }
            }
        }

        .wrapper_content {
            flex-grow: 1;
            padding-top: 25px;

            .badge {
                @include badge();
            }

            .list_categories {
                display: flex;
                margin-bottom: 20px;
                flex-wrap: wrap;

                .category {
                    color: $Rouge_Praxedo;
                    border: solid 1px $Rouge_Praxedo;
                    border-radius: 17px;
                    padding: 5px 10px;
                    font-size: 8px;
                    font-weight: 500;
                    letter-spacing: 0.07em;
                    text-transform: uppercase;
                    margin-bottom: 8px;

                    &:not(:last-child) {
                        margin-right: 8px;
                    }
                }
            }

            .titre {
                font-weight: 500;
                @include font-responsive(22px, 10, $breakpoint-mobile, $desktop_to_mobile);
                @include responsive(28px, 11, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                color: $DarkBlue_Text;
                margin-bottom: 20px;
            }

            a:hover {
                .titre {
                    color: $Red_Praxedo;
                }
            }

            .texte {
                @include font-responsive(16px, 2, $breakpoint-mobile, $desktop_to_mobile);
                @include responsive(22px, 3, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                font-weight: 300;
                padding-bottom: 25px;
                border-bottom: 1px solid rgba($mediumGrey_Praxedo, 0.5);
                margin-bottom: 20px;
            }

            .date {
                @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                line-height: 26px;
                font-weight: 300;
            }

            .logo-company {
                max-width: 140px; // purement arbitraire !

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .list_posts {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include max($breakpoint-medium) {
            flex-direction: column;
        }

        .post {
            width: calc(33.33333333333% - 40px);
            @include responsive(50px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
            display: flex;
            flex-direction: column-reverse;
            flex-wrap: wrap;
            align-items: stretch;

            @include max($breakpoint-medium) {
                width: 100%;
            }

            .wrapper_img {
                width: 100%;
                height: 220px;
                flex-shrink: 0;
                margin-bottom: 25px;
                cursor: pointer;
                position: relative;

                @include max($breakpoint-medium) {
                    height: auto;
                }

                @include between($breakpoint-medium, $breakpoint-large) {
                    height: 160px;
                }

                .wrapper-post-image {
                    height: 100%;
                }

                a, button {
                    display: block;
                    height: 100%;
                    position: relative;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;

                    @include min($breakpoint-medium) {
                        object-fit: cover;
                    }
                }

                .player_video {
                    @include player_video();
                }

                .video_embed {
                    display: none;
                }
            }

            .wrapper_content {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .badge {
                    @include badge();
                }

                .list_categories {
                    display: flex;
                    margin-bottom: 10px;
                    flex-wrap: wrap;

                    .category {
                        color: $Rouge_Praxedo;
                        border: solid 1px $Rouge_Praxedo;
                        border-radius: 17px;
                        padding: 5px 10px;
                        font-size: 8px;
                        font-weight: 500;
                        letter-spacing: 0.07em;
                        text-transform: uppercase;
                        margin-bottom: 8px;

                        &:not(:last-child) {
                            margin-right: 8px;
                        }
                    }
                }

                .wrapper_content_link {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                }

                .titre {
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 28px;
                    color: $DarkBlue_Text;
                    margin-bottom: 20px;
                    flex-grow: 1;
                }

                a:hover {
                    .titre {
                        color: $Red_Praxedo;
                    }
                }

                .date {
                    font-size: 14px;
                    line-height: 26px;
                    font-weight: 300;
                    padding-top: 15px;
                    border-top: 1px solid rgba($mediumGrey_Praxedo, 0.5);
                    color:$DarkGrey_Praxedo;
                }

                .logo-company {
                    max-width: 150px; // purement arbitraire !

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            &.empty_block {
                height: 0px;
                margin: 0;
            }
        }
    }

    .post-pagination {
        display: flex;
        justify-content: center;

        .prev,
        .next {
            svg {
                width: 12px;
                height: 12px;
            }
        }

        .prev {
            transform: rotate(-180deg);
        }

        .page-numbers {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            margin: 0 9px;
            @include font-responsive(16px, 2, $breakpoint-mobile, $desktop_to_mobile);
            font-weight: 300;

            svg, &.dots {
                pointer-events: none;
            }

            &.current {
                color: $Rouge_Praxedo;
                border: solid 1px $Rouge_Praxedo;
                border-radius: 50px;
                width: 32px;
                height: 32px;
            }
        }
    }



    &#archive-guide, &#archive-cas_usage {
        $box_shadow_image: (-7px -7px 25px 0px rgba(215, 225, 232, 0.75));

        .highlighted_post {
            .wrapper_img {
                display: flex;
                justify-content: center;
                background-color: $greyPush_Praxedo;
                position: relative;

                &::before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 35px;
                    background-color: white;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 35px;
                    background-color: white;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                }
                
                img {
                    position: relative;
                    z-index: 2;
                }

                a, button {
                    width: 300px;
                    height: 425px;
                    position: relative;
                    z-index: 2;
                    box-shadow: $box_shadow_image;
                }
            }

            .wrapper_content {
                .content_post {
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 1.4em;
                    padding-bottom: 25px;
                    margin-bottom: 28px;
                    border-bottom: solid 1px $greyPush_Praxedo;
                }
            }
        }

        .list_posts {
            .post {
                width: 300px;

                .wrapper_img {
                    line-height: 0;
                    height: 425px;
                    box-shadow: $box_shadow_image;
                }

                .badge {
                    margin-bottom: 19px;
                }

                .titre {
                    font-size: 20px;
                    line-height: 1.3em;
                    margin-bottom: 17px;
                }
            }
        }
    }

    &#archive-temoignage {
        .highlighted_post {
            .titre {
                padding-bottom: 35px;
                border-bottom: 1px solid rgba(197, 209, 217, 0.5);
                margin-bottom: 35px;
            }

            .wrapper_content {
                .logo-company {
                    background: #FFFFFF;
                    box-shadow: 0px 0px 20px rgba(78, 78, 78, 0.1);
                    border-radius: 10px;
                    object-fit: contain;
                    padding: 3px;
                }
            }
        }
        
        .wrapper_img {
            border-radius: 10px;

            .logo-company {
                max-width: 100px;
                width: 100px;
                height: 50px;
                border-radius: 10px;
                position: absolute;
                top: 20px;
                left: 20px;
                box-shadow: 0px 0px 20px rgba(78,78,78,0.19);

                img {
                    border-radius: 10px;
                    background: $white;
                    object-fit: contain;
                    padding: 3px;
                }
            }

            img {
                border-radius: 10px;
            }
        }

        .categories-select-form {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            @include max($breakpoint-large) {
                flex-direction: column;
            }
        }

        .filter_search_wrapper .filter_wrapper select {
            @include max($breakpoint-large) {
                font-size: 12px;
                width: 100% !important;
            }
        }
    }

    &#archive-blog {

        .highlighted_post .wrapper_content {
            .date-readingtime {
                display: flex;
                justify-content: space-between;
                color:$DarkGrey_Praxedo;
                @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                line-height: 26px;
                font-weight: 300;
            }

            .readingtime {
                .time {
                    font-weight: 600;
                }
            }
        }
        
        .list_posts .post .wrapper_content {

            .date-readingtime {
                display: flex;
                justify-content: space-between;
                padding-top: 15px;
                border-top: 1px solid rgba($mediumGrey_Praxedo, 0.5);
                color:$DarkGrey_Praxedo;
                font-size: 14px;
                line-height: 26px;
                font-weight: 300;
            }

            .date {
                border: none;
                padding-top: 0;
            }

            .readingtime {
                .time {
                    font-weight: 600;
                }
            }
        }
    }
    .inner_content_strate_archive_blog {
        &.bg_grey {
            background-color: $LightGrey_Praxedo_Bkg;
            margin-top: 140px;
            margin-bottom: 100px;

            .list_posts .post {
                margin-bottom: 0;
            }

            .inner_content_wrapper {
                padding: 110px 0;
            }
        }
    }
}

