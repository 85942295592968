.aside_single_post {
    position: absolute;
    top: 0;
    width: fit-content;
    height: 100%;

    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }

    &.aside_single_post_push_product {
        @include max(1400px) {
            position: relative;
            right: unset;
            width: 100%;
        }
    }

    &.aside_single_post_share_links {
        @include max(1040px) {
            position: relative;
            left: unset;
            width: 100%;
        }

        @include max(900px) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}

.wrapper_share_links {
    position: sticky;
    top: 100px;
    order: -1;
    flex-shrink: 0;
    width: 90px;
    height: fit-content;
    background: $white;
    box-shadow: 15px 9px 30px rgba(210, 219, 232, 0.2);
    border-radius: 10px;
    padding: 20px;

    .share_link {
        &:not(:last-child) {
            margin-bottom: 15px;
        }

        a, button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52px;
            height: 52px;
            border: 1px solid rgba($mediumGrey_Praxedo, 0.5);
            border-radius: 7.5px;
        }
    }

    @include max(1040px) {
        display: flex;
        position: relative;
        top: 0;
        width: 100%;
        max-width: 855px;
        margin: 0 auto 70px;
        justify-content: center;

        .share_link {
            &:not(:last-child) {
                margin-right: 15px;
                margin-bottom: 0;
            }
        }
    }
}

.wrapper_push_product {
    position: sticky;
    top: 100px;
    flex-shrink: 0;
    width: 210px;
    height: fit-content;
    background: $greyPush_Praxedo;
    border-radius: 10px;
    margin-right: 45px;
    padding: 25px 0;
    text-align: center;

    @include max(1400px) {
        margin: 0 auto;
        position: relative;
        top: unset;
    }

    .titre {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        padding: 0 20px;
        margin-bottom: 20px;
    }

    .cta {
        width: 100%;
        justify-content: center;
        max-width: max-content;
    }

    .image {
        margin-top: 30px;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
