$breakpoint-main-header: $breakpoint-large;

:root {
    @include root-prop(--breakpoint-main-header, $breakpoint-main-header);
}

/*
Le menu principal est devenu sticky :
on compense sa hauteur aavec un margin-top équivalent pour #main_content
*/
#main_content {
    margin-top: 60px;

    @include min($breakpoint-main-header) {
        margin-top: 85px;
    }
}


.main-header {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    border-bottom: solid 1px transparent;
    display: flex;
    height: 60px;
    transition: background-color .4s;

    &.underlined {
        border-bottom: solid 1px rgba(0, 0, 0, .05);
    }

    @include min($breakpoint-main-header) {
        height: 85px;
        border-bottom: solid 1px transparent;

        &.underlined {
            border-bottom: solid 1px rgba(0, 0, 0, .05);
        }
    }

    &.main-header--bleu:not(.open) {
        background-color: $bleuFonceFondHeader;
    }

    &.main-header--gris:not(.open) {
        background-color: $grisClairFondHeader;
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}

.main-header-left {
    position: static;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.main-header__logo {
    display: flex;
    align-items: center;
    width: 85px;

    @include min($breakpoint-main-header) {
        width: 146px;
    }

    svg {
        height: auto;
        width: 100%;

        .main-header--bleu:not(.open) & path {
            fill: $white;
        }
    }
}

.main-header-center {
    position: absolute;
    top: 100%;
    left: -100%;
    right: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 0 40px;
    height: calc(100vh - 60px); // 60px = height header
    overflow: auto;
    overflow-x: hidden;
    transform: translateX(0);
    transition: .4s linear;
    visibility: hidden;
    pointer-events: none;

    @media screen and (max-width: 782px) {
        .admin-bar & {
            height: calc(100vh - (60px + 46px));
        }
    }

    @include min($breakpoint-main-header) {
        position: static;
        flex-direction: row;
        flex-grow: 1;
        background-color: transparent;
        padding: 0;
        height: unset;
        text-align: center;
        overflow: unset;
        transform: none;
        visibility: visible;
        pointer-events: initial;
    }

    .main-header.open & {
        transform: translateX(100%);
        visibility: visible;
        pointer-events: initial;
        z-index: 999;
    }
}

.main-header-nav {
    font-size: 18px;

    @include min($breakpoint-main-header) {
        flex-grow: 1;
        margin: 0 30px;
        font-size: 15px;
    }

    .menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        @include min($breakpoint-main-header) {
            flex-direction: row;
        }

        .menu-item.open > .sub-menu {
            transform: translateX(100%);
        }

        .menu-item a, .menu-item button {
            transition: color .2s;

            &:hover, &:focus {
                color: $Rouge_Praxedo;
            }

            &.link-red {
                color: $Rouge_Praxedo;

                &:hover, &:focus {
                    svg {
                        margin-left: 15px;
                    }
                }

                svg {
                    margin-left: 10px;
                    transition: .2s;
                }
            }

            &.content-link {
                display: inline-flex;
                align-items: center;
                margin-top: 15px;
            }
        }

        & > .menu-item { // Menu root
            width: 100%;
            transition: .2s;

            @include min($breakpoint-main-header) {
                width: auto;
            }

            & > a, & > button {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 25px 0;
                border-bottom: 1px solid transparentize($mediumGrey_Praxedo, .5);

                @include min($breakpoint-main-header) {
                    display: block;
                    padding: 10px 20px;
                    border-bottom: 0;
                }

                svg {
                    path {
                        stroke: currentColor;
                    }

                    @include min($breakpoint-main-header) {
                        display: none;
                    }
                }

                .main-header--bleu:not(.open) & {
                    @include min($breakpoint-main-header) {
                        color: $white;
                    }
                }
            }

            @media screen and (hover: hover) {
                &.current-menu-item, &:hover {
                    & > a, & > button {
                        color: $Rouge_Praxedo;
                    }
                }
            }

            @include max($breakpoint-main-header - 1px) {
                [data-depth='0']:not(.menu-item-column-title--not-link),
                [data-depth='1']:not(.menu-item-column-title--not-link) {
                    & + .sub-menu {
                        position: absolute;
                        top: 0;
                        left: -100%;
                        right: 100%;
                        bottom: 0;
                        padding: 0 20px 40px;
                        background-color: $white;
                        transition: .4s linear;
                        overflow: auto;
                        overflow-x: hidden;
                        z-index: 2;
                    }
                }
            }

            .sub-menu { // Tous les niveaux
                @include min($breakpoint-main-header) {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    padding: 0;
                }

                .menu-item {
                    @include min($breakpoint-main-header) {
                        margin: 0 22.5px;
                        width: 100%;
                    }

                    & > a, & > button {
                        display: flex;
                        align-items: center;
                        padding: 10px 20px;
                        font-size: 15px;

                        @include min($breakpoint-main-header) {
                            padding: 10px 0;
                        }

                        &.menu-item-column-title {
                            font-weight: 300;
                            padding-top: 7px;
                            padding-bottom: 6px;

                            @include max($breakpoint-main-header) {
                                font-weight: 500;
                                padding-top: 15px;
                                padding-bottom: 15px;
                            }

                            &.menu-item-column-title--not-link {
                                font-size: 18px;
                                color: $Rouge_Praxedo;
                                pointer-events: none;
                                @include min($breakpoint-main-header) {
                                    margin-top: 10px;
                                }

                                &+.sub-menu {
                                    .menu-item-parent-title {
                                        display: none;
                                    }
                                }
                            }
                        }

                        img {
                            margin-right: 10px;
                            max-width: 20px;
                            max-height: 22px;
                        }

                        .arrow {
                            flex-grow: 1;
                            text-align: right;
                        }
                    }

                    & > button {
                        -webkit-appearance: none;
                        appearance: none;
                        background: transparent;
                        border: none;
                        width: 100%;
                        text-align: left;
                    }

                    & > .menu-item-content {
                        display: none;

                        @include min($breakpoint-main-header) {
                            display: block;
                        }
                    }

                    &.black {
                        & > a {
                            &.menu-item-column-title {
                                &.menu-item-column-title--not-link {
                                    color:$DarkBlue_Text;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }

                .menu-item-column {
                    @include min($breakpoint-main-header) {
                        flex-grow: 1;
                        margin: 0 22.5px;

                        &.menu-item-column--1 {
                            width: calc(100% - 45px);
                        }

                        &.menu-item-column--2 {
                            width: calc((100% / 2) - 45px);
                        }

                        &.menu-item-column--3 {
                            width: calc((100% / 3) - 45px);
                        }
                        &.menu-item-column--4 {
                            // width: calc((100% / 4) - 45px);
                            width: auto;
                        }
                    }

                    .menu-item-column-title {
                        @include min($breakpoint-main-header) {
                            font-size: 16px;
                            font-weight: 500;
                        }

                        svg {
                            path {
                                stroke: currentColor;
                            }

                            @include min($breakpoint-main-header) {
                                display: none;
                            }
                        }
                    }
                }

                .menu-item-parent-title {
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    font-weight: 500;
                    border-bottom: 1px solid transparentize($mediumGrey_Praxedo, .5);
                    padding: 20px;
                    margin-bottom: 15px;

                    @include min($breakpoint-main-header) {
                        display: none;
                    }

                    img {
                        margin-right: 10px;
                    }
                }

                .menu-item-single-post {
                    &:not(:last-child) {
                        @include min($breakpoint-main-header) {
                            border-bottom: 1px solid transparentize($mediumGrey_Praxedo, .5);
                            margin-bottom: 10px;
                            padding-bottom: 10px;
                        }
                    }

                    & > a {
                        display: block;
                    }

                    .title {
                        display: block;
                    }

                    .date {
                        display: none;

                        @include min($breakpoint-main-header) {
                            display: block;
                            margin-top: 15px;
                            font-size: 11px;
                            color: $Gris_Praxedo;
                        }
                    }
                }

                .menu-item-separator {
                    display: none;

                    @include min($breakpoint-main-header) {
                        display: block;
                        width: 100%;
                        margin: 6px 22.5px;
                    }

                    hr {
                        border: 0;
                        border-bottom: 1px solid transparentize($mediumGrey_Praxedo, .5);
                    }
                }
            }

            &.menu-item-width--large > .sub-menu {
                @include min($breakpoint-main-header) {
                    width: 945px;
                }
            }

            & > .sub-menu { // Premier niveau
                @include min($breakpoint-main-header) {
                    position: absolute;
                    top: 100px;
                    left: 50%;
                    right: unset;
                    bottom: unset;
                    border-radius: 10px;
                    width: 660px;
                    max-width: calc(100vw - 100px);
                    // padding: 50px calc(50px - 22.5px);
                    padding: 28px 50px;
                    background-color: $white;
                    transition: .2s;
                    visibility: hidden;
                    transform: translateX(-50%) rotateX(40deg);
                    transform-origin: 50% -50px;
                    opacity: 0;
                    pointer-events: none;
                    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

                    &::before { // Div transparente entre le menu parent et le sub-menu pour garder le hover
                        position: absolute;
                        top: -40px;
                        left: 0;
                        content: '';
                        height: 47px;
                        width: 100%;
                    }

                    &> .menu-item {
                        text-align: left;
                        opacity: 0;
                        transition: .2s;
                        transition-delay: 0.2s;
                        transform: translateX(20px);
                    }

                    .sub-menu {
                        position: static;
                        background-color: unset;
                        margin: 0 -22.5px;
                    }

                }

                & > li.menu-item-column > a.menu-item-column-title {
                    font-weight: 500;
                }
            }

            @include min($breakpoint-main-header) {
                &:hover > .sub-menu { // Premier niveau au hover de root
                    visibility: visible;
                    opacity: 1;
                    pointer-events: initial;
                    transform: translateX(-50%) rotateX(0deg);

                    &> .menu-item {
                        opacity: 1;
                        transform: translateX(0px);
                    }
                }
            }
        }
    }
}

.main-header-nav-right {
    margin-top: 25px;

    @include min($breakpoint-main-header) {
        margin-top: 0;
    }

    .menu {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include min($breakpoint-main-header) {
            flex-direction: row;
        }

        & > .menu-item {
            width: 100%;

            .cta {
                width: 100%;
                justify-content: center;

                @include min($breakpoint-main-header) {
                    width: auto;
                    justify-content: initial;
                }
            }

            @include min($breakpoint-main-header) {
                width: auto;
            }
        }
    }

    .cta {
        @include min($breakpoint-main-header) {
            color: $white;
        }
    }

    .menu-item:not(:last-child) {
        margin-bottom: 20px;

        @include min($breakpoint-main-header) {
            margin-right: 10px;
            margin-bottom: 0;
        }
    }

    .main-header:not(.main-header--bleu) & .cta_fond_blanc_bleu {
        @extend .cta_fond_blanc;
    }
}

.main-header-toggler {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: $white;

    @include min($breakpoint-main-header) {
        display: none;
    }

    .main-header-toggler__button {
        display: flex;
        align-items: center;
        background-color: transparent;
        height: 100%;
        border: 0;
        padding: 20px;
        font-size: 16px;
        color: $Red_Praxedo;


        .main-header--bleu:not(.open) & {
            color: $white;
            background-color: $bleuFonceFondHeader;
            transition: background-color 0.4s;
        }

        .main-header--gris:not(.open) & {
            // color: $white;
            background-color: $grisClairFondHeader;
            transition: background-color 0.4s;
        }

        .burger {
            display: block;
            position: relative;
            height: 10px;
            width: 20px;
            margin-right: 10px;

            &::before, &::after {
                content: '';
                position: absolute;
                top: calc(50% - (3px / 2));
                left: 0;
                right: 0;
                background-color: $Rouge_Praxedo;
                width: 100%;
                height: 3px;
                border-radius: 3px;
                transform-origin: center;
                transition: transform .2s;

                .main-header--bleu:not(.open) & {
                    background-color: $white;
                }
            }

            &::before {
                margin-top: -3px;
            }

            &::after {
                margin-top: 3px;
            }
        }

        .main-header.open & .burger {
            &::before, &::after {
                margin-top: 0;
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }
    }
}

.main-header-phone-nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;

    @include min($breakpoint-main-header) {
        display: none;
    }

    .main-header-phone-nav-container {
        position: absolute;
        top: 0;
        left: -100%;
        display: flex;
        align-items: center;
        height: 100%;
        width: 70%;
        background-color: $white;
        visibility: hidden;
        transition: .4s linear;
        pointer-events: initial;

        &.show {
            left: 0;
            visibility: visible;
        }
    }

    button {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: 0;
        padding: 20px;
        font-size: 16px;
        font-weight: 500;
        transition: color .2s;

        &:hover, &:focus {
            color: $Rouge_Praxedo;
        }
    }

    svg {
        margin-right: 10px;

        path {
            stroke: currentColor;
        }
    }
}

.main-header-sticker {
    position: relative;
    top: -4px;

    @include max($breakpoint-main-header) {
        display: none;
    }
    
    a.cta , button.cta {
        @include max($breakpoint-main-header) {
            transform-origin: 100% 50%;
            transform: scale(0.8);
            top: -2px;
        }
    }
}
