$largeur_col_left_faq: 363px;
$decalage_image: 35.81267217630854%;
$gouttiere_faq: 5%;

.strate-push-faq {

    .inner_content_strate_push_faq {
        background-color: $greyPush_Praxedo;
        min-height: 695px;
        // @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(70px, 30, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

        @include max($breakpoint-strate-medium) {
            height: auto;
        }

        .wrapper {
            height: 100%;
        }
    }

    .inner_content_wrapper {
        display: flex;
        justify-content: space-between;
        height: 100%;
        @include responsive(50px, 50, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');

        @include max($breakpoint-strate-medium) {
            flex-direction: column-reverse;
        }

        .col_left {
            max-width: 350px;
            position: relative;
            width: 100%;
            margin-right: 30px;

            @include max($breakpoint-strate-medium) {
                max-width: 100%;
            }

            .wrapper_image {
                width: 100%;

                @include responsive(330px, 307, $breakpoint-mobile, $desktop_to_mobile, 'height');

                @include max($breakpoint-strate-medium) {
                    text-align: center;
                    width: auto;
                }

                img {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    @include responsive(-50px, -70 , 1140px, 210, 'left'); // 1350px -> 1140px 
                    @include max($breakpoint-strate-medium) {
                        position: unset;
                        height: 100%;
                        width: auto;
                    }
                }
            }
        }

        .col_right {

            width: 100%;
            max-width: 720px;
            @include responsive(680px, 40 , 1140px, 210, 'max-width'); // 1350px -> 1140px 

            @include max($breakpoint-strate-medium) {
                max-width: 100%;
            }

            .titre {
                @include titre-strate();
                @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile);
            }

            .wrapper_faqs {
                @include responsive(40px, 17, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');

                // accordion
                .faq {
                    border-bottom: 1px solid transparentize($mediumGrey_Praxedo, 0.5);
                    transition: $transition_standard;
                    position: relative;
                    cursor: pointer;

                    &:last-child {
                        border-bottom: 0px;
                    }

                    .titre_faq {
                        display: flex;
                        justify-content: space-between;
                        padding: 30px 0;

                        .text_titre_faq {
                            margin-right: 20px;
                            @include font-responsive(18px, 4, $breakpoint-mobile, $desktop_to_mobile);
                            transition: $transition_standard;
                        }

                        &::after {
                            content: url($pathToSVG + 'ico-plus.svg');
                            width: 10px;
                            height: 10px;
                            position: absolute;
                            right: 0;
                            top: 30px;
                            display: block;
                        }

                        &.active {
                            &::after {
                                content: url($pathToSVG + 'ico-moins.svg');
                                margin-top: 0;
                                top: 25px;
                            }

                            .text_titre_faq {
                                color: $Red_Praxedo;
                            }
                        }
                    }

                    .content_faq {
                        overflow: hidden;
                        margin: 0;
                        max-height: 0;
                        transition: $transition_standard;
                        @include font-responsive(18px, 0, $breakpoint-mobile, $desktop_to_mobile);

                        p {
                            margin-bottom: 30px;
                        }
                    }


                    &:hover {
                        .titre_faq {
                            .text_titre_faq {
                                color: $Red_Praxedo;
                            }
                        }
                    }

                }
            }
        }
    }
}
