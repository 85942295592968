/*
Extension de _header-carriere.scss
Et aussi quelques overrules
*/

/*
BP : $breakpoint-large: 992px;
sauf pour .row.partageable-notes-etoiles qui passe à 100% en mobile -> $breakpoint-mobile: 480px;
*/

.header-carriere-image-full-screen {
    .inner_content_header_carriere_image_full_screen {
        position: relative;

        >.bg_image {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;

            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 12.57%, rgba(0, 0, 0, 0) 81.94%);

                @include max($breakpoint-large){
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 37%, rgba(0, 0, 0, 0) 90.93%);
                }
            }
    
            img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }
    
        >.wrapper {
            position: relative;
            z-index: 2;

            .inner_content_wrapper {
                justify-content: space-between;
                padding-top: 90px;
                padding-bottom: 56px;

                @include max($breakpoint-large){
                    padding-top: 37px;
                    padding-bottom: 32px;
                }
            }
        }

        .wrapper_cols {
            color: white;

            @include max($breakpoint-large){
                flex-direction: column;
                align-items: center;
            }

            .wrapper_breadcrumb {
                @include max($breakpoint-large){
                    text-align: center;
                }

                >span>span .breadcrumb_last {
                    opacity: .6;
                }
            }

            .col_left {
                width: 85%;

                .titre {
                    padding-bottom: 35px;

                    @include max($breakpoint-large){
                        padding-bottom: 23px;
                    }
                }

                .sous_titre {
                    @include max($breakpoint-large){
                        margin-bottom: 27px;
                    }
                }

                .wrapper_cta {
                    @include max($breakpoint-large){
                        text-align: center;
                    }
                }
            }

            .col_right {
                width: 0%;
            }
        }

        .wrapper_avis .partageable-notes-etoiles {
            @include max($breakpoint-large){
                margin-top: 150px;
            }

            .col {
                background-color: white;

                @include max($breakpoint-mobile){
                    width: 100%;
                    max-width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}
