$breakpoint_cf: 768px;

.strate-comparaison-formules {

    .titre {
        @include titre-strate();
        margin: 0 auto;
        @include responsive(45px, 35, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        max-width: 890px;
        text-align: center;
    }

    .inner_content_wrapper {
        overflow: hidden;
    }

    .wrapper_domaines {
        $largeur_col_formule: 138px;
        $largeur_col_formule_mobile: 110px;
        padding-top: 40px;

        .domaine {
            position: relative;

            &:not(:first-child) {
                @include responsive(50px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
            }

            .ai-generated-wrapper {
                position: absolute;
                right: 0;
                top: -49px;
                display: none;

                @include max($breakpoint-medium) {
                    display: none;
                }

                td {
                    width: 138px;

                    .ai-generated {
                        display: flex;
                        height: 49px;
                        background: #FFD2D5;
                        border-radius: 6px;
                        margin: 0 7px;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 500;
                        color: $DarkBlue_Text;

                        svg {
                            margin-right: 10px;
                        }
                    }
                }
            }

            &:first-child  .ai-generated-wrapper {
                display: block;

                @include max($breakpoint-medium) {
                    display: none;
                }
            }
        }

        .lignes {
            display: flex;
            justify-content: space-between;
            position: relative;
            // border-bottom: solid 1px rgba(0, 0, 0, .2);

            .col {
                display: flex;
                align-items: center;
                min-height: 52px;
            }

            .col_libelle {
                width: calc(100% - ( 3 * #{$largeur_col_formule}));
                &[data-nb-cols="2"] {
                    width: calc(100% - ( 2 * #{$largeur_col_formule}));
                    @media screen and (max-width: $breakpoint_cf) {
                        width: calc(100% - #{$largeur_col_formule_mobile});
                    }
                }
                &[data-nb-cols="3"] {
                    width: calc(100% - ( 3 * #{$largeur_col_formule}));
                    @media screen and (max-width: $breakpoint_cf) {
                        width: calc(100% - #{$largeur_col_formule_mobile});
                    }
                }
                &[data-nb-cols="4"] {
                    width: calc(100% - ( 4 * #{$largeur_col_formule}));
                    @media screen and (max-width: $breakpoint_cf) {
                        width: calc(100% - #{$largeur_col_formule_mobile});
                    }   
                }
                @media screen and (max-width: $breakpoint_cf) {
                    width: calc(100% - #{$largeur_col_formule_mobile});
                }
            }

            .col_formule {
                width: $largeur_col_formule;
                justify-content: center;
                
                @media screen and (max-width: $breakpoint_cf) {
                    width: $largeur_col_formule_mobile;
                }

                &.col_select {
                    display: none;

                    .wrapper_select {
                        display: flex;
                        align-items: center;
                        border: 1px solid rgba($mediumGrey_Praxedo, 0.5);
                        padding: 13px 8px;
                        width: 100%;
                        justify-content: space-between;
                        background: $white;

                        select {
                            -webkit-appearance: none;
                            appearance: none;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 0.07em;
                            text-transform: uppercase;
                            border: 0;
                            background: $white;
                            width: 100%;
                        }

                        .icon {
                            display: flex;
                            align-items: center;
                            margin-left: 8px;

                            svg {
                                path {
                                    stroke: $Rouge_Praxedo;
                                }
                            }
                        }

                        .ai-enabled {
                            position: absolute;
                            right: 30px;
                            pointer-events: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 115px;
                            height: 25px;
                            border-radius: 5px;
                            background: #FFD2D5;
                            font-size: 12px;
                            padding: 0 12px;
                            text-transform: none;

                            svg {
                                width: 18px;
                                margin-right: 6px;
                            }
                        }
                    }
                }

                @media screen and (max-width: $breakpoint_cf) {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    opacity: 0;
                    top: 0;

                    &.active, &.col_select {
                        opacity: 1;
                    }

                    &.col_select {
                        display: flex;
                        top: 50px;
                        width: 100%;
                    }
                }
            }

            &.header_domaine {

                @media screen and (max-width: $breakpoint_cf) {
                    margin-bottom: 15px;
                }

                .col_libelle {
                    @include font-responsive(18px, 2, $breakpoint-mobile, $desktop_to_mobile);
                    color: $Rouge_Praxedo;
                    font-weight: 500;

                    @media screen and (max-width: $breakpoint_cf) {
                        width: 100%;
                        justify-content: center;
                        margin-bottom: 54px;
                        padding-bottom: 36px;
                        text-align: center;
                    }
                }

                .col_formule {
                    @include font-responsive(12px, 2, $breakpoint-mobile, $desktop_to_mobile);
                    text-transform: uppercase;
                    font-weight: 500;
                    letter-spacing: 0.07em;
                }
            }

            &.content_domaine {
                @include font-responsive(12px, 2, $breakpoint-mobile, $desktop_to_mobile);
                @include responsive(20px, 3, $breakpoint-mobile, $desktop_to_mobile, 'line-height');

                .col_libelle {
                    @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                    font-weight: 500;
                    padding: 5px 0;
                }

                .col {
                    border-top: solid 1px rgba(0, 0, 0, .2);
                }
                
                &.total_tarif {
                    position: relative;
                    @include font-responsive(18px, 2, $breakpoint-mobile, $desktop_to_mobile);
                    font-weight: 500;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        top: 20px;
                        border-radius: 6px;
                        background: $greyPush_Praxedo;
                        z-index: -1;

                        @include max($breakpoint-medium) {
                            bottom: -12px;
                            top: 11px;
                        }
                    }

                    .col {
                        padding: 35px 0 15px;

                        @include max($breakpoint-medium) {
                            padding: 27px 0 15px;
                        }
                    }
                }
            }
        }

        .wrapper_ctas {
            .domaine {
                @include responsive(30px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
                .cta {
                    padding: 22px 25px;
                    height: auto;
                    text-align: center;
                    .texte_cta {
                        @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                    }
                }
                @media screen and (max-width: $breakpoint_cf) {
                    .col_libelle {
                        display: none;
                    }

                    .col_formule.active {
                        width: 100%;
                    }
                }

            }
        }
    }

}

