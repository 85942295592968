/*
Concernant la structure des Blocks ACF/Gutenberg, voici les 3 "conteneurs" obligatoires :

1/  Conteneur principal
    Exemple : <section class="{nom du block tel qu'il est défini dans acf_blocks.php}"></section>
    Jamais de CSS sur cette balise
    Exception : <header class="idem"></header> pour les headers... CQFD

2/  Conteneur secondaire
    Exemple : <div class="inner_content_{reprise de la classe du conteneur principal}"></div>
    CCS uniquement pour margin-top et margin-bottom et background-color

3/  Conteneur dont l'obectif principal est de définir la largeur max du contenu
    Exemple : <div class="wrapper wrapper_header_main">
    Toujours 2 classes : wrapper + wrapper_{dénomination spécifique}
    Voir mixin wrapper()
*/


.wrapper {
    &.wrapper_nav {
        @include wrapper($breakpoint-nav);
    }

    &.wrapper_header_main {
        @include wrapper($breakpoint-header-main);
    }

    &.wrapper_strate_small {
        @include wrapper($breakpoint-strate-small);
    }

    &.wrapper_strate_medium {
        @include wrapper($breakpoint-strate-medium);
    }

    &.wrapper_strate_large {
        @include wrapper($breakpoint-strate-large);
    }

    &.wrapper_strate_extra_large {
        @include wrapper($breakpoint-nav);
    }
}
