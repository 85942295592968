/*
BP : $breakpoint-large
*/
.strate-push-simple-page {
    .inner_content_strate_push_simple_page {
        margin-top: 70px;

        @include max($breakpoint-large){
            display: flex;
            justify-content: center;
            margin-top: 40px;
        }
    }

    .wrapper.wrapper_strate_large {
        @include max($breakpoint-large){
            max-width: 540px;
        }
    }

    .inner_content_wrapper {
        border-radius: 20px;
        background-color: white;
        padding: 0 70px;
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        overflow: hidden;

        @include max($breakpoint-large){
            padding: 27px 20px 46px;
            height: auto;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 385px;
            height: 100%;
            background-image: url($pathToSVG + 'bg_push_simple_page.svg');
            background-position: left top;
            background-repeat: no-repeat;

            @include max($breakpoint-large){
                width: 215px;
                background-image: url($pathToSVG + 'bg_push_simple_page_mobile.svg');
                background-position: left bottom;
            }
        }

        .titre, .wrapper_cta {
            position: relative;
            z-index: 2;
        }

        .titre {
            max-width: 720px;
            margin-bottom: 15px;
        }
    }
}

// bg_push_simple_page
