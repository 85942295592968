$bp_max_fr_ssgi: 780; // calc(1260 - 480);

.strate-solution-gestion-interventions {
    // wrapper_strate_large : 1260px

    .inner_content_strate_solution_gestion_interventions {
        //margin-top:130px;
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        //margin-bottom:130px;
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        overflow-x: hidden;
    }

    .strate_top {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .titre {
        @include titre-strate();
        @include font-responsive(30px, 20, $breakpoint-mobile, $bp_max_fr_ssgi); // 30xp - 30px - 480px - 1260px
        text-align: center;
        max-width: 850px;
    }

    .wrapper_lien_top {
        a, button {
            text-decoration: none;
            color: $Red_Praxedo;
            display: inline-flex;
            //justify-content: flex-start;
            //align-items: flex-end;
            margin-top: 20px;

            .text_lien {
                margin-right: 7px;
            }

        }
    }

    .strate_middle {
        @include responsive(50px, 40, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');

        .wrapper_chapitre {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include responsive(50px, 40, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');

            @include max($breakpoint-strate-small) {
                flex-direction: column-reverse;
            }

            &.image_droite {
                flex-direction: row-reverse;

                @include max($breakpoint-strate-small) {
                    flex-direction: column-reverse;
                }

                .col_chapitre_image {
                    right: -50px;
                    @include max($breakpoint-strate-small) {
                        right: 0;
                    }
                }


                &.wide {
                    .col_chapitre_image {
                        position: relative;

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 110px;
                            width: 50vw;
                            height: 100%;
                            background-color: $greyPush_Praxedo;

                            @include max($breakpoint-strate-small) {
                                left: -20px;
                                width: 100vw;
                            }
                        }
                    }
                }


            }

            &:first-child {
                margin-top: 0;
            }

            &.image_gauche {

                .col_chapitre_image {
                    left: -50px;
                    @include max($breakpoint-strate-small) {
                        left: 0;
                    }
                }
            }
        }

        .image_gauche {

            &.wide {
                .col_chapitre_image {
                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        // left: 110px;
                        width: 50vw;
                        height: 100%;
                        background-color: $greyPush_Praxedo;
                        right: 110px;
                        left: unset;

                        @include max($breakpoint-strate-small) {
                            right: -20px;
                            width: 100vw;
                        }
                    }
                }
            }
        }

        .col_chapitre {
            width: 100%;
            // max-width: 540px;
            @include max($breakpoint-strate-small) {
                max-width: 100%;
            }
        }

        .col_chapitre_image {
            max-width: 640px;
            position: relative;

            @include max($breakpoint-strate-small) {
                margin-top: 20px;
            }

            .wrapper_image_chapitre {
                width: 100%;
                position: relative;
                z-index: 2;
                // margin-top: 22px;
                @include max($breakpoint-strate-small) {
                    margin-top: 0;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }




        }

        .col_chapitre_textes {
            max-width: 540px;

            .titre_chapitre {
                font-weight: 500;

                @include font-responsive(20px, 15, $breakpoint-mobile, $desktop_to_mobile);

                .single-connector & {
                    @include font-responsive(15px, 5, $breakpoint-mobile, $desktop_to_mobile);
                    @include responsive(20px, 8, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                }
            }

            p a:not(.cta) {
                text-decoration: underline;
            }

            .texte_chapitre, .texte_chapitre  p {
                @include responsive(15px, 6, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
                @include responsive(30px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                @include font-responsive(14px, 4, $breakpoint-mobile, $desktop_to_mobile);
                @include responsive(20px, 5, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                font-weight: 300;
                
                >.inner_content_texte_chapitre {
                    display: none;

                    &.show {
                        display: block;
                    }

                    &.excerpt {
                        > p:last-of-type {
                            margin-bottom: unset;
                        }
                    }

                }

                a.show-excerpt .picto_cta, button.show-excerpt .picto_cta {
                    transform: rotate(180deg);
                }
            }

            .wrapper_liens_chapitre {
                .wrapper_lien {
                    border-bottom: 1px solid #d7e1e8;

                    &:last-child {
                        border-bottom: 0px;
                    }

                    a, button {
                        display: flex;
                        justify-content: flex-start;
                        padding: 20px 0;
                        position: relative;
                        text-decoration: none;
                        color: $DarkBlue_Text;
                        align-items: center;
                        width: 100%;

                        &:hover {
                            .picto_lien_chapitre {

                                svg {
                                    path {
                                        fill: $Red_Praxedo;
                                    }
                                }
                            }

                            .texte_lien_chapitre {
                                color: $Red_Praxedo;
                            }

                            .picto_arrow_mini {
                                right: 0;
                            }
                        }

                        .picto_lien_chapitre {
                            @include responsive(20px, 15, $breakpoint-mobile, $desktop_to_mobile, 'margin-right');
                            max-width: 35px;
                            width: 100%;
                            text-align: center;

                            svg {
                                display: flex;
                                margin: auto;

                                path {
                                    transition: $transition_standard;
                                }

                                g {
                                    mask: none;
                                }
                            }
                        }

                        .texte_lien_chapitre {
                            margin-right: 25px;
                            transition: $transition_standard;
                            text-align: left;
                        }

                        .picto_arrow_mini {
                            position: absolute;
                            right: 1%;
                            top: 50%;
                            transform: translateY(-50%);
                            transition: $transition_standard;
                            display: flex;

                            @include max($breakpoint-strate-small) {
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .strate_bottom {
        text-align: center;
        @include responsive(20px, 30, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');

        a, button {
            @include responsive(40px , 10 , $breakpoint-mobile, $desktop_to_mobile, 'height');
            padding: 0 35px;
            @include max($breakpoint-strate-small) {
                width: 100%;
                max-width: 300px;
            }

            .texte_cta {
                margin: auto;
            }
        }
    }
}

