.strate-2-cols-textes-cta {

    .inner_content_strate_2_cols_textes-cta {
        @include responsive(70px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(70px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
    }

    // largeur strate = 1140

    .titre {
        @include titre-strate();
        @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile);
        text-align: center;
        margin-bottom: 40px;
    }

    .wrapper_blocks {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include max($breakpoint-strate-small) {
            flex-direction: column;
        }

        $gouttiere_2ctstap: 40px;

        .block {
            width: calc(50% - (#{$gouttiere_2ctstap} / 2));
            max-width: 540px;
            display: flex;
            margin-top: 45px;
            border-bottom: 1px solid transparentize($color: $mediumGrey_Praxedo, $amount: 0.3);
            @include responsive(20px, 20, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');

            &:nth-last-child(-n+2) {
                border-bottom: 0px;
                @include max($breakpoint-strate-small) {
                    border-bottom: 1px solid transparentize($color: $mediumGrey_Praxedo, $amount: 0.3);
                }
            }

            &:last-child {
                @include max($breakpoint-strate-small) {
                    border-bottom: 0px;
                }
            }

            @include max($breakpoint-medium) {
                width: 100%;
            }

            @include max($breakpoint-strate-small) {
                max-width: 100%;
                width: 100%;
                margin-top: 20px;
            }

            .picto {
                display: flex;
                width: 50px;
                flex-shrink: 0;

                svg {
                    width: 35px;
                    height: 35px;
                }

            }

            .inner_content_block {

                .titre_block {
                    @include font-responsive(18px, 4, $breakpoint-mobile, $desktop_to_mobile);
                    line-height: 1.27;
                    margin-bottom: 10px;
                }

                .texte_block {
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 1.43;

                    strong {
                        font-weight: 500;
                    }
                }

                .cta_savoir_plus {
                    margin-top: 10px;
                }
            }
        }
    }

    .wrapper_cta {
        text-align: center;
        margin-top: 30px;
    }
}
