$bp_max_fr_sf: 780; // calc(1260 - 480);

.strate-fonctionnalites, .strate-addons {
    // wrapper_strate_large : 1260px

    .inner_content_strate_fonctionnalites {
        // background-color: $grisClairFondHeader;

        // .inner_content_wrapper {
        //     @include responsive(40px, 20, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
        //     @include responsive(130px, 220, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
        // }
        @include responsive(70px, 30, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

        .titre {
            @include titre-header();
            @include font-responsive(46px, 4, $breakpoint-mobile, $desktop_to_mobile);
            @include responsive(46px, 11, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
            font-weight: 700;
            @include responsive(20px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');

        }

        .fonctionnalites_liste {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;


            @include max($breakpoint-strate-large) {
                justify-content: space-around;
            }


            .secteur {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 400px;
                min-height: 430px;
                background-color: $white;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
                border-radius: 10px;
                overflow: hidden;
                position: relative;
                padding: 37px 37px 60px;
                @include responsive(30px, 40, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
                @include responsive(30px, 40, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

                @include max($breakpoint-large) {
                    width: calc(50% - (14px * 2));
                }

                @include max($breakpoint-medium) {
                    width: 100%;
                }

                &.fake {
                    min-height: 0;
                    height: 0;
                    opacity: 0;
                    padding: 0;
                    margin: 0;
                    overflow: hidden;
                }

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 5px;
                    background-color: $Red_Praxedo;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                .wrapper_svg {
                    height: 45px; // pour forcer l'alignement vertical quel que soit la hauteur du svg (max 40)
                    @include responsive(40px, 15, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                    width: 45px;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                .titre_block {
                    @include font-responsive(20px, 5, $breakpoint-mobile, $desktop_to_mobile);
                    @include responsive(28px, -10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                    line-height: 32px;
                }

                .texte_block {
                    font-weight: 300;
                    @include font-responsive(14px, 6, $breakpoint-mobile, $desktop_to_mobile);
                    @include responsive(20px, 8, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                    // margin-bottom: 65px;
                    // @include responsive(40px, 25, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                }
            }
        }


        .wrapper_cta {
            text-align: center;
        }
    }
}
