.header-blog {
    @include responsive(40px, 30, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
    @include responsive(70px, 30, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

    .inner_content_wrapper {
        display: flex;
        justify-content: space-between;

        @include max($breakpoint-large) {
            flex-direction: column;
        }

        .wrapper_img {
            width: 100%;
            max-width: 540px;
            max-height: 350px;
            margin-right: 60px;
            flex-shrink: 0;
            overflow: hidden;

            @include max($breakpoint-large) {
                margin-right: 0;
                max-width: 100%;
                height: 350px;
                margin-bottom: 25px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .wrapper_content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            padding-top: 10px;

            .list_categories {
                display: flex;
                margin-bottom: 27px;
                flex-wrap: wrap;

                .category {
                    color: $Rouge_Praxedo;
                    border: solid 1px $Rouge_Praxedo;
                    background: $white;
                    border-radius: 17px;
                    padding: 5px 10px;
                    font-size: 8px;
                    font-weight: 500;
                    letter-spacing: 0.07em;
                    text-transform: uppercase;
                    margin-bottom: 8px;

                    &:not(:last-child) {
                        margin-right: 8px;
                    }
                }
            }

            .titre {
                flex-grow: 1;
                @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile);
                @include responsive(35px, 20, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                font-weight: 500;
                color: inherit;
                @include responsive(25px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
            }

            .infos {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid rgba($mediumGrey_Praxedo, 0.5);
                @include responsive(22px, 7, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
            }

            .date {
                @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                line-height: 26px;
                font-weight: 300;
            }

            .auteur {
                display: flex;
                align-items: center;

                &_image {
                    width: 45px;
                    height: 45px;
                    background-color: white;
                    margin-right: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &_nom {
                    @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                    line-height: 26px;
                    font-weight: 300;
                }
            }
        }
    }

    @include responsive(30px, 50, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
    background: $bleuFonceFondHeader;

    display: flex;
    align-items: center;
    flex-direction: column;

    .wrapper_breadcrumb {
        text-align: center;
        color: $white;

        .separator {
            border-color: $white;
        }
    }

    .inner_content_wrapper {
        .wrapper_content {
            color: $white;
        }
    }
    
}