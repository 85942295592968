/*
BP : $breakpoint-large: 992px;

overrule old BP : $breakpoint-strate-medium: 1140px
*/

.page-template-simple-page {
    background-color: $grisClairFondHeader;
    // overrule
    .main-header {
        @include max($breakpoint-large){
            height: 50px;
        }

        .main-header-center {
            justify-content: center;
            position: static;
            flex-direction: row;
            flex-grow: 1;
            background-color: transparent;
            padding: 0;
            height: unset;
            text-align: center;
            overflow: unset;
            transform: none;
            visibility: visible;
            pointer-events: initial;
        }
    }
    
    #main_content {
        @include max($breakpoint-large){
            margin-top: 50px;
        }
    }

    #main_footer {
        .footer_bottom {
            margin-top: 70px;
            padding-top: 40px;
            padding-bottom: 45px;
            border-top: 1px solid $mediumGrey_Praxedo;

            // overrule old BP
            @include max($breakpoint-strate-medium){
                flex-direction: row;
            }

            @include max($breakpoint-large){
                flex-direction: column;
                border-top: none;
                margin-top: 0;
            }

            &.sticky {
                margin-top: 0;
                border-top: none;
            }

            .footer_bottom_right {
                // overrule old BP
                @include max($breakpoint-strate-medium){
                    margin-top: 0;
                }

                @include max($breakpoint-large){
                    justify-content: space-between;
                }

                .texte_cta {
                    margin-left: 24px;

                    @include max($breakpoint-large){
                        margin-left: 0;
                    }
                }
            }

            .item_footer_bottom_right {
                // margin-left: 24px;
                font-size: 14px;
                font-weight: 400;

                @include max($breakpoint-large){
                    margin-left: 0;
                    margin-top: 10px;
                }

                ul.menu {
                    display: flex;

                    @include max($breakpoint-large){
                        flex-direction: column;
                    }

                    li.menu-item {
                        margin-left: 24px;

                        @include max($breakpoint-large){
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}