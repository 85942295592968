/*
comportement tablette/mobile : $breakpoint-medium: 768px;
*/


.strate-praxedo-international {

    // version carousel...
    &.carousel {
        .inner_content_strate_praxedo_international {
            background-color: $bleuFonceFondHeader;

            .wrapper_top_strate {
                position: relative;

                .titre {
                    color: white;
                    text-align: left;

                    @include max($breakpoint-medium) {
                        text-align: center;
                        margin-bottom: 25px;
                    }
                }
    
                .wrapper_nav {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;

                    @include max($breakpoint-medium) {
                        display: none;
                    }

                    .link_nav {
                        // pas de hover !
                        // &:hover svg {
                        //     circle {
                        //         fill: green;
                        //         stroke: green;
                        //     }

                        //     path:last-of-type {
                        //         fill: green;
                        //     }
                        // } 

                        &.left {
                            margin-right: 25px;
                        }
                    }
                }
            }

            .wrapper_regions {
                @include max($breakpoint-medium) {
                    display: block;
                }

                &.owl-carousel {
                    .owl-stage-outer {
                        overflow: visible;
    
                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            z-index: 2;
                            left: calc((#{$breakpoint-strate-medium} - 100vw) / 2);
                            width: calc((100vw - #{$breakpoint-strate-medium}) / 2);
                            // petite marge de 5px pour éviter que ça déborde en haut et en bas
                            top: -5px;
                            height: calc(100% + 10px);
                            background-color: $bleuFonceFondHeader;
    
                            @include max($breakpoint-medium) {
                                display: none;
                            }
                        }
                    }
    
                    @include max($breakpoint-medium) {
                        .owl-dots {
                            display: flex;
                            justify-content: center;
                            padding: 30px 0 10px;
                
                            .owl-dot {
                                width: 5px;
                                height: 5px;
                                border-radius: 50px;
                                background-color: #c5d1d9;
                                margin: 0 15px;
                
                                &.active {
                                    background-color: $Red_Praxedo;
                                }
                            }
                        }
                    }
    
                    .region {
                        width: 240px;
    
                        .wrapper_image {
                            &:after {
                                background: linear-gradient(360deg, #000000 8.89%, rgba(0, 0, 0, 0) 70.76%);
                            }
    
                            .wrapper_textes_image {
                                padding: 0 25px;
                                text-align: left;
    
                                .pays {
                                    font-weight: 400;
                                    font-size: 12px;
                                    text-transform: uppercase;
                                }
    
                                .ville {
                                    font-size: 32px;
                                    line-height: 1em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }



    .inner_content_strate_praxedo_international {
        // 1140
        background-color: $grisClairFondHeader;
        @include responsive(20px, 110, $breakpoint-mobile, $desktop_to_mobile, 'margin-top'); // 130 / 20
        @include responsive(54px, 70, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom'); // 124 / 54

        &.no_margin_bottom {
            margin-bottom: 0;
        }

        // debug
        overflow: hidden;
    }

    .inner_content_wrapper {
        position: relative;
        @include responsive(50px, 70, $breakpoint-mobile, $desktop_to_mobile, 'padding-top'); // 120 / 50
        @include responsive(50px, 70, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom'); // 120 / 50

        &.decale {
            @include responsive(110px, 70, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom'); // 120 / 50
        }
    }

    .titre {
        @include titre-strate();
        @include responsive(40px, 38, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom'); // 124 / 54
        text-align: center;
    }

    .wrapper_strate_large .wrapper_regions .region {
        // 240 - strate 1260 large pour 5 items
        width: 19.047619047619048%;

        @include max($breakpoint-large) {
            width: 100%;
            max-width: 400px;
            flex-shrink: 0;
            margin-bottom: 50px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .wrapper_regions {
        display: flex;
        justify-content: space-between;

        @include max($breakpoint-medium) {
            flex-direction: column;
            align-items: center;
        }

        &.decale {
            .region:nth-child(even) {
                top: 60px;
            }

            @include max($breakpoint-medium) {

                .region:nth-child(even) {
                    top: 0;
                }
            }
        }

        .region {
            // 240 - strate 1140 large pour 4 items
            width: 21.052631578947368%;
            position: relative;

            @include max($breakpoint-medium) {
                width: 100%;
                max-width: 400px;
                flex-shrink: 0;
                margin-bottom: 50px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .wrapper_image {
                // 240 x 330
                width: 100%;
                height: 330px;
                position: relative;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                }

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(360deg, #000 8.89%, #000 50%);
                    opacity: 0.5;
                }

                .wrapper_textes_image {
                    position: absolute;
                    z-index: 3;
                    bottom: 46px;
                    left: 0;
                    width: 100%;
                    color: white;
                    font-weight: 500;
                    text-align: center;
                    line-height: 1em;

                    .pays {
                        // font-size: 16px;
                        @include font-responsive(22px, -4, $breakpoint-mobile, $desktop_to_mobile); // 22 / 16
                    }

                    .ville {
                        // font-size: 35px;
                        @include font-responsive(42px, -7, $breakpoint-mobile, $desktop_to_mobile); // 42 / 35
                        margin-top: 10px;
                    }
                    .wrapper_cta_savoir_plus {
                        margin-top: 25px;
                        // overrule cta
                        .cta.cta_red_with_red_arrow {
                            &:after {
                                background-color: white;
                            }

                            .texte_cta {
                                color: white;
                            }

                            .picto_cta svg > * {
                                fill: white;
                            }
                        }
                    }
                     
                }
            }

            .wrapper_coordonnees {
                padding-top: 30px;
                font-weight: 500;
                font-size: 18px;
                line-height: 1.4em;

                .titre_coordonnees {
                    color: $Rouge_Praxedo;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-size: 12px;
                    line-height: 2.3em;
                }

                .wrapper_telephone {
                    padding-top: 18px;
                }
            }
        }
    }
}
