.strate-2-col-texte-image {

    .inner_content_strate_2_col_texte_image {
        @include responsive(70px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(70px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
    }

    .inner_content_wrapper {
        display: flex;
        justify-content: space-between;

        @include max($breakpoint-strate-small) {
            flex-direction: column;
        }
    }



    // largeur strate = 1140

    .strate-2-col-texte-image__texte {
        width: 100%;
        max-width: 600px;

        &.none {
            padding-right: 60px;
            @include max($breakpoint-strate-small) {
                padding-right: 0;
            }
        }

        @include max($breakpoint-strate-small) {
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__titre_h3 {
            @include font-responsive(20px, 12, $breakpoint-mobile, $desktop_to_mobile);
            color: $Red_Praxedo;
            font-weight: 500;

            @include max($breakpoint-strate-small) {
                width: 100%;
            }
        }

        &__titre_h3_bf {
            @include font-responsive(20px, 12, $breakpoint-mobile, $desktop_to_mobile);
            font-weight: 500;

            @include max($breakpoint-strate-small) {
                width: 100%;
            }
        }

        &__titre_h2 {
            @include titre-strate();

            @include max($breakpoint-strate-small) {
                width: 100%;
            }
        }

        &__sous-titre {
            @include font-responsive(19px, 1, $breakpoint-mobile, $desktop_to_mobile);
            @include responsive(20px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
            font-weight: 500;
            align-self: flex-start;
        }

        &__contenu {
            @include responsive(20px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');

            p {
                @include texte-standard();
                @include responsive(25px, 3, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                font-weight: 300;
                line-height: 20px;
                @include responsive(20px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
            }

            ul {
                li {
                    padding-left: 20px;
                    position: relative;
                    margin-bottom: 10px;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 7px;
                        height: 7px;
                        border-radius: 10px;
                        background-color: $Red_Praxedo;
                        top: 7px;
                        left: 0;
                    }
                }
            }

            a:not(.cta) {
                color: $Red_Praxedo;
                text-decoration: underline;
            }
        }

        a , button {
            margin-top: 30px;
        }

    }

    .strate-2-col-texte-image__image { // Div contenant la colonne image
        .strate-2-col-texte-image__image__icone-sur-image-conteneur {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 90px;
            height: 90px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .layer_youtube {
            position: absolute;
            background-color: rgba(0, 0, 0, .3);
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .video_embed {
                display: none;
            }

            a.cta_player_video, button.cta_player_video {
                display: block;
                line-height: 0;
            }
        }
    }

    &.type_ombre_image_wide { // Déclinaison grande ombre - à droite
        .inner_content_strate_2_col_texte_image {
            overflow-x: hidden;
        }

        .strate-2-col-texte-image__texte {
            // max-width: 530px;
            max-width: 46.491228070175439%;

            @include max($breakpoint-strate-small) {
                max-width: 100%;
            }
        }

        .strate-2-col-texte-image__image {
            // max-width: 580px;
            max-width: 50.87719298245614%;
            width: 100%;
            // height: 100%;
            position: relative;
            display: flex;
            align-items: center;

            @include max($breakpoint-strate-small) {
                max-width: 100%;
                margin-top: 40px;
                padding: 15px 0;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 110px;
                width: 50vw;
                height: 100%;
                background-color: $greyPush_Praxedo;

                @include max($breakpoint-strate-small) {
                    left: -20px;
                    width: 100vw;
                }
            }

            img {
                position: relative;
                z-index: 2;

                @include max($breakpoint-strate-small) {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &.strate-2-col-texte-image--left { // à gauche
            .inner_content_strate_2_col_texte_image {
                overflow-x: hidden;
            }

            .strate-2-col-texte-image__image {
                justify-content: flex-end;

                &::before {
                    right: 110px;
                    left: unset;

                    @include max($breakpoint-strate-small) {
                        right: -20px;
                    }
                }
            }
        }
    }

    &.type_ombre_image_short { // Déclinaison petite ombre
        .strate-2-col-texte-image__image {
            max-width: 400px;
            max-height: 500px;
            width: 100%;
            height: 100%;
            position: relative;
            -webkit-box-shadow: 18px 18px 0px 0px $greyPush_Praxedo;
            box-shadow: 18px 18px 0px 0px $greyPush_Praxedo;
            display: flex;
            @include max($breakpoint-strate-small) {
                margin: auto;
                margin-top: 40px;
                -webkit-box-shadow: 14px 14px 0px 0px $greyPush_Praxedo;
                box-shadow: 14px 14px 0px 0px $greyPush_Praxedo;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &.type_ombre_image_none {
        .strate-2-col-texte-image__image {
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &.strate-2-col-texte-image--left { // Déclinaison image à gauche
        .inner_content_wrapper {
            flex-direction: row-reverse;

            @include max($breakpoint-strate-small) {
                flex-direction: column;
            }
        }
    }

    .wrapper_cta {
        @include responsive(66px, 44, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        text-align: center;
    }
}
