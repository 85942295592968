.calculette {
  .inner_content_wrapper {
    padding-block: 50px;

    @include min($breakpoint-medium) {
      padding-block: 72px;
    }
  }
  .wrapper_top {
    @include responsive(34px, 26, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

    .wrapper_breadcrumb {
      text-align: center;
    }

    .titre {
      @include titre-header();

      text-align: center;
      margin-bottom: 20px;
    }

    .sous_titre {
      @include font-responsive(14px, 6, $breakpoint-mobile, $desktop_to_mobile);
      @include responsive(20px, 8, $breakpoint-mobile, $desktop_to_mobile, 'line-height');

      font-weight: 300;
      text-align: center;
    }
  }

  .calculette-wrapper {
    background: $greyPush_Praxedo;
    padding: 18px;
    margin-inline: -20px;

    @include min($breakpoint-mobile) {
      padding: 30px;
    }

    @include min($breakpoint-medium) {
      margin-inline: unset;
      border-radius: 10px;
    }

    .calculette-inner {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      @include min($breakpoint-medium) {
        flex-direction: row;
        gap: 20px;
      }
    }

    .calculette-form {
      max-width: 510px;

      .form-input-wrapper {
        &:not(first-child) {
          margin-top: 20px;

          @include min($breakpoint-medium) {
            margin-top: 25px;
          }
        }
      }

      .form-input-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
      }

      .form-label {
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        font-weight: 500;
      }

      .form-input {
        @include responsive(100px, 50, $breakpoint-mobile, $desktop_to_mobile, 'width');
        @include responsive(20px, 40, $breakpoint-mobile, $desktop_to_mobile, 'margin-left');

        border: 1px solid $mediumGrey_Praxedo;
        display: block;
        transition: border-color 0.15s ease;
        outline: none;
        font-size: 18px;
        line-height: 25px;
        padding: 12px 15px;

        @include min($breakpoint-medium) {
          padding: 12px 20px;
        }

        &.with-error {
          border: 1px solid $Red_Praxedo;
          color: $Red_Praxedo;
        }

        &.with-unit {
          padding-right: 38px;
        }
      }
      /* For Chrome, Safari, and newer versions of Edge */
      .form-input::-webkit-inner-spin-button,
      .form-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* For Firefox */
      .form-input {
        -moz-appearance: textfield;
      }

      /* For older versions of Edge and IE */
      .form-input::-ms-clear,
      .form-input::-ms-reveal {
        display: none;
      }

      /* For modern browsers (including newer Edge, which doesn't need a prefix) */
      .form-input::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
      }

      .form-unit {
        position: absolute;
        right: 8px;

        @include min($breakpoint-medium) {
          right: 15px;
        }
      }

      .text-error {
        color: $Red_Praxedo;
        text-align: right;
        display: inherit;
        font-size: 14px;
        margin-top: 5px;
      }

      .cta.cta_rectangle {
        width: 100%;
        margin-top: 40px;
        height: 49px;
        border: 1px solid $Red_Praxedo;

        .texte_cta {
          font-size: 16px;
        }

        @include min($breakpoint-medium) {
          margin-top: 50px;
        }
      }
    }

    .simulation {
      flex-basis: 50%;
      flex-shrink: 0;
      background: $DarkBlue_Text;
      color: $white;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include min($breakpoint-large) {
        padding: 35px;
      }

      &__title, &__desc {
        text-align: center;
      }

      &__title {
        @include font-responsive(18px, 4, $breakpoint-mobile, $desktop_to_mobile);
        @include responsive(26px, 2, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
      }

      &__result {
        @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile);

        font-weight: 700;
        margin-top: 30px;

        @include min($breakpoint-medium) {
          margin-top: 20px;
        }

        .valid {
          display: none;
          gap: 10px;
          justify-content: center;
          flex-wrap: wrap;
        }
      }

      &__desc {
        @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);

        line-height: 23px;
        font-weight: 200;
        margin-top: 30px;

        @include min($breakpoint-medium) {
          margin-top: 20px;
        }
      }

      &__icon-wrapper {
        margin-top: 40px;
        width: 80px;
        height: 80px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__cta-wrapper {
        margin-top: 40px;

        .cta.cta_rectangle {
          height: 49px;
          padding-block: 15px;
          @include responsive(39px, 11, $breakpoint-mobile, $desktop_to_mobile, 'padding-inline');

          &::after {
            background-color: $white;
          }

          &:hover {
            .texte_cta {
              color: $Rouge_Praxedo;
            }
          }
        }

        .texte_cta {
          white-space: nowrap;
          font-size: 16px;
        }
      }

      &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 15px;
        @include responsive(301px, 22, $breakpoint-mobile, $desktop_to_mobile, 'width');

        &--item {
          display: flex;
          align-items: baseline;
          gap: 7px;
        }

        svg {
          width: 11px;
          height: auto;
        }

        .check {
          font-size: 12px;
        }
      }
    }
  }
}