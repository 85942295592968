.warning_empty_acf {
    color: rgb(255, 0, 0);
}

html, body {
    scroll-behavior: smooth;
}

body.prevent-scroll {
    height: 100vh;
    overflow: hidden;
}

@include max($breakpoint-medium) {
    #wp-admin-bar-wpseo-menu, #wp-admin-bar-customize {
        display: none !important;
    }
}

section {
    overflow-x: hidden;
}