$bp_max_fr_ecl: 780; // calc(1260 - 480);

.strate-erp-connecteurs-liste {
    // wrapper_strate_large : 1260px

    .inner_content_strate_erp_connecteurs_header {
        background-color: $grisClairFondHeader;

        .inner_content_wrapper {
            @include responsive(40px, 20, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
            @include responsive(130px, 220, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
        }

        .wrapper_breadcrumb {
            text-align: center;
            margin-bottom: 28px;
        }

        .titre {
            @include titre-header();
            margin-bottom: 35px;
        }

        .sous_titre {
            text-align: center;
            @include font-responsive(14px, 6, $breakpoint-mobile, $desktop_to_mobile);
            @include responsive(20px, 8, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
            font-weight: 300;
            max-width: 738px;
            margin: 0 auto;
            margin-top: 35px;
            line-height: 1.4;
        }
    }

    .inner_content_strate_erp_connecteurs_liste {
        @include responsive(-65px, -165, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(0px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

        .erp_connecteurs_liste {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .erp_connecteur {
                background: $white;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
                border-radius: 10px;
                display: flex;
                flex-direction: column-reverse;
                justify-content: flex-end;
                width: calc(25% - (14px * 2));
                overflow: hidden;
                @include responsive(30px, 40, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

                @include max($breakpoint-large) {
                    width: calc(33.33333333333% - (14px * 2));
                }

                @include max($breakpoint-medium) {
                    width: calc(50% - (14px * 2));
                }

                @include max($breakpoint-mobile) {
                    width: 100%;
                }

                .wrapper_image {
                    width: 100%;
                    height: 200px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .wrapper_content {
                    padding: 45px 25px 55px;
                    border-bottom: 5px solid $Rouge_Praxedo;
                    flex-grow: 1;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;

                    .titre {
                        @include font-responsive(20px, 12, $breakpoint-mobile, $desktop_to_mobile);
                        @include responsive(28px, 10, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                        @include responsive(28px, -10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                    }

                    .cta {
                        justify-content: flex-end;
                        max-width: max-content;
                    }
                }

                &.empty_block {
                    height: 0px;
                    margin: 0;
                }
            }
        }
    }


}

