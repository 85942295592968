/*
Comportement tablette / mobile => $breakpoint-large: 992px;
*/

.strate-push-full-screen {

    .inner_content_strate_push_full_screen {

        position: relative;
        height: 800px;
        @include max($breakpoint-large) {
            height: auto;
        }

        @include responsive(70px, 70, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(70px, 70, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

        .wrapper_bg_image {
            width: 100%;
            height: 100%;
            position: absolute;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include max($breakpoint-large) {
                display: none;
            }
        }
    }

    .wrapper {
        position: relative;
        z-index: 2;
        height: 100%;
        @include max($breakpoint-large) {
            height: auto;
        }
    }

    .inner_content_wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        @include max($breakpoint-large) {
            height: auto;
        }

    }

    .white_box {
        max-width: 600px;
        width: 100%;
        background-color: white;
        padding: 65px 60px;
        border-radius: 10px;

        @include max($breakpoint-large) {
            max-width: 100%;
            padding: 0;
            border-radius: 0;
        }

        .wrapper_bg_image_mobile {
            display: none;

            @include max($breakpoint-large) {
                display: block;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .titre {
            @include titre-strate();
            margin-bottom: 35px;
        }

        .texte {
            @include font-responsive(16px, 4, $breakpoint-mobile, $desktop_to_mobile);
            line-height: 1.4em;

            @include max($breakpoint-large) {
                margin-top: 25px;
            }

            p {
                font-weight: 300;
            }

            strong {
                font-weight: 500;
            }
        }

        .wrapper_cta {
            margin-top: 35px;
        }
    }
}
