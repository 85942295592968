/*
Comportement tablette / mobile => $breakpoint-mobile: 480px;

Sauf pour le filter_search_wrapper =>  $breakpoint-medium
*/



// 1260
.strate-offres-emplois {

    .inner_content_strate_offres_emplois {
        @include responsive(60px, 70, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(60px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        background-color: $LightGrey_Praxedo_Bkg;
    }

    .inner_content_wrapper {
        @include responsive(53px, 57, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
        @include responsive(59px, 23, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
    }

    .titre {
        @include titre-strate();
        text-align: center;
        // margin-bottom: 60px;
        @include responsive(25px, 35, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
    }

    .filter_search_wrapper {
        @include responsive(35px, 25, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        padding-top: 25px;
        border-top: 1px solid rgba($mediumGrey_Praxedo, 0.5);

        @include responsive(35px, 25, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        padding-bottom: 25px;
        border-bottom: 1px solid rgba($mediumGrey_Praxedo, 0.5);

        @include max($breakpoint-medium) {
            padding: 0;
            border: none;
        }

        // form
        .categories-select-form {
            display: flex;
            justify-content: center;
            align-items: center;

            @include max($breakpoint-medium) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .filter_wrapper {
            display: flex;
            align-items: center;
            position: relative;
            height: 20px;
            margin: 0 30px;

            @include max($breakpoint-medium) {
                height: 50px;
                margin: 0;
                width: 100%;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -20px;
                    width: 100vw;
                    height: 1px;
                    background-color: #C5D1D9;
                }

                &:nth-child(3) {
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: -20px;
                        width: 100vw;
                        height: 1px;
                        background-color: #C5D1D9;
                    }
                }
            }
            
            &:nth-child(2) {
                &::before, &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    width: 1px;
                    height: 100%;
                    background-color: #C5D1D9;
                }

                &::before {
                    left: -30px;

                    @include max($breakpoint-medium) {
                        top: 0;
                        left: -20px;
                        width: 100vw;
                        height: 1px;
                        background-color: #C5D1D9;
                    }
                }

                &::after {
                    right: -30px;

                    @include max($breakpoint-medium) {
                        display: none;
                    }
                }
            }

            select {
                -webkit-appearance: none;
                appearance: none;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.07em;
                text-transform: uppercase;
                border: 0;
                padding-right: 20px;
                background-color: transparent;
                outline: none;

                @include max($breakpoint-medium) {
                    height: 100%;
                }
            }

            .icon {
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                margin-left: 8px;
                pointer-events: none;

                svg {
                    path {
                        stroke: $Rouge_Praxedo;
                    }
                }
            }
        }

        .search_wrapper {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            width: 100%;
            max-width: 250px;
            height: 50px;
            padding: 0 15px;
            border: 1px solid rgba($mediumGrey_Praxedo, 0.5);
            border-radius: 100px;

            @include max($breakpoint-medium) {
                max-width: 450px;
            }

            input {
                border: 0;
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;
                flex-grow: 1;
            }

            button {
                background: transparent;
                border: 0;
                display: flex;
                align-items: center;
            }
        }
    }

    .section {
        margin-bottom: 50px;

        .texte_error {
            text-align: center;
            font-size: 16px;
            line-height: 1.4em;
            color: $Gris_Praxedo;
        }

        &.hidden {
            display: none;
        }

        .titre_section {
            @include font-responsive(20px, 12, $breakpoint-mobile, $desktop_to_mobile);
            line-height: 1.2em;
            font-weight: 500;
            @include responsive(15px, 20, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');

            @include max($breakpoint-mobile) {
                text-align: center;
            }

            .compteur {
                @include font-responsive(16px, 9, $breakpoint-mobile, $desktop_to_mobile);
            }
        }

        .wrapper_offres {
            // 1260
            display: flex;
            flex-wrap: wrap;
            // column-gap: 28px; // espace mini entre les offres
            // row-gap: 28px;
            margin: -14px -14px;
        }

        .wrapper_offre {
            background-color: #FFFFFF;
            position: relative;
            transition: all 500ms;

            .inner_content_offre {
                position: relative;
                height: 100%;
                transition: all 350ms 400ms;
            }

            &.hidden {
                // display: none;
                width: 0;
                height: 0;
                box-shadow: none;
                border-radius: 0;
                padding: 0;
                opacity: 1;
                margin: 0;

                .inner_content_offre {
                    opacity: 0;
                    transition: none;
                }
            }

            &.show {
                width: 294px;
                height: 200px;
                box-shadow: 0px 0px 40px rgba(198, 210, 217, 0.25);
                border-radius: 10px;
                padding: 20px;
                opacity: 1;
                margin: 14px 14px;
            }

            .reprise_titre_section {
                height: 24px;
                padding: 0 18px;
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
                border: 1px solid #C5D1D9;
                border-radius: 12px;
                font-size: 10px;
                text-transform: uppercase;
                font-weight: 500;
                margin-bottom: 20px;
                letter-spacing: 0.07em;
            }

            .titre_offre {
                @include font-responsive(16px, 2, $breakpoint-mobile, $desktop_to_mobile);
                line-height: 1.4em;
                font-weight: 400;
                width: 100%;
                margin-bottom: 18px;
                margin-bottom: 4px;
            }

            .wrapper_detail {
                font-size: 14px;
                line-height: 1.4em;
                font-weight: 400;
                display: flex;
                color: #98A6AE;
            }

            .wrapper_cta {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        &.wrapper_sections_cs {
            margin-bottom: 0;
            margin-top: 60px;

            .wrapper_offre_cs {
                padding: 54px 123px 54px 62px;
                box-shadow: 0px 0px 40px rgba(198, 210, 217, 0.25);
                border-radius: 10px;
                background-color: white;

                @include max($breakpoint-mobile) {
                    padding: 20px;
                }

                .texte_cs, .texte_cs p {
                    @include font-responsive(14px, 4, $breakpoint-mobile, $desktop_to_mobile);
                    @include responsive(24px, 4, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                    font-weight: 300;
        
                    strong {
                        font-weight: 500;
                    }
                }
    
                .wrapper_cta_cs {
                    @include responsive(14px, 16, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
                }
            }
        }
    }
}
