/*
Marc le 7/07
Le contenu de gauche (titre, texte, cta, etc.) est parfois trop grand sur petit écran 13 pouces : breadcrumb et CTAs ne s'affichent plus entièrement :
@media (max-height: 820px){ ... }

breakpoint largeur responsive => $breakpoint-strate-small
breakpoint hauteur pour 13 pouces => $breakpoint-height-13pouces : 820px;
*/


.header-main {
    .inner_content_header_main {
        background-color: $grisClairFondHeader;
        //margin-bottom: 120px;
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        @include max($breakpoint-strate-small) {
            padding-top: 35px;
        }

        &.no-margin-bottom {
            margin-bottom: 0;
        }
    }

    .inner_content_wrapper {

        padding-bottom: calc(100vh - #{$hauteur_nav});
        position: relative;
        width: 100%;
        @include max($breakpoint-strate-small) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-bottom: 0;
        }
        // @media (min-width: $breakpoint-strate-small) and (max-height: $breakpoint-height-13pouces){
        //     background-color: green;
        // }
    }

    .col {
        height: 100%;
        position: absolute;
        top: 0;
        @include max($breakpoint-strate-small) {
            position: unset;
        }
    }

    $largeur_col_left: 40%;

    .col_left {
        // padding-left: 148px : 10,277777777777778%
        // gouttiere : 50px : 3,472222222222222%
        left: 10.27%;
        width: calc(100% - #{$largeur_col_left} - 3.472% - 10.27%);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .wrapper_breadcrumb {
            @media (min-width: $breakpoint-strate-small) and (max-height: $breakpoint-height-13pouces) {
                margin-bottom: 25px;
            }
        }

        .picto_single_besoins {
            margin-bottom: 26px;

            @media (min-width: $breakpoint-strate-small) and (max-height: $breakpoint-height-13pouces) {
                margin-bottom: 6px;
            }
        }

        .titre {
            @include font-responsive(45px, 20, $breakpoint-mobile, 960);
            @include responsive(45px, 25, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
            font-weight: 700;
            // padding-bottom: 37px;
            padding-bottom: 6.335616438356164%;
            @media (min-width: $breakpoint-strate-small) and (max-height: $breakpoint-height-13pouces) {
                @include font-responsive(45px, 10, $breakpoint-mobile, 960);
                line-height: 1em;
                padding-bottom: 10px;
            }

            &.bigger {
                @include font-responsive(45px, 34, $breakpoint-mobile, 960);
                @include responsive(45px, 30, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
            }
        }

        .sous_titre {
            @include font-responsive(14px, 4, $breakpoint-mobile, 960);
            line-height: 1.4;
            font-weight: 300;
        }

        .titre, .sous_titre {
            @include max($breakpoint-strate-small) {
                text-align: center;
            }
        }

        .wrapper_ctas {

            @include responsive(22px, 22, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
            display: flex;

            @include max($breakpoint-strate-small) {
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            @media (min-width: $breakpoint-strate-small) and (max-height: $breakpoint-height-13pouces) {
                padding-top: 30px;
            }

            .cta_wide_fixe {
                min-width: 155px;

                &:first-of-type {
                    margin-right: 15px;

                    @include max($breakpoint-strate-small) {
                        margin-right: 0;
                        margin-bottom: 15px;
                    }
                }

                &.cta_fond_blanc {
                    .texte_cta {
                        color: $Rouge_Praxedo;
                    }
                }
            }
        }
    }

    .col_right {
        right: 0;
        // 658 * 715
        width: $largeur_col_left;
        // l'image est taille réelle, calée en bas à droite

        .wrapper_image {
            width: 100%;
            height: 100%;
            overflow: hidden;
            // padding-top: 30px;
            //padding-top: 4.195804195804196%;
            @include responsive(40px , -10 , $breakpoint-mobile, $desktop_to_mobile, 'padding-top');

            img {
                pointer-events: none;
                width: 100%;
                height: 100%;
                object-fit: contain;
                // object-position: right bottom;
                object-position: left bottom;

                @include max($breakpoint-strate-small) {
                    object-position: center bottom;
                    height: auto;
                }
            }

            @include max($breakpoint-strate-small) {
                display: flex;
                justify-content: center;
                max-height: 400px;
            }
        }
    }

    .col_left, .col_right {
        @include max($breakpoint-strate-small) {
            width: 100%;
        }
    }
}
