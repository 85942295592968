/*
comportement tablette/mobile : $breakpoint-large => 992
valeur reprise dans header-carriere.js
*/

.header-carriere {
    .inner_content_header_carriere {
        background-color: $grisClairFondHeader;
    }

    .inner_content_wrapper {
        // padding-bottom: calc(100vh - #{$hauteur_nav});
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: calc(100vh - #{$hauteur_nav});

        @include max($breakpoint-large) {
            flex-direction: column;
            justify-content: flex-start;
            height: auto;
            padding-top: 40px;
        }

        .wrapper_cols {
            display: flex;
            justify-content: space-between;

            @include max($breakpoint-large) {
                flex-direction: column;
                justify-content: flex-start;
            }
        }

        .col {
            height: 100%;
            @include max($breakpoint-large) {
                height: auto;
            }
        }

        .col_left {
            // width: 30.952380952380952%;
            width: 32%;
            margin-left: 4.761904761904762%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include max($breakpoint-large) {
                width: 100%;
                margin-left: 0;
            }

            .wrapper_breadcrumb {
                // text-align: center;
                @include max($breakpoint-large) {
                    margin-bottom: 31px;
                }
            }

            .titre {
                @include font-responsive(45px, 20, $breakpoint-mobile, 960);
                @include responsive(45px, 25, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                font-weight: 700;
                padding-bottom: 6.335616438356164%;
                @include max($breakpoint-large) {
                    padding-bottom: 23px;
                }

                &.bigger {
                    @include font-responsive(45px, 34, $breakpoint-mobile, 960);
                    @include responsive(45px, 30, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                }
            }

            .sous_titre {
                @include font-responsive(18px, 2, $breakpoint-mobile, 960);
                line-height: 1.4em;
                font-weight: 300;
                margin-bottom: 37px;

                @include max($breakpoint-large) {
                    margin-bottom: 23px;
                }
            }

            .titre, .sous_titre {
                @include max($breakpoint-large) {
                    text-align: center;
                }
            }

            .wrapper_cta {
                @include max($breakpoint-large) {
                    text-align: center;
                    margin-bottom: 40px;
                }
            }

        }

        .col_right {
            // 660
            width: 52.380952380952381%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include max($breakpoint-large) {
                display: block;
                width: 100%;
            }

            .col_temoignage {
                width: 48.181818181818182%;
                @include max($breakpoint-large) {
                    display: none;
                }
            }

            .wrapper_slider {
                @include min(calc(#{$breakpoint-large} + 1px)) {
                    display: none;
                }

                .owl-item {
                    display: flex;
                    justify-content: center;
                }

                .owl-dots {
                    display: flex;
                    justify-content: center;
                    padding: 23px 0 58px;

                    .owl-dot {
                        width: 5px;
                        height: 5px;
                        border-radius: 50px;
                        background-color: #c5d1d9;
                        margin: 0 15px;

                        &.active {
                            background-color: $Red_Praxedo;
                        }
                    }
                }
            }


            .wrapper_temoignage {
                // 318 x 214
                position: relative;
                display: block;
                width: 100%;
                padding-bottom: 67.29559748427673%;
                @include max($breakpoint-large) {
                    width: 340px;
                    padding-bottom: 228px;
                }

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
                    z-index: 2;
                }

                &:first-of-type {
                    margin-bottom: 26px;
                    @include max($breakpoint-large) {
                        margin-bottom: 0;
                    }
                }

                img {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .bottom {
                    position: absolute;
                    z-index: 3;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 0 12px 12px;

                    svg {
                        width: 50px;
                        height: 50px;
                    }

                    .texte {
                        color: white;
                        margin-left: 18px;
                        @include font-responsive(16px , 2 , $breakpoint-mobile, $desktop_to_mobile);
                    }

                    .video_embed {
                        display: none;
                    }
                }
            }
        }

        .wrapper_avis {
            &.desktop {
                @include max($breakpoint-large) {
                    display: none;
                }
            }
            &.mobile {
                display: none;

                @include max($breakpoint-large) {
                    display: block;
                }
            }

            .partageable-notes-etoiles {
                margin-top: 72px;

                @include max($breakpoint-large) {
                    margin-top: 0;
                }

                .col {
                    max-width: 300px;
                    justify-content: space-between;
                    height: 65px;

                    @include max($breakpoint-large) {
                        margin: 0 0 30px;
                    }
                }
            }            
        }
    }
}
