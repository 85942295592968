$bp_max_fr_s4tbr: 660; // calc(1140 - 480);

.strate-4-tiny-blocks-row {
    // wrapper_strate_medium : 1140px

    .inner_content_strate_4_tiny_blocks_row {
        margin-bottom: 0;

        @include max($breakpoint-strate-medium) {
            overflow: hidden;
        }
        @include responsive(70px, 50, $breakpoint-mobile, $bp_max_fr_s4tbr, 'margin-top');

        &.gris {
            background-color: $grisClairFondHeader;

            .inner_content_wrapper {
                @include responsive(70px, 30, $breakpoint-mobile, $bp_max_fr_s4tbr, 'padding-top');
                @include responsive(70px, 75, $breakpoint-mobile, $bp_max_fr_s4tbr, 'padding-bottom');
            }
        }

        &.blanc {
            background-color: transparent;

            .inner_content_wrapper {
                @include responsive(10px, 75, $breakpoint-mobile, $bp_max_fr_s4tbr, 'padding-bottom');
            }
        }
    }

    .wrapper_top {
        margin-bottom: 64px;

        .titre {
            @include titre-strate();
            @include font-responsive(30px, 20, $breakpoint-mobile, $bp_max_fr_s4tbr); // 50xp - 50px - 480px - 1140px
            text-align: center;
        }

        .wrapper_cta {
            // margin-top: 20px;
            @include responsive(10px, 10, $breakpoint-mobile, $bp_max_fr_s4tbr, 'margin-top');
            // padding-bottom: 16px;
            @include responsive(0px, 16, $breakpoint-mobile, $bp_max_fr_s4tbr, 'padding-bottom');
            text-align: center;
        }
    }


    .wrapper_bottom {
        display: flex;
        justify-content: space-between;

        @include max($breakpoint-strate-medium) {
            display: block;
        }
    }

    .tiny_block {
        width: 250px;
        height: 330px;
        background-color: $white;
        box-shadow: 0px 0px 100px rgba(198, 210, 217, 0.4);
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        padding: 30px 30px 0;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            background-color: $Red_Praxedo;
            position: absolute;
            bottom: 0;
            left: 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        &:nth-child(even) {
            top: 40px;
        }

        .wrapper_svg {
            margin-bottom: 50px;
        }

        .titre_block {
            font-size: 25px;
            line-height: 1.2;
            font-weight: 500;
            margin-bottom: 14px;
        }

        .texte_block {
            font-weight: 300;
        }
    }

    .owl-carousel .owl-stage-outer {

        @include max($breakpoint-strate-medium) {
            overflow: unset;
        }

        .owl-stage {

            .owl-item {
                transition: $transition_standard;

                &.center {
                    transform: scale(1.15);
                }
            }
        }
    }

    .owl-dots {
        display: flex;
        justify-content: center;
        margin-top: 70px;

        .owl-dot {
            width: 5px;
            height: 5px;
            border-radius: 50px;
            background-color: $mediumGrey_Praxedo;
            margin: 0 15px;

            &.active {
                background-color: $Red_Praxedo;
            }
        }
    }
}
