/*
Cas particulier : partie gauche (adresse + contact) de la page contact

Le balisage a été modifié à la main et wrappé dans une div.zone_adresse_page_contact

<div class="zone_adresse_page_contact">
<p class="with_border_bottom">Situé en plein cœur de Paris entre Saint-Lazare et Opéra, facilement accessible en transports en commun, venez-nous rencontrer au siège social de Praxedo !</p>
<p class="red_sub_title">Adresse</p>
<p><strong>28, rue Mogador, 75009 Paris</strong></p>
<div class="metro_rer">
<p><img class="alignnone size-medium wp-image-52305" src="https://praxedo.dev.limpide.fr/wp-content/uploads/2021/06/rer_a.svg" alt="" width="27" height="27" />RER A – Station Auber</p>
<p><img class="alignnone size-medium wp-image-52297" src="https://praxedo.dev.limpide.fr/wp-content/uploads/2021/06/rer_e.svg" alt="" width="27" height="27" />RER E – Haussmann-Saint Lazare</p>
<p><img class="alignnone size-medium wp-image-52337" src="https://praxedo.dev.limpide.fr/wp-content/uploads/2021/06/metro_3.svg" alt="" width="25" height="26" /><img class="alignnone size-medium wp-image-52329" src="https://praxedo.dev.limpide.fr/wp-content/uploads/2021/06/metro_12.svg" alt="" width="25" height="26" /><img class="alignnone size-medium wp-image-52321" src="https://praxedo.dev.limpide.fr/wp-content/uploads/2021/06/metro_13.svg" alt="" width="25" height="26" /><img class="alignnone size-medium wp-image-52313" src="https://praxedo.dev.limpide.fr/wp-content/uploads/2021/06/metro_14.svg" alt="" width="25" height="26" />Métro – Sation Saint-Lazare</p>
</div>
<p class="red_sub_title">Contact</p>
<p class="without_margin_bottom_mobile"><strong>+33 (0)1 40 33 79 79</strong></p>
</div>

*/

.zone_adresse_page_contact {

    .with_border_bottom {
        padding-bottom: 30px;
        border-bottom: solid 1px rgba(#C5D1D9, .5);
    }

    .red_sub_title {
        font-size: 12px !important;
        font-weight: 500 !important;
        color: $Rouge_Praxedo !important;
        text-transform: uppercase !important;
        letter-spacing: 1px !important;
        margin-bottom: 0 !important;
    }

    .metro_rer {

        p {
            display: flex !important;
            align-items: center !important;
            font-weight: 400 !important;
            margin-bottom: 17px !important;

            img {
                margin-right: 9px !important;
            }
        }
    }

    strong {
        font-weight: 500 !important;
    }

    .without_margin_bottom_mobile {
        @include max($breakpoint-mobile) {
            margin-bottom: 0 !important;
        }
    }
}
