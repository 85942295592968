@mixin cta_common() {
    display: inline-flex;
    align-items: center;
}

@mixin after_cta_with_fond() {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    transition: $transition_smooth_speed;
}

.cta {
    text-decoration: none;
    transition: $transition_default;

    .picto_cta {
        margin-right: 8px;
        line-height: 0;
        z-index: 1;
    }
    .texte_cta {
        pointer-events: none;
    }

    &.cta_fond_rouge {
        background-color: $Rouge_Praxedo;
        border-color: $Rouge_Praxedo !important;
        position: relative;

        .texte_cta {
            color: $white;
            position: relative;
            z-index: 1;
        }

        &::after {
            @include after_cta_with_fond();
            background-color: $DarkBlue_Text;
        }

        &:hover {
            border-color: $DarkBlue_Text !important;

            &::after {
                width: 100%;
            }
        }
    }

    &.cta_fond_bleu {
        background-color: $LightBleu_Praxedo;
        border-color: $LightBleu_Praxedo !important;

        .texte_cta {
            color: $Bleu_Praxedo;
        }
    }

    &.cta_fond_blanc_bleu {
        background-color: $bleuFonceFondHeader;
        position: relative;

        .texte_cta {
            color: $white;
            position: relative;
            z-index: 1;
            transition: $transition_default;
        }

        &.cta_typo_red {
            .texte_cta {
                color: $Rouge_Praxedo;
            }
        }

        &.cta_border_red {
            border: 1px solid $Rouge_Praxedo;
        }

        &::after {
            @include after_cta_with_fond();
            background-color: $white;
        }

        &:hover {
            border-color: $white !important;
 
            .texte_cta {
                color: $DarkBlue_Text !important;
            }

            &::after {
                width: 100%;
            }

            .picto_cta {
                svg {
                    fill: $white;

                    * {
                        fill: $white;
                    }
                }
            }
        }
    }

    &.cta_fond_blanc {
        background-color: $white;
        position: relative;

        .texte_cta {
            color: $DarkBlue_Text;
            position: relative;
            z-index: 1;
            transition: $transition_default;
        }

        &.cta_typo_red {
            .texte_cta {
                color: $Rouge_Praxedo;
            }
        }

        &.cta_border_red {
            border: 1px solid $Rouge_Praxedo;
        }

        &::after {
            @include after_cta_with_fond();
            background-color: $Rouge_Praxedo;
        }

        &:hover {
            border-color: $Rouge_Praxedo !important;

            .texte_cta {
                color: $white !important;
            }

            &::after {
                width: 100%;
            }

            .picto_cta {
                svg {
                    fill: $white;

                    * {
                        fill: $white;
                    }
                }
            }
        }
    }

    &.cta_fond_transparent {
        background-color: transparent;
        position: relative;

        .texte_cta {
            // color: $black;
            color: $Red_Praxedo;
            position: relative;
            z-index: 1;
            transition: $transition_default;
        }

        &.cta_typo_red {
            .texte_cta {
                color: $Rouge_Praxedo;
            }
        }

        &::after {
            @include after_cta_with_fond();
            background-color: $Rouge_Praxedo;
        }

        &:hover {
            border-color: $Red_Praxedo;

            .texte_cta {
                color: $white !important;
            }

            &::after {
                width: 100%;
            }
        }

    }

    &.cta_rectangle {
        @include cta_common();
        justify-content: center;
        height: 40px;
        padding: 0 15px;
        border: 1px solid $mediumGrey_Praxedo;

        .texte_cta {
            font-size: 14px;
            font-weight: 500;
            pointer-events: none;
        }

        &.cta_wide_fixe {
            //height: 50px;
            //width: 220px;
            @include responsive(40px , 10, 375px , 649, 'height');
            @include responsive(300px , -80, 375px , 649, 'width');
            justify-content: center;
            padding: 0;

            @include max($breakpoint-strate-small) {
                max-width: 100%;
            }
        }
    }

    &.cta_radius {
        @include cta_common();
        height: 25px;
        border-radius: 17px;
        padding: 0 10px;

        .texte_cta {
            text-transform: uppercase;
            font-size: 9px;
            letter-spacing: 0.07em;
            font-weight: 700;
        }

        &::after {
            border-radius: 17px;
            width: 100%;
            opacity: 0;
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }
    }

    &.cta_base {
        @include cta_common();

        .picto_cta {
            margin-right: 11px;
        }

        .texte_cta {
            font-size: 13px;
            letter-spacing: 0.07em;
            font-weight: 600;
        }
    }

    &.cta_red_with_red_arrow, &.cta_white_with_white_arrow, &.cta_blue_with_blue_arrow, &.cta_white_with_white_arrow {
        display: inline-flex;
        align-items: center;
        flex-direction: row-reverse;
        position: relative;

        .picto_cta {
            margin-left: 11px;
            margin-right: 0;
            position: relative;
            top: 2px;
            right: 0;
            transition: $transition_standard;
        }

        .texte_cta {
            font-size: 16px;
            line-height: 1.2;
            font-weight: 500;
            color: $Red_Praxedo;
        }

        &::after {
            content: '';
            width: 0%;
            height: 1px;
            background: $Red_Praxedo;
            bottom: -5px;
            left: 0;
            position: absolute;
            transition: $transition_smooth_slowly;
        }


        &:hover {
            &::after {
                width: 100%;
            }
        }

    }

    &.cta_white_with_white_arrow {
        .picto_cta svg {
            transform: none;
            transition: $transition_standard;

            path {
                fill: $white;
            }
        }

        .texte_cta {
            color: $white;
            text-decoration: underline;
        }

        &::after {
            content: none;
        }

        &:hover {
            .picto_cta svg {
                transform: translateX(5px);
            }
        }
    }

    &.cta_blue_with_blue_arrow {
        .texte_cta {
            color: $textColorFooter;
        }

        &::after {
            display: none;
        }

        &:hover {
            opacity: .5;
        }
    }

    &.cta_white_with_white_arrow {
        .texte_cta {
            color: $white;
            text-decoration: none;
        }

        &::after {
            display: none;
        }

        &:hover {
            opacity: .5;
        }
    }
    &.cta_base_footer {
        @include cta_common();

        .texte_cta {
            font-size: 14px;
            letter-spacing: -0.0875px;
            font-weight: 300;
        }
    }
}

.floating-button {
    position: fixed;
    bottom: 5%;
    right: 5%;
    z-index: 1000;
}