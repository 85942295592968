/*
comportement tablette/mobile : $breakpoint-mobile: 480px;
*/

.strate-maillage-blog-guide-cards {
    // wrapper_strate_medium : 1140px

    .inner_content_strate_maillage_blog_guide_cards {
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
    }

    .wrapper_top {
        .titre {
            @include titre-strate();
            @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile); // 30xp - 30px - 480px - 1140px
        }
    }

    .wrapper_bottom {
        @include responsive(30px, 24, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');

        .col {
            width: 100%;
            position: relative;

            > .titre {
                @include responsive(25px, 15, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                @include titre-strate();
                line-height: 35px;
                @include font-responsive(20px, 12, $breakpoint-mobile, $desktop_to_mobile);
                font-weight: 500;

                @include max($breakpoint-mobile) {
                    text-align: center;
                }
            }

            .wrapper_nav {
                position: absolute;
                top: 0;
                right: 0;

                @include max($breakpoint-mobile) {
                    display: none;
                }
            }
        }

        .col_right {
            @include responsive(40px, 36, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        }

        .wrapper_post, .wrapper_guide {

            .post {
                display: block;
                width: 340px;

                .col_image {
                    width: 100%;
                    height: 220px;
                    margin-bottom: 26px;
                }

                .col_texte .texte:hover {
                    color: $Red_Praxedo;
                }
            }

            .col_texte {
                .secteur {
                    display: inline-block;
                    border: 1px solid $Red_Praxedo;
                    border-radius: 17px;
                    margin-right: 7px;
                    text-transform: uppercase;
                    color: $Red_Praxedo;
                    transition: $transition_standard;
                    margin-bottom: 5px;
                    @include font-responsive(8px, 2, 375px, 649);
                    padding: 6px 18px;
                    @include responsive(10px, 8, $breakpoint-mobile, $desktop_to_mobile, 'padding-right');
                    @include responsive(10px, 8, $breakpoint-mobile, $desktop_to_mobile, 'padding-left');
                }

                .texte {
                    font-size: 20px;
                    line-height: 1.4em;
                    font-weight: 500;
                    margin-top: 14px;
                }

                .date {
                    font-size: 14px;
                    line-height: 1.4em;
                    font-weight: 400;
                    color: $DarkGrey_Praxedo;
                    margin-top: 8px;
                }
            }

            &.owl-carousel .owl-stage-outer {
                overflow: visible;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: 2;
                    left: calc((#{$breakpoint-strate-medium} - 100vw) / 2);
                    width: calc((100vw - #{$breakpoint-strate-medium}) / 2);
                    // petite marge de 5px pour éviter que ça déborde en haut et en bas
                    top: -5px;
                    height: calc(100% + 10px);
                    background-color: white;

                    @include max($breakpoint-medium) {
                        display: none;
                    }
                }
            }

            .owl-dots {
                display: none;
                
                @include max($breakpoint-mobile) {
                    display: flex;
                    justify-content: center;
                }

                .owl-dot {
                    background-color: #D7E1E8;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    margin: 25px 6px 0;

                    &.active {
                        background-color: $Rouge_Praxedo;
                    }
                }
            }
        }

        .wrapper_post {
            .col_image {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .wrapper_guide {
            .col_image {
                background-color: $greyPush_Praxedo;
                padding: 20px 0;

                img {
                    width: auto;
                    height: 100%;
                    margin: 0 auto;
                }
            }
        }

        .wrapper_cta {
            @include responsive(25px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        }
    }
}
