$bp_max_fr_ssgi: 780; // calc(1260 - 480);

.strate-partenaires {
  overflow: hidden;

  .inner_content_wrapper {
    margin-block: 40px;
    padding-bottom: 25px;
    position: relative;

    @include min($breakpoint-medium) {
      padding-bottom: unset;
    }
  }

  &__top {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .titre {
      @include titre-strate();
      @include font-responsive(30px, 20, $breakpoint-mobile, $bp_max_fr_ssgi); // 30xp - 30px - 480px - 1260px
      text-align: center;
      max-width: 850px;
    }
  }

  &__body {
    @include responsive(25px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
    @include min($breakpoint-medium) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 20px;
    }

    .partenaires-cards {
      box-shadow: 0 0 20px rgba(78, 78, 78, 0.1);
      border-radius: 9.55px;
      padding: 20px;
      height: fit-content;

      @include min($breakpoint-medium) {
        grid-column: auto / span 6;
      }

      @include min($breakpoint-large) {
        grid-column: auto / span 4;
      }

      @include min($breakpoint-strate-large) {
        grid-column: auto / span 3;
      }

      &__badge {
        border: 1px solid $Rouge_Praxedo2;
        border-radius: 17px;
        color: $Rouge_Praxedo2;
        display: inline-block;
        font-weight: 500;
        font-size: 10px;
        margin-block: unset;
        line-height: 11.65px;
        padding: 6px 13px;
        text-transform: uppercase;
        top: 20px;
        left: 20px;
      }
      &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 130px;
        //margin-block: 42px;

       /* &--picture {
          height: 60px;
        }*/
      }

      &__title {
        border-top: 1px solid $mediumGrey_Praxedo;
        padding-top: 20px;
        font-weight: 500;
      }

      &__description {
        margin-top: 5px;

        &--content {
          overflow: hidden;
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
          transition: $transition_standard;
        }
      }

      .wrapper-cta-more-less {
        display: none;
        margin-top: 5px;

        .more, .less {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          cursor: pointer;
          text-decoration: underline;
          background: none;
          border: none;
          padding-inline: 0;
        }
      }

      .wrapper-cta {
        margin-top: 20px;
      }
    }
  }
  .owl-carousel {
    max-width: 80vw;
    position: unset;

    @include min($breakpoint-mobile) {
      max-width: 88vw;
    }

    @include min(640px) {
      max-width: 80vw;
    }

    .owl-item img {
      width: auto;
    }

    .owl-stage-outer {
      overflow: visible;
    }

    .owl-dots {
      display: flex;
      justify-content: center;
      gap: 11px;
      position: absolute;
      width: 100%;
      bottom: 0;

      .owl-dot {
        width: 6px;
        height: 6px;
        background: $mediumGrey_Praxedo;
        border-radius: 50%;

        &.active {
          background: $Red_Praxedo;
        }
      }
    }
  }
}