$bp_max_fr_spdd: 660; // calc(1140 - 480);

.strate-push-expert {
    // wrapper_strate_medium : 1140px
    @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

    .inner_content_strate_push_expert {
        background-color: $LightGrey_Praxedo_Bkg;
    }

    .inner_content_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @include max($breakpoint-strate-small) {
            flex-direction: column-reverse;
            align-items: center;
        }

        > div {
            @include max($breakpoint-strate-small) {
                width: 100%;
            }
        }

        .col_left {
            display: flex;
            justify-content: flex-end;
            flex-shrink: 0;
            height: 100%;
            width: 460px;

            @include max($breakpoint-strate-small) {
                max-height: 360px;
            }

            .wrapper_image {
                width: 100%;
                height: auto;
                overflow: hidden;
            }

            img {
                pointer-events: none;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .col_right {
            @include responsive(38px, 70, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
            @include responsive(20px, 100, $breakpoint-mobile, $desktop_to_mobile, 'padding-left');
            padding-bottom: 155px;
            padding-right: 0;
            flex-grow: 1;

            @include max($breakpoint-strate-small) {
                @include responsive(20px, 100, $breakpoint-mobile, $desktop_to_mobile, 'padding-right');
                padding-bottom: 0;
            }

            .titre {
                @include titre-strate();
                @include font-responsive(30px, 20, $breakpoint-mobile, $bp_max_fr_spdd); // 36xp - 50px - 480px - 1140px
                @include responsive(35px, 20, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                @include max($breakpoint-strate-small) {
                    text-align: center;
                }
            }

            .texte {
                @include responsive(16px, 11, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
                @include font-responsive(14px, 6, $breakpoint-mobile, $desktop_to_mobile);
                @include responsive(20px, 8, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                font-weight: 300;
                @include max($breakpoint-strate-small) {
                    text-align: center;
                }
            }

            .wrapper_ctas {
                margin-top: 30px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                a, button {
                    margin-bottom: 15px;

                    &:first-child {
                        margin-right: 15px;
                    }
                }

                @include max($breakpoint-strate-small) {
                    justify-content: space-evenly;
                    flex-wrap: wrap;

                    a, button {
                        max-width: 300px;
                        width: 100%;

                        &:first-child {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
    }
}
