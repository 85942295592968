.strate-onglets-temoignage {
    .inner_content_strate_onglets_temoignage {
        @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        overflow: hidden;
    }

    .titre {
        @include titre-strate();
        @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile);
        @include responsive(35px, 20, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
        @include responsive(54px, 18, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        text-align: center;
    }

    .top_inner_content {
        border: 1px solid rgba(#C5D1D9, .5);
        height: 60px;
        border-radius: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
        position: relative;
        @include responsive(40px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

        @include max($breakpoint-strate-small) {
            display: none;
        }

        .selecteur_onglet {
            height: 50px;
            border-radius: 25px;
            padding: 0 40px;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .inner {
            display: table;
            width: 100%;
            margin: 0;
            padding: 0;
        }

        .onglet-animated-background {
            position: absolute;
            height: calc(100% - 10px);
            width: calc(100% - 10px);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            pointer-events: none;

            span {
                height: 100%;
                width: 33.333%;
                display: inline-block;
                background: rgba($mediumGrey_Praxedo, 0.35);
                border-radius: 30px;
                position: absolute;
                z-index: 1;
                transition: left .5s cubic-bezier(0.5, 0, 0.1, 1), width .5s cubic-bezier(0.5, 0, 0.1, 1);
                left: 0;
            }
        }
    }

    .top_inner_content_responsive {
        display: none;
        margin-bottom: 30px;
        position: relative;

        @include max($breakpoint-strate-small) {
            display: block;
        }

        select#tab_select_responsive_temoignage {
            border: 1px solid $mediumGrey_Praxedo;
            height: 40px;
            width: 100%;
            border-radius: 30px;
            outline: none;
            -webkit-appearance: none;
            font-size: 12px;
            text-transform: uppercase;
            padding: 0 20px;
            letter-spacing: 0.07em;
        }

        &::after {
            content: url($pathToSVG + 'select-arrow.svg');
            display: block;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .content_onglet {
        position: relative;
        display: none;
        justify-content: space-between;
        align-items: flex-start;

        &.active {
            display: flex;
        }

        @include max($breakpoint-strate-small) {
            flex-direction: column;
        }
    }

    .col_left_content_onglet {
        max-width: 500px;
        width: 100%;
        margin-top: 35px;

        @include max($breakpoint-strate-small) {
            max-width: 100%;
            margin-top: 0px;
        }

        .titre_onglet {
            @include font-responsive(20px, 12, $breakpoint-mobile, $desktop_to_mobile);
            @include responsive(28px, 11, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
            font-weight: 500;
            @include max($breakpoint-strate-small) {
                font-weight: 400;
            }
        }

        .texte_onglet {
            @include font-responsive(16px, 4, $breakpoint-mobile, $desktop_to_mobile);
            @include responsive(25px, 3, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
            @include responsive(20px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
            font-weight: 300;
        }

        .wrapper_cta {
            @include responsive(30px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        }
    }

    // TODO
    // Aurait-il fallu factoriser les css de .white_box ?
    $width_white_box: 502px;

    .white_box {
        position: relative;
        background-color: $white;
        box-shadow: 0px 4px 34px transparentize($color: $black, $amount: 0.91);
        z-index: 2;
        width: 100%;
        max-width: $width_white_box;
        min-height: 390px;
        //padding: 42px 40px;
        border-radius: 10px;
        overflow: hidden;

        @include responsive(30px, 12, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
        @include responsive(30px, 12, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
        @include responsive(20px, 20, $breakpoint-mobile, $desktop_to_mobile, 'padding-left');
        @include responsive(20px, 20, $breakpoint-mobile, $desktop_to_mobile, 'padding-right');

        @include max($breakpoint-strate-small) {
            position: relative;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            width: 100%;
            min-height: auto;
            left: 0;
            top: 0;
            max-width: 100%;
            margin-top: 40px;
        }

        &::after {
            position: absolute;
            display: block;
            content: '';
            width: 100%;
            height: 5px;
            background-color: $Red_Praxedo;
            bottom: 0;
            left: 0;
        }

        .row {
            &.row_top {
                display: flex;
                justify-content: flex-start;

                .secteur {
                    border: 1px solid $Red_Praxedo;
                    border-radius: 17px;
                    margin-right: 30px;

                    text-transform: uppercase;
                    color: $Red_Praxedo;
                    @include font-responsive(8px, 2, $breakpoint-mobile, $desktop_to_mobile);
                    padding: 6px 18px;
                    @include responsive(10px, 8, $breakpoint-mobile, $desktop_to_mobile, 'padding-right');
                    @include responsive(10px, 8, $breakpoint-mobile, $desktop_to_mobile, 'padding-left');

                    @include max($breakpoint-strate-small) {
                        background-color: $Red_Praxedo;
                        color: $white;
                    }
                }

                .techniciens {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .picto {
                        margin-right: 9px;

                        svg {
                            width: 10px;
                            display: flex;
                        }
                    }

                    .texte {
                        text-transform: uppercase;
                        @include font-responsive(8px, 2, $breakpoint-mobile, $desktop_to_mobile);
                    }
                }
            }

            &.row_middle {
                @include responsive(30px, 8, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
                @include responsive(30px, 8, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                @include font-responsive(16px , 2 , $breakpoint-mobile, $desktop_to_mobile);
                @include responsive(24px, 3 , $breakpoint-mobile, $desktop_to_mobile, 'line-height');
            }

            &.row_bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include responsive(30px, 8, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');

                .col_row_bottom {
                    // padding-right: 20px;
                }

                .col_row_bottom_left {
                    .wrapper_auteur {
                        display: flex;
                        align-items: center;
                    }

                    .visu_auteur {
                        width: 60px;
                        height: 60px;
                        border-radius: 10px;
                        margin-right: 10px;
                        flex-shrink: 0;
                        overflow: hidden;

                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .prenom_nom {
                        font-weight: bold;
                        @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                        @include responsive(0px, 4, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

                    }

                    .fonction {
                        font-weight: lighter;
                        @include font-responsive(12px, 2, $breakpoint-mobile, $desktop_to_mobile);

                    }

                    .wrapper_cta {
                        margin-top: 20px;

                        .cta.cta_red_with_red_arrow .texte_cta {
                            @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile); // 14 -> 16
                        }
                    }
                }

                .col_row_bottom_right {
                    display: flex;
                    justify-content: flex-end;
                    max-width: 128px;

                    img  {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
