/*
Comportement tablette / mobile => $breakpoint-medium: 768px;
*/

.strate-multi-steps {
    // wrapper_strate_medium : 1140px
    // @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
    // @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

    //.inner_content_wrapper {
    //    margin-bottom: 140px;
    //}

    .titre {
        font-weight: 500;
        @include font-responsive(24px, 8, $breakpoint-mobile, $desktop_to_mobile);
        line-height: 1.2em;
        margin-bottom: 47px;
        text-align: center;
        @include max($breakpoint-medium) {
            margin-bottom: 25px;
        }
    }

    .wrapper_etapes {
        display: flex;
        justify-content: space-evenly;
        position: relative;

        @include max($breakpoint-medium) {
            display: block;
            padding-top: 30px;
            padding-bottom: 15px;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: $Red_Praxedo;
            position: absolute;
            top: 45px;
            left: 0;
            z-index: 1;
            @include max($breakpoint-medium) {
                width: 2px;
                height: 100%;
                top: 0;
                left: 15px;
            }
        }

        .etape {
            max-width: 145px;
            position: relative;

            @include max($breakpoint-medium) {
                max-width: 100%;
                display: flex;
                margin-bottom: 40px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .etape_right {
                z-index: 2;
                display: flex;
                flex-direction: column;
                align-items: center;

                @include max($breakpoint-medium) {
                    display: block;
                }
            }

            .numero {
                color: $Red_Praxedo;
                margin-bottom: 10px;

                @include max($breakpoint-medium) {
                    margin-bottom: 2px;
                }
            }

            .mega_dot {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                border: solid 2px $Red_Praxedo;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 16px;
                position: relative;
                z-index: 2;

                &::after {
                    content: '';
                    display: block;
                    width: 9px;
                    height: 9px;
                    background-color: $Red_Praxedo;
                    border-radius: 100%;
                }

                &.desktop {
                    @include max($breakpoint-medium) {
                        display: none;
                    }
                }

                &.mobile {
                    display: none;

                    @include max($breakpoint-medium) {
                        display: flex;
                        margin-right: 10px;
                    }
                }
            }

            .texte {
                text-align: center;
                font-size: 14px;
                line-height: 1.4em;
                font-weight: 300;

                @include max($breakpoint-medium) {
                    text-align: left;
                }

                p {
                    font-weight: 300;
                }

                strong {
                    font-weight: 500;
                }
            }
        }
    }
}
