#main_footer {
    .strate_footer {
        display: flex;
        justify-content: space-between;

        @include max($breakpoint-strate-medium) {
            flex-direction: column;
        }
    }

    .footer_top {
        margin-bottom: 57px;

        @include responsive(15px , 42, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

        .footer_top_left {
            display: flex;
            flex-direction: column;
            width: 310px;
            @include max($breakpoint-strate-medium) {
                flex-direction: column;
                width: 100%;
            }

            .wrapper-logo-footer {
                margin-bottom: 27px;

                .logo-header {
                    display: block;
                }
            }

            .wrapper-language-footer {
                display: flex;
                flex-direction: column;

                // selecteur pays ou langue ?
                >a:first-of-type {
                    margin-bottom: 17px;
                    @include max($breakpoint-strate-medium) {
                        margin-bottom: 0px;
                        margin-right: 15px;
                    }
                    // pas de rollover
                    &:after{
                        content:none;
                    }
                }

                // overrule
                .cta.cta_fond_transparent .texte_cta {
                    color: $textColorFooter !important;
                }

                // specifique
                .cta .picto_cta {
                    min-width: 22px;
                    display: flex;
                }

                // WPML Language Switcher
                .language_switcher_wrapper {
                    display: flex;
                    align-items: flex-start;
                    
                    @include max($breakpoint-strate-medium) {
                        margin-top: 20px;
                    }

                    .icon {
                        &.globe {
                            height: 22px;
                            margin-right: 8px;
                        }
                    }

                    .js-language-switcher {
                        position: relative;
                        display: flex;
                        align-items: flex-start;
                        ul {
                            display: flex;
                            flex-direction: column;
                            li {
                                display: none;
                                &.active {
                                    display: flex;
                                }

                                a {
                                    font-size: 13px;
                                    color:$textColorFooter;
                                    letter-spacing: -0.0875px;
                                    font-weight: 300;
                                    line-height: 22px;
                                }
                            }
                        }

                        &.open {
                            li {
                                display: flex;
                            }
                            .icon {
                                transform: rotate(180deg);
                            }
                        }
                        .icon {
                            margin-left: 5px;
                            display: flex;
                            height: 22px;
                            align-items: center;
                        }
                    }
                    /*
                    A utiliser si passage sur le shortcode
                    .wpml-ls-legacy-dropdown {
                        display: flex;
                        width: auto;
                        .wpml-ls-sub-menu {
                            border-top-color: $textColorFooter;
                            border: none;
                            white-space: nowrap;
                        }
                        a {
                            border:none;
                            font-size: 14px;
                            letter-spacing: -0.0875px;
                            font-weight: 300;
                            color: $textColorFooter !important;
                            background: transparent;
                            padding: 3px 8px;
                            &.wpml-ls-item-toggle {
                                padding-right: 20px;
                                &:after {
                                    content: url($pathToSVG + 'arrow-down-menu.svg');
                                    border: 0;
                                    right: 0;
                                    transform: translateY(-50%);
                                }
                            }

                            span {
                                pointer-events: none;
                            }

                            &:hover {
                                background: transparent;
                            }
                        }
                    }
                    */
                }
            }
        }

        .footer_top_right {
            display: flex;
            width: calc(100% - 310px);
            @include max($breakpoint-strate-medium) {
                margin-top: 30px;
                width: 100%;
            }

            >a:first-of-type {
                margin-right: 30px;
            }

            .menu-footer-primary-container {
                width: 100%;
            }
        }
    }

    .footer_bottom {
        color: $textColorFooter;
        letter-spacing: -0.075px;
        font-size: 12px;
        font-weight: 300;
        @include responsive(15px , 42, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(30px , 40, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');

        a, button {
            color: inherit;
            text-decoration: none;
            transition: $transition_default;

            &:hover {
                color: $Red_Praxedo;
            }
        }

        .footer_bottom_left {
            display: flex;
            align-items: center;
            @include max($breakpoint-strate-medium) {
                justify-content: space-between;
            }

            .footer_rs {
                margin-left: 17px;
                display: flex;
                align-items: center;

                a, button {
                    margin-right: 10px;

                    &:hover {
                        svg {
                            path {
                                fill: $Red_Praxedo;
                            }
                        }
                    }

                    svg {
                        path {
                            fill: $textColorFooter;
                            transition: $transition_default;
                        }
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }

        .footer_bottom_right {
            display: flex;
            justify-content: flex-end;

            @include max($breakpoint-strate-medium) {
                margin-top: 15px;
            }
        }
    }
}


#menu-footer-primary {
    display: flex;
    justify-content: space-between;
    @include max($breakpoint-strate-medium) {
        flex-direction: column;
    }

    a {
        text-decoration: none;
        color: $textColorFooter;
    }


    // titre
    $marginTopTitleFooter: 30px;

    li.title {
        margin-top: $marginTopTitleFooter;
        margin-bottom: 10px;

        >a, >button {
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            @include min($breakpoint-strate-medium) {
                pointer-events: none;
            }
        }
    }

    .sub-menu {
        // a sous-item
        a, button {
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            transition: $transition_standard;

            &:hover {
                color: $Red_Praxedo;
            }
        }

        button {
            -webkit-appearance: none;
            appearance: none;
            background: transparent;
            border: none;
            display: inline;
            padding: 0;
        }

        // sous menu
        .menu-item-has-children {
            position: relative;
            @include max($breakpoint-strate-medium) {
                // padding-bottom: 15px;
                margin-top: 15px;
                border-bottom: 1px solid transparentize($mediumGrey_Praxedo, 0.5);
                transition: $transition_standard;
            }

            &::after {
                content: url($pathToSVG + 'ico-plus.svg');
                display: none;
                width: 10px;
                height: 10px;
                position: absolute;
                right: 5px;
                top: 0;
                margin-top: 5px;

                @include max($breakpoint-strate-medium) {
                    display: block;
                }
            }


            &.active {
                @include max($breakpoint-strate-medium) {
                    padding-bottom: 15px;

                    .sub-menu {
                        display: block;
                        max-height: 100%;
                    }
                }

                &::after {
                    content: url($pathToSVG + 'ico-moins.svg');
                    margin-top: 0;
                }

                > a, > button {
                    @include max($breakpoint-strate-medium) {
                        padding-bottom: 0;
                    }
                }
            }

            > a, > button {
                font-weight: 500;
                @include max($breakpoint-strate-medium) {
                    display: block;
                    padding-bottom: 15px;
                    position: relative;
                    z-index: 2;
                    cursor: pointer;
                }
            }

            .sub-menu {
                margin-top: 11px;
                margin-bottom: 30px;

                @include max($breakpoint-strate-medium) {
                    overflow: hidden;
                    margin: 0;
                    max-height: 0;
                    transition: $transition_standard;
                }
            }
        }
    }

    // li a COLONNE masqué
    >li.hidden>a, >li.hidden>button {
        height: 0px;
        opacity: 0;
        overflow: hidden;
        display: block;
    }
}

#menu-footer-secondary {
    display: flex;
    justify-content: flex-end;

    li:last-of-type {
        margin-left: 28px;
    }
}

#menu-footer-simple {
    .menu-item {
        button {
            -webkit-appearance: none;
            appearance: none;
            background: transparent;
            border: none;
            display: inline;
            padding: 0;
        }
    }
}
