$bp_max_fr_sem: 780; // calc(1260 - 480);
$gouttiere_sem: 50px;
$width_col_right_sem: 51.031746031746032%; // 643

.strate-enjeux-multiples {
    // wrapper_strate_large : 1260px

    // image : 643 x 655

    .inner_content_strate_enjeux_multiples {
        background-color: $grisClairFondHeader;
        //margin-top:130px;
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        //margin-bottom:130px;
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        //padding-top: 110px;
        @include responsive(53px, 57, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
    }

    .inner_content_wrapper {
        display: flex;
        justify-content: space-between;

        .col_left {
            width: calc(100% - #{$width_col_right_sem} - #{$gouttiere_sem});
            // background-color: bisque;

            @include max($breakpoint-strate-small) {
                width: 100%;
            }
        }

        .col_right {
            width: $width_col_right_sem;
            display: flex;
            align-items: flex-end;
            // background-color: aqua;

            @include max($breakpoint-strate-small) {
                display: none;
            }

            .wrapper_image {
                max-height: 655px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    .titre {
        @include titre-strate();
        @include font-responsive(30px, 20, $breakpoint-mobile, $bp_max_fr_sem); // 36xp - 50px - 480px - 1260px
        @include max($breakpoint-strate-small) {
            width: fit-content;

            br {
                display: none;
            }
        }
    }

    .wrapper_enjeux {
        max-width: 480px;

        @include max($breakpoint-strate-small) {
            max-width: 100%;
        }
        button.enjeux {
            width: 100%;
            .icone {
                display: flex;
                justify-content: flex-start;
            }
        }
        a.enjeux, button.enjeux {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 90px;
            text-decoration: none;
            border-bottom: 1px solid $mediumGrey_Praxedo;
            position: relative;

            &:hover {

                .texte {
                    color: $Red_Praxedo;
                }

                .arrow {
                    right: 0;
                }

            }

            .icone {
                width: 70px;
                flex-shrink: 0;
            }

            .texte {
                padding-right: 25px;
            }

            .arrow {
                position: absolute;
                right: 1%;
                top: 50%;
                transform: translateY(-50%);
                transition: $transition_standard;

                @include max($breakpoint-strate-small) {
                    right: 0;
                }
            }

            &:first-child {
                margin-top: 30px;
            }

            &:last-child {
                border-bottom: 0px;
                margin-bottom: 30px;
            }
        }
    }
}
