.strate-single-content-blog{
    position: relative;
    margin-bottom: 100px;

    & > .wrapper_content {
        max-width: 855px;
        min-height: 300px;
        box-shadow: 0px 0px 50px rgba(204, 213, 222, 0.35);
        border-radius: 10px;
        padding: 45px 60px;
        margin: 0 auto 20px;

        @include max(900px) {
            margin: 0 15px 20px;
            padding: 20px;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }
}