.strate-addons {
  background: $grisClairFondHeader;

  .inner_content_strate_fonctionnalites .fonctionnalites_liste {
    .owl-stage {
      display: flex;

      .owl-item {
        display: flex;
      }
    }

    .owl-dots {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 30px;

      .owl-dot {
        width: 5px;
        height: 5px;
        border-radius: 50px;
        background-color: $mediumGrey_Praxedo;
        margin: 0 15px;
        padding: 0;

        &.active {
          background-color: $Rouge_Praxedo;
        }
      }
    }

    .owl-nav.disabled {
      display: none;
    }

    .secteur {
      @include max($breakpoint-strate-small) {
        width: 315px;
      }
      .secteur-header {
        display: flex;
        align-items: center;
        margin-bottom: 44px;

        @include max($breakpoint-strate-small) {
          flex-wrap: wrap;
        }

        .wrapper_svg {
          margin-bottom: 0;
          margin-right: 10px;
        }

        .sur-titre {
          flex: 1;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;

          @include max($breakpoint-strate-small) {
            font-size: 12px;
          }

          .price {
            display: block;
            font-size: 25px;
            padding-top: 12px;

            @include max($breakpoint-strate-small) {
              font-size: 20px;
            }
          }
        }

        .logo {
          margin-top: 20px;
          text-align: right;
          @include min($breakpoint-strate-small) {
            margin-top: 0;
            flex: 1;
          }
        }
      }

      .texte_block {
        font-size: 16px;
      }

      .cta {
        margin-top: 20px;
      }
    }
  }
}
