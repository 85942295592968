.strate-titre-section {

    .inner_content_strate_titre_section {
        @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(70px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

    }

    .inner_content_wrapper {
        text-align: center;
        margin: auto;
        max-width: 800px;
    }

    &__titre {
        @include titre-strate();
        @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile);
    }

    &__sous-titre {
        margin: auto;
        max-width: 650px;
        @include responsive(18px, 8, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(25px, 3, $breakpoint-mobile, $desktop_to_mobile, 'line-height');

        &.light {
            font-weight: 300;
        }

        &.bold {
            font-weight: 500;
        }
    }

    p.texte {
        font-weight: 300;
        line-height: 1.4em;
        margin-top: 25px;
    }

    .cta {
        margin-top: 20px;
    }

}
