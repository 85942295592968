.strate-offres-connecteurs-cles-en-main {
    // wrapper_strate_large : 1260px
    margin: 50px 0;
    .strate_top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .strate-titre-section__titre {
            text-align: center;
        }
        .strate-titre-section__sous_titre {
            margin: 20px 0 45px;
            text-align: center;
        }
    }
    .connecteurs {
        &:not(.owl-carousel) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
      
    }
    .connecteur {
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: calc(25% - 20px);
        height: 234px;
        padding:20px 60px 30px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(78, 78, 78, 0.1);
        border-radius: 9.55px;

        @include max($breakpoint-strate-small) {
            width: 100%;
            max-width: 300px;
            margin: 0 auto;
        }

        .nom {
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            text-align: center;
        }
    }

    .owl-stage {
        display: flex;
    }

    .owl-dots {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .owl-dot {
            width: 5px;
            height: 5px;
            border-radius: 50px;
            background-color: $mediumGrey_Praxedo;
            margin: 0 15px;

            &.active {
                background-color: $Red_Praxedo;
            }
        }
    }

    .owl-stage-outer {
        overflow: visible;
    }

}

