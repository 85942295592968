/*
BP : $breakpoint-large: 992px;
*/

.strate-connexion-compte {

    .inner_content_strate_push_simple_page {
        margin-top: 70px;
    }

    .inner_content_wrapper {
        // 1260
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 54px;

        @include max($breakpoint-large){
            flex-direction: column;
            padding-top: 5px;
        }
        
        .col_gauche {
            // 540
            width: 42.857142857142857%;
            padding: 40px;
            background-color: $DarkBlue_Text;
            color: white;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include max($breakpoint-large){
                width: 100%;
                max-width: 540px;
                padding: 30px 20px 30px;
            }

            .titre {
                font-weight: 700;
                @include font-responsive(30px, 10, $breakpoint-mobile, $desktop_to_mobile);
                line-height: 1.25em;
                margin-bottom: 12px;
            }

            .sous_titre {
                text-align: center;

                @include max($breakpoint-large){
                    font-size: 16px;
                }
            }

            .wrapper_cta {
                margin-top: 25px;

                .cta.cta_rectangle.cta_wide_fixe {
                    max-width: 220px;

                    &:hover {
                        transition: $transition_default;

                        .texte_cta {
                            color: $DarkBlue_Text;
                        }
                        border-color: $DarkBlue_Text !important;
                    }
                    &::after {
                        background-color: white !important;
                    }
                }
            }

            .wrapper_image {
                margin-top: 30px;

                @include max($breakpoint-large){
                    margin-top: 40px;
                }
                picture {
                    @include max($breakpoint-large){
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    }
                }

                img {
                    @include max($breakpoint-large){
                        max-width: 90%;
                        height: auto;
                    }
                }
            }
        }

        .col_droite {
            // 600
            width: 47.619047619047619%;

            @include max($breakpoint-large){
                width: 100%;
                max-width: 540px;
                margin-top: 40px;
            }

            .titre_colonne_droite {
                font-weight: 500;
                @include font-responsive(20px, 5, $breakpoint-mobile, $desktop_to_mobile);
                line-height: 1.25em;
                margin-bottom: 36px;
            }

            .wrapper_item {
                display: flex;
                padding-bottom: 26px;
                margin-bottom: 26px;
                border-bottom: solid 1px #C5D1D9;

                &:last-of-type {
                    margin-bottom: 0;
                    border-bottom:none;

                    @include max($breakpoint-large){
                        padding-bottom: 0;
                    }
                }

                .picto_item {
                    width: 42px;
                    flex-shrink: 0;
                }

                .content_item {
                    .titre_item {
                        font-size: 18px;
                        line-height: 1.25em;
                        font-weight: 500;
                        margin-bottom: 8px;
                    }

                    .descriptif_item {
                        font-size: 16px;
                        line-height: 1.25em;
                        font-weight: 300;
                        margin-bottom: 8px;
                    }
                }
            }
        }

    }
}

// bg_push_simple_page
