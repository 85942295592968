.strate-2-cols-textes-separes {

    .inner_content_strate_2_cols_textes_separes {
        @include responsive(70px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(50px, 40, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
    }

    .inner_content_wrapper {
        @include responsive(0px, 90, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
        position: relative;

        &.separation {
            border-bottom: 1px solid $mediumGrey_Praxedo;
        }
    }

    // largeur strate = 1140

    .titre {
        @include titre-strate();
        @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile);
        text-align: center;
        margin-bottom: 40px;
    }

    .wrapper_blocks {
        display: flex;
        justify-content: space-between;
        position: relative;

        @include max($breakpoint-strate-medium) {
            flex-direction: column;
        }

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            width: 1px;
            background-color: transparentize($color: $mediumGrey_Praxedo, $amount: 0.2);

            @include max($breakpoint-strate-medium) {
                display: none;
            }
        }

        .block {
            width: 100%;
            max-width: 500px;

            @include max($breakpoint-strate-medium) {
                max-width: 100%;

                &:first-child {
                    border-bottom: 1px solid transparentize($color: $mediumGrey_Praxedo, $amount: 0.2);
                }

                &:last-child {
                    margin-top: 20px;
                }
            }

            .titre_block {
                font-size: 32px;
                margin-bottom: 10px;
                color: $Red_Praxedo;
            }

            .texte_block {
                margin-top: 30px;

                @include max($breakpoint-strate-medium) {
                    margin-top: 20px;
                }

                strong {
                    font-weight: 500;
                }

                p {
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 28px;
                    @include responsive(15px, 15, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                }

                ul {
                    li {
                        font-weight: 300;
                        font-size: 20px;
                        margin-bottom: 15px;
                        position: relative;
                        padding-left: 20px;

                        &::before {
                            content: '';
                            background: $Rouge_Praxedo;
                            position: absolute;
                            border-radius: 50px;
                            width: 7px;
                            height: 7px;
                            left: 0px;
                            top: 8px;
                        }
                    }
                }
            }
        }
    }
}
