.strate-2-col-titre-texte {

    @include max($breakpoint-strate-small) {
        br {
            display: none;
        }
    }

    .inner_content_strate_2_col_titre_texte {
        background-color: $grisClairFondHeader;
        @include responsive(70px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(70px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        @include responsive(40px, 40, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
        @include responsive(40px, 40, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
    }

    .inner_content_wrapper {
        display: flex;
        justify-content: space-between;

        @include max($breakpoint-strate-small) {
            flex-direction: column;
            align-items: center;
        }
    }

    .wrapper_titre {
        max-width: 400px;

        @include max($breakpoint-strate-small) {
            max-width: 550px;
        }

        .titre {
            @include titre-strate();
            @include font-responsive(32px, 18, $breakpoint-mobile, $desktop_to_mobile);
            @include max($breakpoint-strate-small) {
                text-align: center;

            }
        }
    }

    .wrapper_texte {
        max-width: 660px;

        @include max($breakpoint-strate-small) {
            margin-top: 40px;
            max-width: 100%;
        }

        strong {
            font-weight: 500;
        }

        p {
            font-weight: 300;
            font-size: 20px;
            @include responsive(15px, 15, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
            line-height: 28px;


        }

        ul {
            li {
                font-weight: 300;
                font-size: 20px;
                margin-bottom: 15px;
                position: relative;
                padding-left: 20px;
                line-height: 28px;


                &::before {
                    content: '';
                    background: $Rouge_Praxedo;
                    position: absolute;
                    border-radius: 50px;
                    width: 7px;
                    height: 7px;
                    left: 0px;
                    top: 8px;
                }
            }
        }
    }

}
