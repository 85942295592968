$bp_max_fr_spdd: 660; // calc(1140 - 480);

.strate-push-demo-devis {
    // wrapper_strate_medium : 1140px
    @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
    @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

    .inner_content_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        min-height: 480px;

        @include max($breakpoint-strate-small) {
            flex-direction: column;
            align-items: center;
        }

        > div {
            width: 50%;
            @include max($breakpoint-strate-small) {
                width: 100%;
            }
        }

        .col_left {
            @include responsive(38px, 62, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
            @include responsive(20px, 100, $breakpoint-mobile, $desktop_to_mobile, 'padding-left');
            @include responsive(38px, 70, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
            padding-right: 0;

            @include max($breakpoint-strate-small) {
                @include responsive(20px, 100, $breakpoint-mobile, $desktop_to_mobile, 'padding-right');
                padding-bottom: 0;
            }

            .texte {
                @include responsive(16px, 11, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
                @include font-responsive(18px, 4, $breakpoint-mobile, $desktop_to_mobile);
                @include max($breakpoint-strate-small) {
                    text-align: center;
                }
            }

            .wrapper_ctas {
                @include responsive(13px, 23, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
                display: flex;
                justify-content: flex-start;
                align-items: center;

                a, button {
                    margin-bottom: 15px;

                    &:first-child {
                        margin-right: 15px;
                    }
                }

                @include max($breakpoint-strate-small) {
                    justify-content: space-evenly;
                    flex-wrap: wrap;

                    a, button {
                        max-width: 300px;
                        width: 100%;

                        &:first-child {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }

        .col_right {
            display: flex;
            justify-content: flex-end;
            height: 100%;
            @include responsive(236px, 200, $breakpoint-mobile, $desktop_to_mobile, 'height');

            .wrapper_image {
                width: 100%;
                height: 100%;
                overflow: hidden;
            }

            img {
                pointer-events: none;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: right bottom;
            }
        }
    }

    .wrapper_strate_large {
        background-color: $greyPush_Praxedo;
        border-radius: 10px;

        @include max($breakpoint-strate-small) {
            margin: 0;
        }
    }

    .titre {
        @include titre-strate();
        @include font-responsive(36px, 14, $breakpoint-mobile, $bp_max_fr_spdd); // 36xp - 50px - 480px - 1140px
        @include max($breakpoint-strate-small) {
            text-align: center;
        }
    }

}
