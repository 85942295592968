.strate-colorful-round-logos {
    .inner_content_strate_colorful_round_logos {
        @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(70px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
    }

    .inner_content_wrapper {
        max-width: 930px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .titre {
        @include titre-strate();
        @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile);
        text-align: center;
    }

    .sous_titre {
        max-width: 660px;
        text-align: center;
        margin-top: 20px;
    }

    .wrapper_logos {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        .logo {
            flex: 2;
            margin: 0 20px;
            text-align: center;

            @include max($breakpoint-strate-medium) {
                margin-bottom: 25px;
            }

            .and_more {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                background: $Rouge_Praxedo;
                width: 80px;
                height: 80px;
                text-transform: uppercase;
                color:#FFF;
                font-size:12px;
                font-weight: 400;
            }
        }
        
    }

    .wrapper_cta {
        margin-top: 50px;

        @include max($breakpoint-strate-medium) {
            margin-top: 30px;
        }
    }
}
