$bp_max_fr_sst: 660;

.strate-slider-temoignages.nouveau-slider-temoignages {
  .inner_content_strate_slider_temoignages {
   margin-top: unset;
    margin-bottom: unset;

    &.bg_white {
      @include responsive(50px, 50, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
      @include responsive(60px, 50, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
    }
  }

  .bottom_slider {
    &::before {
      right: 101%;
    }
  }

  .slider {
    @include min($breakpoint-mobile) {
      max-width: 85vw;
    }

    @include min($breakpoint-strate-small) {
      max-width: 90vw;
    }

    @include min($breakpoint-header-main) {
      max-width: unset;
    }

    .slide_temoignage {
      position: relative;
      width: 100%;
      height: 100%;

      @include max($breakpoint-strate-small) {
        box-shadow: 0px 0px 56.3973px rgba(194, 194, 194, 0.25);
        border-radius: 11px;
      }

      .white_box {
        position: relative;
        background-color: $white;
        box-shadow: 0 0 20px rgba(78, 78, 78, 0.1);
        z-index: 2;
        height: 100%;
        padding: 20px;
        border-radius: 10px;
        width: 100%;
        overflow: hidden;
        left: 0;
        top: 0;

        .new_slider_temoignage_cta {
          margin-top: 11px;
        }

        .video_embed {
          display: none;
        }

        .slide_temoignage_cta {
          display: flex;
          justify-content: flex-start;
          margin-top: 10px;
        }

        &.no-image {
          padding-block: 30px;
        }

        .box-image-content {
          position: relative;
          border-radius: 10px;
          background-color: #FAFAFC;
          width: 100%;
          overflow: hidden;
          margin-bottom: 20px;
          height: 200px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .logo-company {
            max-width: 100px;
            width: 100px;
            height: 50px;
            border-radius: 10px;
            position: absolute;
            top: 20px;
            left: 20px;
            box-shadow: 0px 0px 20px rgba(78,78,78,0.19);
  
            img {
                border-radius: 10px;
                background: $white;
                object-fit: contain;
                padding: 3px;
            }
          }
        }

        .row {

          &.row_bottom {

            .col_row_bottom {
              width: unset;
            }
          }
        }

      }

      .wrapper_player_video {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
      }
      
      .wrapper_player_video.new_slider {
        position: relative;
        z-index: 2;
        top: auto;
        left: auto;
        transform: none;
        width: auto;
        height: auto;

        .js_player_video_popin {
          cursor: pointer;
          position: absolute;
          bottom: -20px;
          right: -15px;
          transform: translate(-50%, -50%);
          width: 57px;
          height: 57px;
          
          svg {
              width: 100%;
              height: 100%;
  
              // circle {
              //     fill: $LightGrey_Praxedo_Bkg;
              // }
              // path {
              //     fill: #EE313B;
              // }
          }
        }
      } 
    }
  }

  .owl-carousel {
    position: unset;
  }

  .owl-dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}