$bp_max_fr_smbb: 660; // calc(1440 - 480);

.strate-maillage-blog-guide {
    // wrapper_strate_medium : 1140px

    .inner_content_strate_maillage_blog_guide {
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
    }

    .wrapper_top {
        .titre {
            @include titre-strate();
            @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile); // 30xp - 30px - 480px - 1140px
        }
    }

    .wrapper_bottom {
        @include responsive(30px, 30, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');

        display: flex;
        justify-content: space-between;

        @include max($breakpoint-strate-medium) {
            flex-direction: column;
        }

        .col {

            width: 100%;
            max-width: 530px;

            @include max($breakpoint-strate-medium) {
                max-width: 100%;
            }


            > .titre {
                @include responsive(20px, 17, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                @include titre-strate();
                @include font-responsive(20px, 12, $breakpoint-mobile, $desktop_to_mobile);
                font-weight: 500;
            }
        }

        .col_right {
            @include max($breakpoint-strate-medium) {
                margin-top: 50px;
            }
        }

        .wrapper_post, .wrapper_guide {

            .post {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                @include max($breakpoint-strate-medium) {
                    flex-direction: column;
                }
                //@include max($breakpoint-strate-medium) {
                //    width: 50%;
                //}

                &:last-child {
                    margin-top: 50px;
                    //@include max($breakpoint-strate-medium) {
                    //    display: none;
                    //}
                }

                .col_texte .texte:hover {
                    color: $Red_Praxedo;
                }
            }

            .col_texte {
                max-width: 306px;

                @include max($breakpoint-strate-medium) {
                    max-width: 100%;
                    width: 100%;
                }

                .secteur {
                    display: inline-block;
                    border: 1px solid $Red_Praxedo;
                    border-radius: 17px;
                    margin-right: 7px;
                    text-transform: uppercase;
                    color: $Red_Praxedo;
                    transition: $transition_standard;
                    margin-bottom: 5px;
                    @include font-responsive(8px, 2, 375px, 649);
                    padding: 6px 18px;
                    @include responsive(10px, 8, $breakpoint-mobile, $desktop_to_mobile, 'padding-right');
                    @include responsive(10px, 8, $breakpoint-mobile, $desktop_to_mobile, 'padding-left');
                    @include max($breakpoint-strate-medium) {
                        background-color: $Red_Praxedo;
                        color: $white;
                        margin-top: 26px;
                    }

                    &:hover {
                        background-color: $Red_Praxedo;
                        color: $white;
                    }
                }

                .texte {
                    @include responsive(13px, 2, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
                    font-weight: 500;

                    @include max($breakpoint-strate-medium) {
                        padding-bottom: 25px;
                        border-bottom: 1px solid $mediumGrey_Praxedo;
                    }
                }

                .date {
                    @include responsive(15px, -6, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
                    @include font-responsive(14px, 0 , $breakpoint-mobile, $desktop_to_mobile);
                    color: $Gris_Praxedo;

                }
            }
        }

        .wrapper_post {
            .col_image {
                width: 180px;
                height: 180px;
                margin-right: 50px;

                @include max($breakpoint-strate-medium) {
                    margin: 0 auto;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .wrapper_guide {
            .col_image {
                width: 130px;
                height: 180px;
                margin-right: 45px;
                @include max($breakpoint-strate-medium) {
                    margin: 0 auto;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .wrapper_cta {
            @include responsive(20px, 4, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        }
    }
}
