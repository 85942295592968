.banner-news {
  position: fixed;
  align-items: center;
  justify-content: center;
  gap: 35px;
  background-color: $DarkBlue_Text;
  padding-block: 12px;
  padding-inline-start: 15px;
  padding-inline-end: 45px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1000;
  display: none;

  &.enabled {
    display: none;

    @include min($breakpoint-medium) {
      display: flex;
    }
  }

  &__text {
    color: $white;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 23px;

    &--title {
      &::after {
        content: '';
        display: block;
        position: absolute;
        background-color: $white;
        width: 1px;
        height: 29px;
        bottom: 0;
        right: 0;
        opacity: .2;
      }
    }

    &--title {
      font-size: 16px;
      padding-inline-end: 23px;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 25px;
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;

      & > * {
        display: inline-block;
      }

      .icon-wrapper {
        width: 18px;
        height: 18px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &--desc {
      @include font-responsive(16px, 6, $breakpoint-medium, $desktop_to_medium);
      @include responsive(20px, 8, $breakpoint-medium, $desktop_to_medium, 'line-height');
      font-weight: 300;
    }
  }

  &__cta {
    display: flex;
    flex-shrink: 0;
  }

  &__close {
    position: absolute;
    z-index: 1001;
    right: 5px;
    top: unset;
    padding: unset;

    @include min($breakpoint-strate-large) {
      right: 20px;
    }

    svg {
      width: 35px;
      height: 35px;
      transform: none;
      transition: all .2s ease;
    }

    &:hover svg {
      transform: scale(1.2, 1.2);
    }
  }
  
  &__text, &__cta {
    position: relative;
    z-index: 2;
  }

  .background-illustration {
    position: absolute;
    top: 0;
    left: auto;
    max-width: $breakpoint-header-main;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}