$bp_max_fr_se3e: 660; // calc(1440 - 480);


.strate-equipe-3-etapes {
    // wrapper_strate_medium : 1140px

    .inner_content_strate_equipe_3_etapes {

        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        @include responsive(50px, 40, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
        @include responsive(60px, 50, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
        background-color: $grisClairFondHeader;


    }

    // .inner_content_wrapper{
    // }

    .wrapper_top {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .titre {
        @include titre-strate();
        @include font-responsive(30px, 20, $breakpoint-mobile, $bp_max_fr_ssgi); // 30xp - 30px - 480px - 1260px
        text-align: center;
        max-width: 600px;
    }

    .wrapper_lien_top {
        a, button {
            // text-decoration: none;
            // color: $Red_Praxedo;
            // display: inline-flex;
            // justify-content: flex-start;
            // align-items: flex-end;
            margin-top: 20px;

            .text_lien {
                margin-right: 7px;
                font-size: 16px;
            }

        }
    }

    .wrapper_bottom {
        display: flex;
        justify-content: space-between;
        @include responsive(37px, 23, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include max($breakpoint-strate-small) {
            display: block;
        }

        .wrapper_etape {
            max-width: 340px;
            width: 100%;
            border-bottom: 5px solid $Red_Praxedo;
            padding-bottom: 30px;

            @include max($breakpoint-strate-small) {
                max-width: 100%;
                padding-bottom: 50px;
                min-height: 450px;
                border-bottom: 0px;

            }
        }

        .wrapper-image_etape {
            width: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .step {
            @include font-responsive(12px, 4, $breakpoint-mobile, $desktop_to_mobile);
            color: $Red_Praxedo;
            font-weight: 500;
            margin-top: 22px;
        }

        .titre_etape {
            @include responsive(2px, 2, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
            @include font-responsive(20px , 10, $breakpoint-mobile, $desktop_to_mobile);
            font-weight: 500;
        }

        .texte_etape {
            margin-top: 14px;
            font-weight: 300;
            @include font-responsive(16px, 2, $breakpoint-mobile, $desktop_to_mobile);
        }
    }

    .owl-stage-outer {
        @include max($breakpoint-strate-small) {
            border-bottom: 5px solid $Red_Praxedo;
        }
    }

    .owl-stage {
        display: flex;
    }

    .owl-dots {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .owl-dot {
            width: 5px;
            height: 5px;
            border-radius: 50px;
            background-color: $mediumGrey_Praxedo;
            margin: 0 15px;

            &.active {
                background-color: $Red_Praxedo;
            }
        }
    }
}
