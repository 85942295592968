$bp_max_fr_srcp: 660; // calc(1140 - 480);

.strate-ref-clients-praxedo {
    // wrapper_strate_medium : 1140px

    .inner_content_strate_ref_clients_praxedo {
        // margin-top:130px;
        // margin-bottom:130px;

        @include responsive(70px, 60, 375px, 649, 'margin-top');
        @include responsive(15px, 15, 375px, 649, 'margin-bottom');

    }

    .inner_content_wrapper {
        text-align: center;
    }

    .titre {
        @include font-responsive(20px, 12, $breakpoint-mobile, $bp_max_fr_srcp); // 30xp - 30px - 480px - 1140px
    }

    .wrapper_logos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include responsive(26px, 40, 375px, 649, 'margin-top');

        @include max($breakpoint-strate-small) {
            flex-wrap: wrap;

            .wrapper_logo {
                flex: 50%;
                // margin: 0 15px;
                margin-bottom: 31px;
            }
        }
    }

    .wrapper_cta {
        @include responsive(35px, 25, 375px, 649, 'margin-top');
        @include max($breakpoint-strate-small) {
            margin-top: 0;
        }

        a, button {
            @include responsive(300px, -80, 375px, 649, 'width');
        }
    }



}

