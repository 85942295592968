// MIXIN RESPONSIVE
@mixin min($min) {
    @media (min-width: $min) {
        @content;
    }
}

@mixin max($max) {
    @media (max-width: $max) {
        @content;
    }
}

@mixin between($min, $max) {
    @media (min-width: $min) and (max-width: $max) {
        @content;
    }
}


// défintion des wrappers
@mixin wrapper($breakpoint) {
    max-width: $breakpoint;
    margin: 0 auto;

    @include max(calc(#{$breakpoint} + (#{$marge-mini}*2))) {
        margin: 0 $marge-mini;
    }
    @content;
}



// Typo responsive
// https://gist.github.com/ben-w-smith/078e81015805832ef5b1
@mixin font-responsive($fmin, $fdiff, $breakmin, $breakdiff) {
    font-size: calc(#{$fmin} + #{$fdiff} * ((100vw - #{$breakmin}) / #{$breakdiff}));
    @media(max-width: $breakmin) {
        font-size: $fmin;
    }
    @media(min-width: round($breakmin + $breakdiff)) {
        font-size: round($fmin + $fdiff);
    }
}


/*
Propriété responsive
USAGE :
In this example the font size will fluctuate between 30px - 36px between 420px and 768px
h1 {
  @include font-responsive(30px, 6, 420px, 348);
}
*/

@mixin responsive ($min, $diff, $breakmin, $breakdiff, $prop) {
    #{$prop}: calc(#{$min} + #{$diff} * ((100vw - #{$breakmin}) / #{$breakdiff}));
    @media(max-width: $breakmin) {
        #{$prop}: $min;
    }
    @media(min-width: round($breakmin + $breakdiff)) {
        #{$prop}: round($min + $diff);
    }
}

// Titrages et textes courant
@mixin titre-strate() {
    font-weight: 700;
    color: $DarkBlue_Text;
    @include font-responsive(30px, 20, $breakpoint-mobile, $desktop_to_mobile);
    @include responsive(35px, 20, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
}

@mixin titre-header() {
    font-weight: 700;
    color: $DarkBlue_Text;
    @include font-responsive(45px, 20, $breakpoint-mobile, $desktop_to_mobile);
    @include responsive(45px, 25, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
    text-align: center;
}

@mixin texte-standard() {
    color: $DarkBlue_Text;
    @include font-responsive(14px, 6, $breakpoint-mobile, $desktop_to_mobile);
    @include responsive(20px, 8, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
    font-weight: 300;

}

// Ajout de variables CSS
// Si on essai de déclarer une variable CSS comme ceci : `--ma-var: $maVarSass;`, le linter retourne une erreur.
// En utilisant `@include root-prop('--ma-var', $maVarSass);`, plus de soucis.
@mixin root-prop($prop: null, $value: null) {
    @if ($prop and $value) {
        #{$prop}: $value;
    }
}

// utilisé dans _strate-archive-blog.scss et _strate-maillage-autres-temoignages.scss
@mixin badge() {
    text-transform: uppercase;
    font-size: 8px;
    line-height: 1;
    color: $Rouge_Praxedo2;
    display: inline-flex;
    align-items: center;
    height: 20px;
    padding: 0 10px;
    border: 1px solid $Rouge_Praxedo2;
    border-radius: 10px;
    font-weight: 500;
    margin-bottom: 24px;
}


@mixin player_video() {
    position: absolute;
    // top: 50%;
    // left: 50%;
    bottom: -20px;
    right: -15px;
    transform: translate(-50%, -50%);
    width: 57px;
    height: 57px;

    svg {
        width: 100%;
        height: 100%;
        filter: drop-shadow(0px 0px 20px rgba(78, 78, 78, 0.18));

        // circle {
        //     fill: $LightGrey_Praxedo_Bkg;
        // }
        // path {
        //     fill: #EE313B;
        // }
    }
}
