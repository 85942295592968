/*
Comportement tablette et mobile : $breakpoint-large: 992px;
largeur strate = 1260
*/


.strate-2-cols-textes-scrollables {

    .inner_content_wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        @include responsive(45px, 85, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');

        /*
        impératif pour faire fonctionner le positionneemnt sticky de .col_left
        car la valeur par défaut est align-items: stretch ce qui donne une hauteur de 100% à la colonne
        */
        align-items: flex-start;

        @include max($breakpoint-large) {
            display: block;
        }
    }



    .col_left {
        // 535
        width: 42.46031746031746%;
        position: sticky;
        top: 90px;
        padding-bottom: 35px;

        @include max($breakpoint-large) {
            width: 100%;
            position: relative;
            top: 0;
        }

        .titre {

            @include titre-strate();
            margin-bottom: 30px;

            @include max($breakpoint-large) {
                text-align: center;
                margin-bottom: 25px;
            }
        }

        .texte {
            @include texte-standard();
            margin-bottom: 30px;
            @include max($breakpoint-large) {
                text-align: center;
            }
        }

        .wrapper_cta {
            @include max($breakpoint-large) {
                text-align: center;
            }
        }
    }

    .col_right {
        // 540
        width: 42.857142857142857%;

        @include max($breakpoint-large) {
            width: 100%;
            // padding-top: 45px;
            overflow-y: unset;
            padding-bottom: 25px;
        }

        .block {
            display: flex;
            padding-bottom: 35px;
            margin-bottom: 35px;
            border-bottom: solid 1px $mediumGrey_Praxedo;

            &:last-of-type {
                margin-bottom: 0;
                border-bottom: 0;
            }

            .side_left {
                width: 74px;
                padding-left: 12px;
                flex-shrink: 0;
            }

            .titre_block {
                margin-bottom: 10px;
            }

            .texte_block {
                @include font-responsive(14px, 4, $breakpoint-mobile, $desktop_to_mobile);
                @include responsive(20px, 5, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                font-weight: 300;
            }
        }
    }

}
