$bp_max_fr_ecl: 780; // calc(1260 - 480);

.strate-secteurs-liste {
    // wrapper_strate_large : 1260px

    .inner_content_strate_secteurs_header {
        background-color: $grisClairFondHeader;

        .inner_content_wrapper {
            @include responsive(40px, 20, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
            @include responsive(130px, 220, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
        }

        .wrapper_breadcrumb {
            text-align: center;
            margin-bottom: 28px;
        }

        .titre {
            @include titre-header();
            margin-bottom: 35px;
        }

        .sous_titre {
            text-align: center;
            @include font-responsive(14px, 6, $breakpoint-mobile, $desktop_to_mobile);
            @include responsive(20px, 8, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
            font-weight: 300;
            max-width: 738px;
            margin: 0 auto;
            margin-top: 35px;
            line-height: 1.4;
        }
    }

    .inner_content_strate_secteurs_liste {
        @include responsive(-65px, -165, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        @include responsive(0px, 60, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

        .secteurs_liste {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @include max($breakpoint-strate-large) {
                justify-content: space-around;
            }


            .secteur {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 400px;
                height: 430px;
                background-color: $white;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
                border-radius: 10px;
                overflow: hidden;
                position: relative;
                padding: 37px 37px 60px;
                @include responsive(30px, 40, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
                @include responsive(30px, 40, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

                @include max($breakpoint-large) {
                    width: calc(50% - (14px * 2));
                }

                @include max($breakpoint-medium) {
                    width: 100%;
                }

                &.fake {
                    height: 0;
                    opacity: 0;
                    padding: 0;
                    margin: 0;
                    overflow: hidden;
                }

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 5px;
                    background-color: $Red_Praxedo;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                .wrapper_svg {
                    height: 42px; // pour forcer l'alignement vertical quel que soit la hauteur du svg (max 40)
                    margin-bottom: 32px;

                    &.bigger {
                        height: 65px;
                    }
                }

                .titre_block {
                    @include font-responsive(20px, 12, $breakpoint-mobile, $desktop_to_mobile);
                    @include responsive(28px, 10, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                    @include responsive(28px, -10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                    min-height: 75px;
                }

                .texte_block {
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 1.4;
                    margin-bottom: 65px;
                    // @include responsive(40px, 25, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}

