.strate-push-content {

    &:not(:first-child) {
        @include responsive(40px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
    }

    &:not(:last-child) {
        @include responsive(40px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
    }

    .push_content {
        background: $greyPush_Praxedo;
        border-radius: 10px;
        @include responsive(20px, 15, $breakpoint-mobile, $desktop_to_mobile, 'padding');
        display: flex;

        @include max($breakpoint-medium) {
            flex-direction: column;
        }

        & > .wrapper_image {
            width: 240px;
            height: auto;
            flex-shrink: 0;
            margin-right: 45px;

            @include max($breakpoint-medium) {
                margin: 0 auto 20px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        & > .wrapper_content {
            flex-grow: 1;

            .titre {
                margin-bottom: 15px;
            }
        }
    }
}
