$bp_max_fr_scc: 540; // calc(1020 - 480);

.strate-chiffres-cles {
    // wrapper_strate_small : 1020px

    .inner_content_strate_chiffres_cles {
        //margin-top:200px;
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        //margin-bottom:130px;
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

        &.sup_margin_top {
            margin-top: 0;
        }

        &.sup_margin_bottom {
            margin-bottom: 0;
        }
    }

    .inner_content_wrapper {

        .titre {
            @include titre-strate();
            @include font-responsive(36px, 14, $breakpoint-mobile, $bp_max_fr_scc); // 36xp - 50px - 480px - 1020px
            text-align: center;
            margin-bottom: 60px;
        }
    }

    .chiffres {
        display: flex;
        justify-content: space-between;
        @include max($breakpoint-strate-small) {
            flex-wrap: wrap;
            margin-bottom: 0;
        }

        .col {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include max($breakpoint-strate-small) {
                flex: 100%;
                margin-bottom: 35px;
            }

            .top {
                @include font-responsive(35px, 0, $breakpoint-mobile, $bp_max_fr_scc); // 34xp - 35px - 480px - 1020px
                color: $Red_Praxedo;
                line-height: 1.16;
            }

            .bottom {
                @include font-responsive(20px, 0, $breakpoint-mobile, $bp_max_fr_scc); // 34xp - 35px - 480px - 1020px
                font-weight: 300;
                line-height: 1.4;
            }
        }
    }
}
