/*
Comportement tablette/mobile : $breakpoint-large: 992px;
Pour être homogène avec la strate du dessus : strate-notre-equipe
*/

.strate-image-full-width {

    .inner_content_strate_image_full_width {
        // @include responsive(70px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
        // @include responsive(70px, 20, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        margin-top: 140px;
        margin-bottom: 157px;
        @include max($breakpoint-large) {
            margin-top: 0;
        }
    }

    .inner_content_wrapper {
        width: 100%;

        position: relative;

        // la série de personnages
        &._800 {
            padding-bottom: 41.666666666666667%;
            @include max($breakpoint-large) {
                // 375 x 340
                padding-bottom: 90.666666666666667%;
            }
        }

        // la carte du monde
        &._1060 {
            padding-bottom: 55.208333333333333%;
        }

        .wrapper_image {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
}


