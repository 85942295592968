/*
// TODO

1/  Marc le 14/05 => j'ai tout simplement dupliqué __header-main.scss => est-il utile de factoriser certaines parties du code
    entre ces 2 feuilles de style ?

2/  Marc le 14/05 => Ce template nécessite 2 images à droite, j'ai moi-même retaillé l'image de premier plan (L'écran avec la carte)
    Pour un positionnement parfait, il faudrait demander à Nolwen de le faire au pixel pres,
    attention le template comprend une gouttière mais l'ombre de droite qui parait énorme semble déborder sur celle-ci.

3/  Marc le 14/05 => j'ai été obligé de créer une version mobile de l'image de premier plan car elle ne comporte pas les mêmes ombres
    en version desktop et mobile !
*/


.header-decentre-process-metier {
    .inner_content_header_decentre_process_metier {
        background-color: $grisClairFondHeader;
        overflow: hidden;
        //margin-bottom: 120px;
        @include responsive(70px, 50, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
        @include max($breakpoint-strate-small) {
            padding-top: 35px;
        }
    }

    // .wrapper_header_main{
    //     // position: relative;
    // }
    .inner_content_wrapper {
        // 1440 x 715
        // padding-bottom: 49.652777777777778%;
        padding-bottom: calc(100vh - #{$hauteur_nav});
        position: relative;
        width: 100%;
        @include max($breakpoint-strate-small) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-bottom: 0;
        }
    }

    .col {
        height: 100%;
        position: absolute;
        top: 0;
        @include max($breakpoint-strate-small) {
            position: unset;
        }
    }

    $largeur_col_left: 45.694444444444444%;

    .col_left {
        // padding-left: 148px : 10,277777777777778%
        // gouttiere : 50px : 3,472222222222222%
        left: 10.27%;
        width: calc(100% - #{$largeur_col_left} - 3.472% - 10.27%);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .picto_single_besoins {
            margin-bottom: 26px;
            @include max($breakpoint-strate-small) {
                text-align: center;
            }
        }

        .titre {
            @include titre-strate();
            @include font-responsive(45px, 20, $breakpoint-mobile, 960); // 45 - 65
            @include responsive(45px, 30, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
            // @include font-responsive(36px, 44, $breakpoint-mobile, 960);
            /*
            (36px, 44, 480px, 960)
            font mini : 36px
            font maxi : 80px (36 + 44)
            largeur min : 480px ($breakpoint-mobile)
            largeur max : 1440px (480 + 960)
            */
            line-height: 0.9375;
            // width: 584
            // padding-bottom: 37px;
            padding-bottom: 6.335616438356164%;

            /*
            Marc le 15/06
            Le contenu de gauche (titre, texte, cta, etc.) est parfois trop grand sur petit écran 13 pouces : breadcrumb et CTAs ne s'affichent plus entièrement
            */
            @media (max-height: 820px) {
                @include responsive(45px, 15, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
            }
        }

        .sous_titre {
            @include font-responsive(14px, 4, $breakpoint-mobile, 960);
            line-height: 1.4;
            font-weight: 400;
        }

        .titre, .sous_titre {
            @include max($breakpoint-strate-small) {
                text-align: center;
            }
        }

        .wrapper_ctas {
            // width: 584
            // padding-top:44
            //padding-top:7.534246575342466%;

            @include responsive(22px, 22, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
            display: flex;
            @include max($breakpoint-strate-small) {
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            .cta_wide_fixe {
                // width: 220
                //width: 37.671232876712329%!important;
                min-width: 155px;

                &:first-of-type {
                    margin-right: 15px;

                    @include max($breakpoint-strate-small) {
                        margin-right: 0;
                        margin-bottom: 15px;
                    }
                }

                &.cta_fond_blanc {
                    .texte_cta {
                        color: $Rouge_Praxedo;
                    }
                }
            }
        }
    }

    .col_right {
        right: 0;
        // 658 * 715
        width: $largeur_col_left;
        // l'image est taille réelle, calée en bas à droite

        .wrapper_image {
            width: 100%;
            height: 100%;
            // overflow: hidden;
            // padding-top: 30px;
            //padding-top: 4.195804195804196%;
            @include responsive(40px , -10 , $breakpoint-mobile, $desktop_to_mobile, 'padding-top');

            display: flex;
            align-items: center;

            // cible img ET picture à cause du plugin qui transforme img en picture.
            img, picture {
                pointer-events: none;
                position: relative;
                z-index: 2;

                &.foreground.desktop {
                    @include max($breakpoint-strate-small) {
                        display: none;
                    }
                }

                &.foreground.mobile {
                    display: none;
                    @include max($breakpoint-strate-small) {
                        display: block;
                        max-width: 100%;
                        height: auto;
                        img{
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }

                &.background {
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    @include max($breakpoint-strate-small) {
                        display: none;
                    }
                }
            }

            @include max($breakpoint-strate-small) {
                display: flex;
                justify-content: center;
            }
        }
    }

    .col_left, .col_right {
        @include max($breakpoint-strate-small) {
            width: 100%;
        }
    }
}
