/*
comportement tablette/mobile : $breakpoint-strate-large -> 1260
*/

.strate-happy-at-work {
    .inner_content_strate_happy_at_work {
        // 1140
        background-color: $grisClairFondHeader;
        @include responsive(60px, 60, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
        @include responsive(0px, 120, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom'); // 120 / 20

        @include max($breakpoint-strate-large) {
            overflow: hidden;
        }
    }

    .inner_content_wrapper {
        display: flex;
        align-items: center;

        @include max($breakpoint-strate-large) {
            flex-direction: column;
        }
    }

    .col_left {
        max-width: 400px;
        margin-right: 95px;

        @include max($breakpoint-strate-large) {
            max-width: 100%;
            width: 100%;
            margin-right: 0;
        }

        .titre {
            @include titre-strate();
            @include responsive(20px, 10, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

            @include max($breakpoint-strate-large) {
                text-align: center;
            }
        }
    
        .descriptif {
            font-weight: 300;
            line-height: 28px;

            @include max($breakpoint-strate-large) {
                text-align: center;
            }
        }
    
        // overrules
        .partageable-notes-etoiles {
            display: block;

            @include max($breakpoint-strate-large) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
    
            .col {
                margin: 20px 0 0;
                display: inline-flex;
                background-color: white;
    
                .logo {
                    width: 130px; // inclu maring-right // 
                    margin: 0;
                }
            }
        }
    
        .wrapper_cta {
            margin-top: 30px;

            @include max($breakpoint-strate-large) {
                margin-top: 20px;
                margin-bottom: 30px;
                text-align: center;
            }
        }
    }

    .col_right {
        display: flex;
        justify-content: flex-end;

        @include max($breakpoint-strate-large) {
            display: block;
        }

        .owl-stage-outer {
            overflow: visible;

            .owl-stage {
                overflow: visible;
            }
        }
        
        .owl-item {
            display: flex;
            justify-content: center;
        }

        .owl-dots {
            display: flex;
            justify-content: center;
            padding: 30px 0 30px;

            .owl-dot {
                width: 5px;
                height: 5px;
                border-radius: 50px;
                background-color: #c5d1d9;
                margin: 0 15px;

                &.active {
                    background-color: $Red_Praxedo;
                }
            }
        }

        .col_temoignage {
            position: relative;
            width: 240px;
            max-height: 360px;
            margin-right: 25px;
            background: #FFFFFF;
            box-shadow: 0px 0px 40px rgba(198, 210, 217, 0.25);
            border-radius: 10px;
            flex-shrink: 0;
            padding: 65px 28px 26px 21px;

            @include max($breakpoint-strate-large) {
                height: 360px;
            }
    
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 19px;
                left: 21px;
                width: 37px;
                height: 27px;
                background-image: url($pathToSVG + 'happy_at_work_temoignage.svg');
                background-repeat: no-repeat;
            }
    
            &:last-of-type {
                margin-right: 0;
            }
    
            .nom {
                font-weight: 700;
                font-size: 16px;
                line-height: 1.3em;
            }
    
            .fonction {
                font-weight: 400;
                font-size: 14px;
                line-height: 1.3em;
                opacity: .4;
            }
    
            // overrules
            .partageable-notes-etoiles {
                margin-top: 10px;
                justify-content: flex-start;
    
                .col {
                    margin: 0;
                    box-shadow: none;
                    padding: 0;
                    height: auto;
                }
            }
    
            .texte {
                margin-top: 26px;
                font-size: 14px;
                line-height: 1.5em;
                font-weight: 300;
            }
        }
    }
}
