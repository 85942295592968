$max_width_textes_htcgi: 880px;

.header-titre-cta-grosse-image {

    .inner_content_header_titre_cta_grosse_image {
        // @include max($breakpoint-strate-small) {
        //     padding-top: 35px;
        // }
        position: relative;
        @include responsive(30px, 30, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(50% + 120px);
            background-color: $grisClairFondHeader;

            @include max($breakpoint-mobile) {
                height: calc(50% + 220px);
            }
        }
    }


    .inner_content_wrapper {
        position: relative;
        z-index: 2;
        padding-top: 60px;
    }

    .wrapper_breadcrumb {
        text-align: center;
        margin-bottom: 28px;
    }

    .titre {
        @include titre-header();
        @include font-responsive(45px, 20, $breakpoint-mobile, $desktop_to_mobile);
        @include responsive(45px, 25, $breakpoint-mobile, $desktop_to_mobile, 'line-height');
        max-width: $max_width_textes_htcgi;
        margin: 0 auto;
        margin-bottom: 35px;
    }

    .sous_titre {
        @include texte-standard();
        font-weight: 300;
        max-width: $max_width_textes_htcgi;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 45px;
    }

    .wrapper_ctas {
        display: flex;
        justify-content: center;
        z-index: 1;
        position: relative;

        @include max($breakpoint-mobile) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;
        }

        .cta {
            margin: 0 7px;

            @include max($breakpoint-mobile) {
                margin: 7px 0;
            }
        }
    }

    .wrapper_image {
        display: flex;
        justify-content: center;
        margin-top: -60px;

        @include max($breakpoint-medium) {
            margin-top: 0px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}
