 /*
Comportement tablette/mobile : $breakpoint-large: 992px;
*/

// 1140
$largeur_col_droite: 420px;

.strate-notre-equipe {

    .inner_content_strate_notre_equipe {
        margin-bottom: 140px;
        background-color: $LightGrey_Praxedo_Bkg;
        padding: 124px 0 134px;
        @include max($breakpoint-large) {
            padding: 53px 0 60px;
            margin-bottom: 0;
        }
    }

    .inner_content_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include max($breakpoint-large) {
            display: block;
        }
    }

    .col_gauche {
        width: calc(100% - #{$largeur_col_droite});
        // padding-right: 120px;
        padding-right: 10.526315789473684%;

        @include max($breakpoint-large) {
            width: 100%;
            padding-right: 0;
            margin-bottom: 45px;
        }

        .titre {
            @include titre-strate();
            @include responsive(25px, 15, $breakpoint-mobile, $large_to_mobile, 'margin-bottom');
        }

        .texte, .texte p {
            font-weight: 300;
            line-height: 1.4em;
            @include font-responsive(14px, 6, $breakpoint-mobile, $large_to_mobile);
            @include responsive(23px, 5, $breakpoint-mobile, $large_to_mobile, 'line-height');

            p {
                margin-bottom: 20px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            strong {
                font-weight: 500;
            }
        }
    }

    .col_droite {
        width: $largeur_col_droite;

        @include max($breakpoint-large) {
            width: 100%;
            padding-right: 0;
        }

        .wrapper_chiffres_cles {
            border-radius: 10px;
            background-color: $white;
            box-shadow: 0px 0px 40px rgba(198, 210, 217, 0.25);
            position: relative;

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 5px;
                background-color: $Red_Praxedo;
                position: absolute;
                bottom: 0;
                left: 0;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            .ligne:not(._1item) {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid rgba(#C5D1D9, .5);

                .item {
                    width: 50%;
                    text-align: center;
                }
            }

            .ligne {
                padding: 30px 0;

                .titre_item {
                    text-align: center;
                    color: $Rouge_Praxedo;
                    letter-spacing: 0.07em;
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 16px;
                    margin-bottom: 7px;
                    line-height: 1.56em;
                }

                .texte_item {
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 1.2em;
                }
            }

            ._2items_seprares {
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 0;
                    width: 1px;
                    height: 100%;
                    background-color: rgba(#C5D1D9, .5);
                }
            }

            ._2items_lies {
                flex-wrap: wrap;

                .titre_item {
                    width: 100%;
                    margin-bottom: 11px;
                }

                .item {
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 22px;

                    &:last-of-type {
                        justify-content: flex-start;
                        padding-right: 0;
                        padding-left: 22px;
                    }
                }

                .picto_item {
                    margin-right: 14px;
                }
            }

            ._1item {
                text-align: center;
            }
        }
    }

}
