.strate-video-full-screen {

    .inner_content_strate_video_full_screen {
        position: relative;
        height: 800px;

        @include max($breakpoint-strate-small) {
            height: auto;
            margin-top: 70px;
            margin-bottom: 70px;
        }

        .wrapper_bg_image {
            width: 100%;
            height: 100%;
            position: absolute;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include max($breakpoint-strate-small) {
                display: none;
            }
        }
    }

    .wrapper {
        height: 100%;
    }

    .wrapper_title {
        position: absolute;
    }

    .inner_content_wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        @include max($breakpoint-strate-small) {
            align-items: center;
        }
    }

    .titre {
        position: relative;
        z-index: 2;
        @include titre-strate();
        @include font-responsive(30px , 20, $breakpoint-mobile, $bp_max_fr_sem); // 36xp - 50px - 480px - 1260px
        color: $white;
        margin-bottom: 46px;

        @include max($breakpoint-strate-small) {
            color: $black;
        }
    }

    .slide_temoignage {
        position: relative;
        width: 100%;

        @include max($breakpoint-strate-small) {
            width: 100%;
            height: auto;
            box-shadow: 0px 0px 56.3973px rgba(194, 194, 194, 0.25);
            border-radius: 11px;
            overflow: hidden;
        }

        .full_image_content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;

            .logo-company-mobile {
                display: none;
            }

            @include max($breakpoint-strate-small) {
                position: relative;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;

                .logo-company-mobile {
                    display: block;
                    max-width: 100px;
                    width: 100px;
                    height: 50px;
                    border-radius: 10px;
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    box-shadow: 0px 0px 20px rgba(78,78,78,0.19);
                    
                    @include min($breakpoint-medium) {
                        max-width: 150px;
                        width: 150px;
                        height: 60px;
                        top: 30px;
                        left: 30px;
                    }

                    img {
                        border-radius: 10px;
                        background: $white;
                        object-fit: contain;
                        padding: 3px;
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: none;

                @include max($breakpoint-strate-small) {
                    display: inline;
                }
            }
        }

        $width_white_box: 502px;

        .white_box {
            position: relative;
            background-color: $white;
            box-shadow: 0px 4px 34px transparentize($color: $black, $amount: 0.91);
            z-index: 2;
            width: 435px;
            min-height: 390px;
            padding: 42px 40px;
            border-radius: 10px;
            overflow: hidden;

            .slide_temoignage_cta {
                display: flex;
                justify-content: flex-start;
                margin-top: 10px;
            }

            @include responsive(30px, 12, $breakpoint-mobile, $desktop_to_mobile, 'padding-top');
            @include responsive(30px, 12, $breakpoint-mobile, $desktop_to_mobile, 'padding-bottom');
            @include responsive(20px, 20, $breakpoint-mobile, $desktop_to_mobile, 'padding-left');
            @include responsive(20px, 20, $breakpoint-mobile, $desktop_to_mobile, 'padding-right');

            @include max($breakpoint-strate-small) {
                position: relative;
                box-shadow: unset;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                width: 100%;
                min-height: auto;
                left: 0;
                top: 0;
            }

            &::after {
                position: absolute;
                display: block;
                content: '';
                width: 100%;
                height: 5px;
                background-color: $Red_Praxedo;
                bottom: 0;
                left: 0;
            }

            .row {

                &.row_top {

                    display: flex;
                    justify-content: flex-start;

                    .secteur {
                        border: 1px solid $Red_Praxedo;
                        border-radius: 17px;
                        margin-right: 30px;

                        text-transform: uppercase;
                        color: $Red_Praxedo;
                        @include font-responsive(8px, 2, $breakpoint-mobile, $desktop_to_mobile);
                        padding: 6px 18px;
                        @include responsive(10px, 8, $breakpoint-mobile, $desktop_to_mobile, 'padding-right');
                        @include responsive(10px, 8, $breakpoint-mobile, $desktop_to_mobile, 'padding-left');

                        @include max($breakpoint-strate-small) {
                            background-color: $Red_Praxedo;
                            color: $white;
                        }
                    }

                    .techniciens {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .picto {
                            margin-right: 9px;

                            svg {
                                width: 10px;
                                display: flex;
                            }
                        }

                        .texte {
                            text-transform: uppercase;
                            @include font-responsive(8px, 2, $breakpoint-mobile, $desktop_to_mobile);
                        }
                    }
                }

                &.row_middle {
                    @include responsive(30px, 8, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');
                    @include responsive(30px, 8, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');
                    @include font-responsive(16px , 2 , $breakpoint-mobile, $desktop_to_mobile);
                    @include responsive(24px, 3 , $breakpoint-mobile, $desktop_to_mobile, 'line-height');
                }

                &.row_bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    @include responsive(30px, 8, $breakpoint-mobile, $desktop_to_mobile, 'margin-top');

                    .col_row_bottom {
                        width: 50%;
                    }

                    .col_row_bottom_left {
                        .prenom_nom {
                            font-weight: bold;
                            @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile);
                            @include responsive(0px, 4, $breakpoint-mobile, $desktop_to_mobile, 'margin-bottom');

                        }

                        .fonction {

                            font-weight: lighter;
                            @include font-responsive(12px, 2, $breakpoint-mobile, $desktop_to_mobile);

                        }

                        .wrapper_cta {
                            margin-top: 20px;

                            .cta.cta_red_with_red_arrow .texte_cta {
                                @include font-responsive(14px, 2, $breakpoint-mobile, $desktop_to_mobile); // 14 -> 16
                            }
                        }
                    }

                    .col_row_bottom_right {
                        display: flex;
                        justify-content: flex-end;
                        max-width: 128px;

                        img  {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        &.logo-company-desktop {
                            @include max($breakpoint-strate-small) {
                                display: none;
                            }
                        }
                    }

                }
            }

        }

        .wrapper_player_video {
            @include min($breakpoint-strate-small) {
                position: absolute;
                z-index: 2;
                top: 50%;
                left: 70%;
                transform: translate(-50%, -50%);
                width: 100px;
                height: 100px;
            }

            .play-video-mobile {
                display: none;
            }
            // position: absolute;
            // top: 50%;
            // left: calc(#{$width_white_box} + 11vw);
            // transform: translateY(40px); // décalage arbitraire
            
            // transform: translateY(-50%); // valeur à appliquer ultérieurement
            // width:100px;
            // height: 100px;

            // @include responsive(60px, 40, $breakpoint-mobile, $desktop_to_mobile, 'width');
            // @include responsive(60px, 40, $breakpoint-mobile, $desktop_to_mobile, 'height');

            @include max($breakpoint-strate-small) {
                // transform: translate(-50%, -50%);
                left: 50%;

                .play-video-mobile {
                    display: block;

                    &.player_video {
                        @include player_video();
                        @include min($breakpoint-medium) {
                            // SVG taille réelle ici
                            width: 70px;
                            height: 70px;
                        }
                    }

                    @include min($breakpoint-mobile) {
                        width: auto;
                        height: auto;
                    }
                }
            }

            .player_video_popin {
                display: block;
                width: 100%;
                height: 100%;
                filter: drop-shadow(0px 0px 20px rgba(78, 78, 78, 0.18));

                &:hover {
                    svg {
                        circle {
                            fill: $DarkBlue_Text;
                        }
                    }
                }

                @include max($breakpoint-strate-small) {
                    display: none;
                }
            }

            .js_player_video_popin {
                cursor: pointer;
            }

            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;

                circle {
                    transition: $transition_standard;
                }
            }
        }

        .video_embed {
            display: none;
        }
    }
}
